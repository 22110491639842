import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges } from '@actions/challenge';
import InfoHeader from 'pages/home/InfoHeader';
import {
  Error,
  ChallengeWrapper,
  Section,
  TableWrapper,
  TableHead,
  TableBody,
} from 'css/pages/home';
import {
  CREATE_CHALLENGES_KEYS,
  USER_ACCEPTED_CHALLENGE_COUNT,
} from '@reduxConstants/create-challenge';

const { TITLE } = CREATE_CHALLENGES_KEYS;

const Challenges = () => {
  const dispatch = useDispatch();
  const [challenges, setChallenges] = useState(undefined);
  const [totalChallenge, setTotalChallenges] = useState(undefined);
  const [searchVal, setSearchVal] = useState(undefined);
  const { user_id, role_id } = useSelector((state) => state?.auth?.profile);

  useEffect(() => {
    const fetchChallenges = async () => {
      if (!challenges) {
        let userId = role_id !== 1 ? user_id : undefined;
        const data = await dispatch(getChallenges(userId, null));
        if (data.error) {
          alert(data.message);
          return;
        }
        if (role_id !== 1) {
          let filter = data.filter((item) => item?.from_user_id === user_id);
          setChallenges(filter);
          setTotalChallenges(filter);
        } else {
          setChallenges(data);
          setTotalChallenges(data);
        }
      }
    };
    fetchChallenges();
  }, [dispatch, challenges, role_id, user_id]);

  const searchChallenge = (val) => {
    setSearchVal(val);
    let match = totalChallenge.filter((item) => {
      return item?.title?.includes(val);
    });
    setChallenges(match);
  };

  return (
    <ChallengeWrapper>
      <Section>
        <InfoHeader
          title='Active Challenges'
          label='Search challenge'
          value={searchVal}
          onChange={(v) => searchChallenge(v)}
        />
        <TableWrapper>
          {challenges?.length ? (
            <>
              <TableHead>
                <div>Challenge Name</div>
                <div>User Count</div>
              </TableHead>
              <TableBody>
                {challenges?.map((key, index) => (
                  <div key={`challenges${index}`}>
                    <p>{key?.[TITLE]}</p>
                    <p>{key?.[USER_ACCEPTED_CHALLENGE_COUNT]}</p>
                  </div>
                ))}
              </TableBody>
            </>
          ) : (
            <Error>No Data Found</Error>
          )}
        </TableWrapper>
      </Section>
    </ChallengeWrapper>
  );
};

export default Challenges;
