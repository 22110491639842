import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Wrapper,
  Heading,
  SubHeading,
  ButtonWrapper,
} from 'css/pages/error';
import Button from 'components/Button';
import { logout as logoutUser } from '@actions/auth';
import { useHistory } from 'react-router-dom';

function ErrorPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user_id } = useSelector((state) => state?.auth?.profile) || 0;

  return (
    <Container>
      <Wrapper>
        <Heading>Oops!</Heading>
        <SubHeading>401 Not Authorized</SubHeading>
        <ButtonWrapper>
          <Button
            fullWidth={false}
            onClick={() => history.push('/')}
            label='Back to Home'
          />{' '}
          <Button
            fullWidth={false}
            onClick={() => dispatch(logoutUser(user_id))}
            label='Logout'
          />
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
}

export default ErrorPage;
