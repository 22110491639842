import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import { purpleGradientButton } from 'components/Button/types';
import Button from 'components/Button';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, getUsersCSV, deleteUsers } from '@actions/user';
import ConfirmDialog from 'components/ConfirmDialog';
import SendMailDialog from 'pages/users/SendMailDialog';
import Table from 'components/Table';
import { PageContainer } from 'css/components/Body';
import { headCells } from 'pages/users/data';
import { userByGroup } from '@actions/bodyAnalytics';


const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleGradientButton(),
    marginTop: '16px',
    marginBottom: '8px',
    height: '45px',
  },
}));

const Users = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.auth?.profile || {});
  const [users, setUsers] = useState(undefined);
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const [sendMailModalVisibility, setSendMailModalVisibility] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(undefined);
  const linkRef = useRef(undefined);
  const [CSVData, setCSVData] = useState(undefined);

  const btnStyle = useButtonStyles();
  const btnLabel = 'Download Excel';

  useEffect(() => {
    const fetchUsers = async () => {
      if (!users && profile?.role_id === 1) {
        const data = await dispatch(getAllUsers());
        if (data?.error) {
          alert(data?.message);
          return;
        } else{
          setUsers(data?.users);
          let res = await dispatch(getUsersCSV());
          setCSVData(res.message);
        }
      } else if (!users) {
        const data = await dispatch(userByGroup(profile?.group_id));
        if (data.error) {
          alert(data?.message);
          return;
        }
        setUsers(data?.message);
      }
    };
    fetchUsers();
  }, [dispatch, users, profile?.role_id, profile?.group_id]);
  if (users?.length) {
    let rows = [];

    if (users?.length < 1)
      rows.push({
        firstname: '-',
        lastname: '-',
        email: '-',
        phone: '-',
        dob: '-',
        zipCode: '-',
        userId: 0,
      });

    for (let i = 0; i < users.length; i++) {
      if (users[i]?.user_id === profile?.user_id && users?.length < 2) {
        rows.push({
          firstname: '-',
          lastname: '-',
          email: '-',
          phone: '-',
          dob: '-',
          zipCode: '-',
          userId: 0,
        });
        continue;
      } else if (users[i]?.user_id === profile?.user_id) continue;
      rows.push({
        firstname: users[i]?.firstname,
        lastname: users[i]?.lastname,
        email: users[i]?.emailid,
        phone: users[i]?.phone,
        dob: users[i]?.dateofbirth
          ? moment(users[i]?.dateofbirth).format('MM/DD/YYYY')
          : 'N/A',
        zipCode: users[i]?.zipcode,
        userId: users[i]?.user_id,
      });
    }
    return (
      <React.Fragment>
        <PageContainer>
          <Table
            title='Active users'
            headCells={headCells}
            rows={rows}
            style={{ background: 'none' }}
            actionKey='userId'
            sendMail={(users) => {
              setSendMailModalVisibility(true);
              setSelectedUserIds(users);
            }}
            onDelete={(users) => {
              setConfirmModalVisibility(true);
              setSelectedUserIds(users);
            }}
          />
          {confirmModalVisibility && (
            <ConfirmDialog
              title='Are you sure?'
              message='Do you really want to delete these records? This process cannot be undone.'
              open={confirmModalVisibility}
              hide={async (remove) => {
                if (remove) {
                  const users = await dispatch(
                    deleteUsers({
                      userIds: selectedUserIds,
                      lastUpdatedBy: profile?.last_updated_by,
                      roleId: profile?.role_id,
                    })
                  );
                  if (users?.error) {
                    alert(users?.message);
                    return;
                  }
                  setUsers(undefined);
                }
                setSelectedUserIds(undefined);
                setConfirmModalVisibility(false);
              }}
            />
          )}
          {sendMailModalVisibility && (
            <SendMailDialog
              open={sendMailModalVisibility}
              setOpen={(v) => setSendMailModalVisibility(v)}
              data={users.filter((item) =>
                selectedUserIds.includes(item?.user_id)
              )}
            />
          )}
          {(CSVData && users?.length) || true ? (
          <ButtonWrapper>
            <div style={{ marginBottom: '20px' }}>
              <Button
                onClick={async () => {
                  linkRef?.current?.link?.click();
                }}
                styles={btnStyle}
                fullWidth={false}
                label={btnLabel.toUpperCase()}
              />

              <CSVLink
                style={{ display: 'none' }}
                ref={linkRef}
                data={CSVData ? CSVData : 'No data found here'}>
                {btnLabel}
              </CSVLink>
            </div>
          </ButtonWrapper>
          ) : (<></>)
          }
        </PageContainer>
      </React.Fragment>
    );
  }

  return null;
};

export default Users;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  > button {
    height: 42px;
    min-width: 209px;
    font-size: 14px;
  }
`;
