import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={23}
    height={26.889}
    viewBox='0 0 26 28.889'
    {...props}>
    <path
      d='M8.667 13H5.778v2.889h2.889zm5.778 0h-2.889v2.889h2.889zm5.778 0h-2.89v2.889h2.889zm2.888-10.111h-1.444V0h-2.889v2.889H7.222V0H4.333v2.889H2.889A2.876 2.876 0 00.014 5.778L0 26a2.888 2.888 0 002.889 2.889h20.222A2.9 2.9 0 0026 26V5.778a2.9 2.9 0 00-2.889-2.889zm0 23.111H2.889V10.111h20.222z'
      fill='#7f3d78'
    />
  </svg>
);

export default Icon;
