import * as a from '@actions/fitnessGraph';

const fitnessGraph = (
  state = { fitnessGraph: [], error: null },
  action = null
) => {
  switch (action.type) {
    case a.SET_FITNESS_GRAPH:
      return {
        ...state,
        fitnessGraph: action.fitnessGraph,
      };
    default:
      return state;
  }
};

export default fitnessGraph;
