import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_FITNESS_GRAPH = 'SET_FITNESS_GRAPH';
const getFitnessGraph = (
  userId,
  graphType,
  reportType,
  startDate,
  endDate,
  device_id
) => {
  try {
    return async (dispatch) => {
      let fitnessGraph = await get({
        url: `${REQUESTS.GET_GRAPH_DATA}${userId}${'&graphtype='}${
          "'" + graphType + "'"
        }${'&reporttype='}${"'" + reportType + "'"}${'&startdate='}${
          "'" + startDate + "'"
        }${'&enddate='}${"'" + endDate + "'"}${'&device_id='}${device_id}`,
      });
      fitnessGraph = fitnessGraph?.message || [];
      dispatch(
        updateField(SET_FITNESS_GRAPH, {
          fitnessGraph,
        })
      );
      return fitnessGraph;
    };
  } catch (error) {
    return { error: true, message: error?.error };
  }
};

export { getFitnessGraph, SET_FITNESS_GRAPH };
