const APPLE_HEALTH = 'Apple Health';
const FITBIT = 'Fitbit';
const SAMSUNG_HEALTH = 'Samsung Health';
const GOOGLE_FIT = 'GOOGLE_FIT';

const ACTIVITY_TRACKER_KEYS = {
  CONNECTED: 'connected',
  DEVICE: 'DEVICE',
  INDEX: 'index',
  DEVICE_ID: 'DEVICE_ID',
};

export {
  APPLE_HEALTH,
  FITBIT,
  SAMSUNG_HEALTH,
  ACTIVITY_TRACKER_KEYS,
  GOOGLE_FIT,
};
