import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16.005}
    height={16.008}
    viewBox='0 0 16.005 16.008'
    {...props}>
    <path
      d='M15.787 13.84l-3.117-3.117a.75.75 0 00-.531-.219h-.51a6.5 6.5 0 10-1.129 1.125v.51a.75.75 0 00.219.531l3.117 3.117a.747.747 0 001.06 0l.885-.885a.754.754 0 00.006-1.062zM6.5 10.5a4 4 0 114-4 4 4 0 01-4 4z'
      fill='#a25f9b'
    />
  </svg>
);

export default Icon;
