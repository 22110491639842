import { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useStyles from 'components/TextField/useStyles';
import { getTheme } from 'components/TextField/Theme';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PURPLE } from '@colors';

const CustomTextField = ({
  error,
  value,
  label,
  helperText,
  type,
  onChange,
  variant,
  theme,
  disabled,
  secureText,
  endAdornment,
  style,
  placeholder,
  ref,
}) => {
  const classes = useStyles(getTheme(theme));
  const [fieldType, setFieldType] = useState(type);

  return (
    <TextField
      fullWidth
      ref={ref}
      className={classes.root}
      error={error}
      value={value || ''}
      style={style}
      label={label}
      disabled={disabled}
      helperText={helperText}
      type={fieldType}
      onChange={(e) => _.isFunction(onChange) && onChange(e.target.value)}
      variant={variant}
      margin='normal'
      placeholder={placeholder}
      InputProps={{
        endAdornment: secureText ? (
          <InputAdornment position='end'>
            <IconButton
              style={{ color: PURPLE, padding: 0 }}
              onClick={() =>
                setFieldType(fieldType === 'password' ? 'text' : 'password')
              }>
              {fieldType === 'password' ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : (
          endAdornment
        ),
      }}
    />
  );
};

CustomTextField.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.oneOf([
    'white',
    'purple',
    'grey',
    'form',
    'transparentBorder',
  ]),
  secureText: PropTypes.bool,
};

CustomTextField.defaultProps = {
  type: 'text',
  variant: 'outlined',
  theme: 'grey',
  secureText: false,
};

export default CustomTextField;
