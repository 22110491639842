import React, { useEffect } from 'react';
import { Marker } from 'react-map-gl';
import MapMarker from 'assets/images/map-marker.png';

const MarkerPins = (props) => {
  const { data, onClick } = props;

  useEffect(() => {}, [data]);

  const isValidLatLong = (val, typ) => {
    if (val === 'undefined') return undefined;
    if (!val) return undefined;
    try {
      let newVal = parseInt(val);
      let check = typ === 'lat' ? 90 : 180;
      if (typeof newVal !== 'number') return undefined;
      if (-check < newVal < check) return true;
      else return undefined;
    } catch {
      return undefined;
    }
  };

  return data.map((item, index) => {
    return isValidLatLong(item?.latitude, 'lat') &&
      isValidLatLong(item?.longitude, 'long') ? (
      <>
        <Marker
          key={`marker-${index}`}
          longitude={parseFloat(item?.longitude || 0)}
          latitude={parseFloat(item?.latitude || 0)}
          onClick={() => onClick(item)}>
          <img
            key={`marker-img-${index}`}
            alt='map-marker'
            src={MapMarker}
            height='25px'
            width='25px'></img>
        </Marker>
      </>
    ) : null;
  });
};

export default MarkerPins;
