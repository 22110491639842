import { post } from '@axios';
import REQUESTS from '@requests';
import { MAIL_KEYS } from '@reduxConstants/mail';
import { updateField } from '@actions/common';

const SEND_EMAIL = 'SEND_EMAIL';
const MAIL_ERROR = 'MAIL_ERROR';

const sendMail = (data) => {
  return async (dispatch) => {
    try {
      let payload = {
        [MAIL_KEYS.EMAIL]: data.mail_list,
        [MAIL_KEYS.EMAIL_BODY]: data.mail_body,
      };
      const request = await post({
        url: REQUESTS.SEND_EMAIL,
        payload,
      });
      dispatch(updateField(SEND_EMAIL), {
        email_list: data.emails,
        sent: true,
      });
      return { error: false, data: request?.data };
    } catch (error) {
      dispatch(updateField(MAIL_ERROR, { [MAIL_KEYS.ERROR]: error.error }));
      return { error: true, message: error?.error };
    }
  };
};

export { sendMail, SEND_EMAIL };
