import * as a from '@actions/challengesArea';

const initialState = { data: [], error: null };

const challengesArea = (state = initialState, action = null) => {
  switch (action.type) {
    case a.SET_CHALLENGES_AREA:
      return {
        error: null,
        data: action.data,
      };
    case a.SET_CHALLENGES_AREA_ERROR:
      return {
        error: action.error,
        data: [],
      };
    default:
      return state;
  }
};

export default challengesArea;
