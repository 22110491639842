import * as a from '@actions/bodyAnalytics';

const initial = {
  data: [],
  users: [],
};

const bodyAnalytics = (state = initial, action = null) => {
  switch (action.type) {
    case a.INITIALIZE_BODY_ANALYTICS:
      return initial;
    case a.SET_BODY_ANALYTICS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case a.GET_USER_LIST: {
      return {
        ...state,
        users: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bodyAnalytics;
