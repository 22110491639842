import styled from 'styled-components';
import { WHITE, PURPLE } from '@colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  grid-gap: 7px;
  position: relative;
`;
const LogoWrapper = styled.div`
  min-width: 254px;
  height: 83px;
  background-color: #8c7d9bb5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
`;
const Logo = styled.img`
  max-width: 142px;
`;

const RightMenu = styled.div`
  flex: 1;
  background-color: ${PURPLE};
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 63px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: ${WHITE};
  justify-content: space-between;
  > div {
    display: flex;
  }
`;
const UserName = styled.div``;

const Date = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
`;

const Detail = styled.div`
  font-size: 18px;
  > span {
    text-transform: capitalize;
  }
`;
const ProfilePic = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  margin-left: 40px;
  img {
    width: 100%;
    object-fit: none;
  }
`;
const Logout = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
`;

export {
  Container,
  Logo,
  RightMenu,
  UserName,
  Date,
  Detail,
  ProfilePic,
  Logout,
  LogoWrapper,
};
