import React from 'react';
import {
  PageContainer,
  HeadingContainer,
  HeadingTitle,
  HeadingSubTitle,
} from 'css/components/Body';
import { PageForm } from 'css/pages/challenges';
import RegisterUserForm from 'components/RegisterUserForm';

const RegisterAdmin = () => {
  return (
    <PageContainer>
      <HeadingContainer>
        <HeadingTitle>Register New Admin</HeadingTitle>
        <HeadingSubTitle>Complete the Form</HeadingSubTitle>
      </HeadingContainer>
      <PageForm style={{ marginTop: '10px' }}>
        <RegisterUserForm />
      </PageForm>
    </PageContainer>
  );
};

export default RegisterAdmin;
