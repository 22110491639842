import Form from 'pages/create-coupon/Form';
import { PageContainer } from 'css/components/Body';
import { PageForm } from 'css/pages/challenges';
import Header from 'pages/create-coupon/Header';

const CreateCoupon = () => {
  return (
    <PageContainer fullHeight>
      <Header />
      <PageForm>
        <div>
          <Form />
        </div>
      </PageForm>
    </PageContainer>
  );
};

export default CreateCoupon;
