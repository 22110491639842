import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_STEP_BANK_DATA = 'SET_STEP_BANK_DATA';

const getStepBankData = (userId, device_id) => {
  return async (dispatch) => {
    try {
      let stepBankData = await get({
        url: `${
          REQUESTS.GET_STEP_BANK_DATA
        }${userId}${'&device_id='}${device_id}`,
      });
      stepBankData = stepBankData?.message || [];
      dispatch(
        updateField(SET_STEP_BANK_DATA, {
          stepBankData,
        })
      );
      return stepBankData;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

export { SET_STEP_BANK_DATA, getStepBankData };
