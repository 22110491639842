import * as a from '@actions/activityTracker';
import { initial } from '@reducers/activityTracker/initialState';
import { ACTIVITY_TRACKER_KEYS } from '@reduxConstants/activityTracker';

const activityTrackers = (state = initial, action = null) => {
  switch (action.type) {
    case a.TOGGLE_CONNECT_ACTIVITY_TRACKER:
      return state.map((item, index) => {
        if (action[ACTIVITY_TRACKER_KEYS.INDEX] !== index) {
          return item;
        }
        return {
          ...item,
          [ACTIVITY_TRACKER_KEYS.CONNECTED]: !item[
            ACTIVITY_TRACKER_KEYS.CONNECTED
          ],
        };
      });
    default:
      return state;
  }
};

export default activityTrackers;
