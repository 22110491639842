import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: (props) => ({
    borderRadius: '4px',
    fontFamily: 'inherit',

    '& label': {
      fontFamily: `'Poppins', sans-serif`,
      color: props.labelColor,
      fontWeight: 600,
    },
    '& label.Mui-focused': {
      color: props.labelFocusedColor,
    },
    '& label.Mui-error': {
      color: 'red',
    },

    '& .MuiSelect-underline': {
      fontFamily: 'inherit',

      '& input': {
        color: props.color,
        paddingBottom: 15,
      },
      '&::before': {
        borderColor: props.borderColor,
      },
      '&:hover': {
        '&:not(.Mui-disabled)::before': {
          borderColor: props.borderColor,
        },
      },
      '&:not(.Mui-disabled)::after': {
        borderColor: props.borderColor,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: props.transform,
    },
    '& .MuiInputLabel-shrink': {
      transform: props.transformShrink,
    },
    '& .MuiSelect-outlined': {
      padding: props.padding,
      zIndex: 1,
      position: 'relative',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${props.borderFocusColor} !important`,
      borderWidth: props.borderFocusWidth,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props.borderColor,
      border: props.border,
      borderRadius: props.borderRadius,
      borderBottomStyle: props.borderBottomStyle,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props.borderColor,
    },
    '&:focus .MuiOutlinedInput-notchedOutline': {
      borderColor: props.borderColor,
      borderRadius: props.borderRadius,
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'inherit',
      '& :hover': {
        borderColor: props.borderColor,
      },
      '& .Mui-focused': {
        borderColor: props.borderColor,
      },

      '& input': {
        color: props.color,
      },
      // '& input::placeholder': {
      //   color: props.placeholderColor,
      //   opacity: 1,
      //   fontWeight: 600,
      // },

      '& fieldset': {
        borderColor: props.borderColor,
      },
      '&:hover fieldset': {
        borderColor: props.borderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: props.borderColor,
      },
    },
  }),
});

export default useStyles;
