import * as a from '@actions/dashboard';
import { HEALTH_KEYS } from '@reduxConstants/dashboard';

const health = (state = { healthData: [], error: null }, action = null) => {
  switch (action.type) {
    case a.SET_HEALTH_DATA:
      return {
        ...state,
        healthData: action.healthData,
        [HEALTH_KEYS.ERROR]: null,
      };

    case a.HEALTH_ERROR:
      return {
        ...state,
        [HEALTH_KEYS.ERROR]: action[HEALTH_KEYS.ERROR],
        healthData: [],
      };
    default:
      return state;
  }
};

export default health;
