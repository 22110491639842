const ROUTES = {
  LOGIN: '/login',
  HOME: '/',
  USERS: '/users',
  MAP: '/map',
  COUPONS: '/coupons',
  CREATE_COUPON: '/coupons/create-coupon',
  CHALLENGES: '/challenges',
  CREATE_CHALLENGE: '/challenges/create-challenge',
  REGISTER_ADMIN: '/register-admin',
  BODY_ANALYTICS: '/body-analytics',
  PAYMENT: '/payment',
  FITNESS_ANALYTICS: '/fitness-analytics',
};

const permissions = {
  1: [
    ROUTES.HOME,
    ROUTES.USERS,
    ROUTES.MAP,
    ROUTES.COUPONS,
    ROUTES.CREATE_COUPON,
    ROUTES.CREATE_CHALLENGE,
    ROUTES.CHALLENGES,
    ROUTES.REGISTER_ADMIN,
    ROUTES.FITNESS_ANALYTICS,
    ROUTES.BODY_ANALYTICS,
    ROUTES.PAYMENT,
  ],
  2: [
    ROUTES.HOME,
    ROUTES.USERS,
    ROUTES.COUPONS,
    ROUTES.CREATE_COUPON,
    ROUTES.CREATE_CHALLENGE,
    ROUTES.CHALLENGES,
    ROUTES.FITNESS_ANALYTICS,
    ROUTES.PAYMENT,
  ],
  3: [
    ROUTES.HOME,
    ROUTES.USERS,
    ROUTES.COUPONS,
    ROUTES.CREATE_COUPON,
    ROUTES.CREATE_CHALLENGE,
    ROUTES.CHALLENGES,
    ROUTES.FITNESS_ANALYTICS,
    ROUTES.BODY_ANALYTICS,
    ROUTES.PAYMENT,
  ],
  4: [],
  5: [
    ROUTES.HOME,
    ROUTES.COUPONS,
    ROUTES.CREATE_COUPON,
    ROUTES.CREATE_CHALLENGE,
    ROUTES.CHALLENGES,
    ROUTES.PAYMENT,
  ],
};

export { ROUTES, permissions };
