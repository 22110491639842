const AUTH_KEYS = {
  AUTHENTICATED: 'authenticated',
  ERROR: 'error',
  PROFILE: 'profile',
  LOGOUT: 'logout',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude'
};
const DISABLE_NOTIFICAION = 'disableNotification';

export { AUTH_KEYS, DISABLE_NOTIFICAION };
