import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={14.7}
    viewBox='0 0 21 14.7'
    {...props}>
    <path
      d='M3.15 6.3a2.1 2.1 0 10-2.1-2.1 2.1 2.1 0 002.1 2.1zm14.7 0a2.1 2.1 0 10-2.1-2.1 2.1 2.1 0 002.1 2.1zm1.05 1.05h-2.1a2.094 2.094 0 00-1.48.61 4.8 4.8 0 012.464 3.59h2.166A1.049 1.049 0 0021 10.5V9.45a2.1 2.1 0 00-2.1-2.1zm-8.4 0a3.675 3.675 0 10-3.675-3.675A3.673 3.673 0 0010.5 7.35zm2.52 1.05h-.272a5.074 5.074 0 01-4.5 0H7.98a3.781 3.781 0 00-3.78 3.78v.945A1.575 1.575 0 005.775 14.7h9.45a1.575 1.575 0 001.575-1.575v-.945a3.781 3.781 0 00-3.78-3.78zm-7.34-.44a2.094 2.094 0 00-1.48-.61H2.1A2.1 2.1 0 000 9.45v1.05a1.049 1.049 0 001.05 1.05h2.162A4.812 4.812 0 015.68 7.96z'
      fill='#fff'
    />
  </svg>
);

export default Icon;
