import * as a from '@actions/roles';

const roles = (state = { roles: [] }, action = null) => {
  switch (action.type) {
    case a.GET_ALL_ROLES:
      return { ...state, roles: action?.payload || [] };
    default:
      return state;
  }
};

export default roles;
