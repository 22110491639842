import * as a from '@actions/user';
import { initial } from '@reducers/user/initialState';
import {
  MINIMUM_PASSWORD_LENGTH,
  USER_KEYS,
  MAXIMUM_PASSWORD_LENGTH,
} from '@reduxConstants/user';

const user = (state = initial, action = null) => {
  switch (action.type) {
    case a.USER_INITIATE:
      return initial;

    case a.SET_USER_EMAIL:
      const email = action[USER_KEYS.EMAIL].toLowerCase();
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

      return {
        ...state,
        [USER_KEYS.EMAIL]: {
          ...state[USER_KEYS.EMAIL],
          value: email,
          valid: expression.test(email),
        },
      };

    case a.SET_USER_PASSWORD:
      const password = action[USER_KEYS.PASSWORD].replace(
        /\"|\`|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\''|\s/g,
        ''
      );
      return {
        ...state,
        [USER_KEYS.PASSWORD]: {
          ...state[USER_KEYS.PASSWORD],
          value: password,
          valid:
            !!action.password &&
            action.password.length <= MAXIMUM_PASSWORD_LENGTH &&
            !/\"|\`|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\''|\s/g.test(
              action.password
            ),
        },
      };
    case a.SET_USER_NEW_PASSWORD:
      const newPassword = action[USER_KEYS.NEW_PASSWORD].replace(/[\s]/g, '');
      return {
        ...state,
        [USER_KEYS.NEW_PASSWORD]: {
          ...state[USER_KEYS.NEW_PASSWORD],
          value: newPassword,
          valid:
            action?.[USER_KEYS.NEW_PASSWORD]?.length >=
              MINIMUM_PASSWORD_LENGTH && !!action?.newPassword,
        },
        [USER_KEYS.CONFIRM_PASSWORD]: {},
      };
    case a.SET_USER_CONFIRM_PASSWORD:
      const confirmPassword = action[USER_KEYS.CONFIRM_PASSWORD].replace(
        /[\s]/g,
        ''
      );
      return {
        ...state,
        [USER_KEYS.CONFIRM_PASSWORD]: {
          ...state[USER_KEYS.CONFIRM_PASSWORD],
          value: confirmPassword,
          valid:
            action?.[USER_KEYS.CONFIRM_PASSWORD] ===
              state?.[USER_KEYS.NEW_PASSWORD]?.value &&
            !!action?.[USER_KEYS.CONFIRM_PASSWORD],
        },
      };

    case a.UPDATE_USER_FIELD:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
          valid: !!action.value,
        },
      };

    case a.SET_USER_HEIGHT:
      return {
        ...state,
        [USER_KEYS.HEIGHT.HEIGHT]: {
          ...state[USER_KEYS.HEIGHT.HEIGHT],
          feet: {
            ...state[USER_KEYS.HEIGHT.HEIGHT][USER_KEYS.HEIGHT.FEET],
            value: action.feet,
            valid: !!action.feet,
          },
          inch: {
            ...state[USER_KEYS.HEIGHT.HEIGHT][USER_KEYS.HEIGHT.INCH],
            value: action.inch,
            valid: !!action.inch,
          },
        },
      };

    case a.TOGGLE_USER_DOB_PICKER_VISIBILITY:
      return {
        ...state,
        [USER_KEYS.DOB]: {
          ...state[USER_KEYS.DOB],
          [USER_KEYS.DOB_VISIBILITY]: !state[USER_KEYS.DOB][
            USER_KEYS.DOB_VISIBILITY
          ],
        },
      };

    case a.SET_USER_OPTIONS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          options: action.options,
        },
      };

    case a.UPDATE_MULTIPLE_USER_FIELDS:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default user;
