const MINIMUM_PASSWORD_LENGTH = 5;
const MAXIMUM_PASSWORD_LENGTH = 15;
const USER_KEYS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  NAME: 'username',
  DOB: 'dob',
  DOB_VISIBILITY: 'visible',
  GENDER: 'gender',
  ZIP_CODE: 'zipCode',
  ETHNICITY: 'ethnicity',
  GROUP_ID: 'groupId',
  HEIGHT: {
    HEIGHT: 'height',
    FEET: 'feet',
    INCH: 'inch',
  },
  WEIGHT: 'weight',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
};

export { MINIMUM_PASSWORD_LENGTH, USER_KEYS, MAXIMUM_PASSWORD_LENGTH };
