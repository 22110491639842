const BODY_ANALYTICS_KEYS = {
  CHEST: 'chest',
  BMI: 'BMI',
  HEIGHT_IN_FEET: 'updatedFeet',
  HEIGHT_IN_INCHES: 'updatedInches',
  WAIST: 'waist',
  LOW_HIPS: 'low_hips',
  HIGH_HIPS: 'high_hips',
  WEIGHT: 'updatedWeight',
  RADIUS: 'radius',
  SHOULDER: 'shoulders',
  NECK: 'neck',
};

export default BODY_ANALYTICS_KEYS;
