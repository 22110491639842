import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Login from 'pages/login';
import Home from 'pages/home';
import Users from 'pages/users';
import Coupons from 'pages/coupons';
import CreateCoupon from 'pages/create-coupon';
import Challenges from 'pages/challenges';
import CreateChallenge from 'pages/create-challenge';
import RegisterAdmin from 'pages/register-admin';
import BodyAnalytics from 'pages/body-analytics';
import FitnessAnalytics from 'pages/fitness-analytics';
import Map from 'pages/map';
import Payment from 'pages/payment';
import { ROUTES } from 'constants/routes';
import PrivateRoute from 'routes/PrivateRoute';

const Routes = () => {
  const auth = useSelector((state) => state?.auth);
  return (
    <Router>
      <Switch>
        <Route path={ROUTES.LOGIN} exact>
          {auth?.authenticated ? <Redirect to={ROUTES.HOME} /> : <Login />}
        </Route>
        <PrivateRoute path={ROUTES.HOME} exact>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.USERS} exact>
          <Users />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.COUPONS} exact>
          <Coupons />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.CREATE_COUPON} exact>
          <CreateCoupon />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.CHALLENGES} exact>
          <Challenges />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.CREATE_CHALLENGE} exact>
          <CreateChallenge />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.MAP} exact>
          <Map />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.PAYMENT} exact>
          <Payment />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.REGISTER_ADMIN} exact>
          <RegisterAdmin />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.FITNESS_ANALYTICS} exact>
          <FitnessAnalytics />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.BODY_ANALYTICS} exact>
          <BodyAnalytics />
        </PrivateRoute>

        <Redirect
          from='*'
          to={auth?.authenticated ? ROUTES.HOME : ROUTES.LOGIN}
        />
        <Route exact path={ROUTES.HOME}>
          {auth?.authenticated ? (
            <Redirect to={ROUTES.HOME} />
          ) : (
            <Redirect to={ROUTES.LOGIN} />
          )}
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
