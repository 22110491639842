import { INITIAL_FIELD_STATE } from '@reducers/common';

const initial = {
  email: {
    ...INITIAL_FIELD_STATE,
  },
  password: {
    ...INITIAL_FIELD_STATE,
  },
  groupid: {
    ...INITIAL_FIELD_STATE,
  },
  access: {
    ...INITIAL_FIELD_STATE,
  },
};

export { initial };
