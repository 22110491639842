import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20.867}
    height={16.226}
    viewBox='0 0 20.867 16.226'
    {...props}>
    <path
      d='M10.157 4.209l-6.679 5.5v5.937a.58.58 0 00.58.58l4.059-.011a.58.58 0 00.577-.58v-3.466a.58.58 0 01.58-.58h2.317a.58.58 0 01.58.58v3.464a.58.58 0 00.58.581l4.058.011a.58.58 0 00.58-.58V9.706l-6.677-5.5a.442.442 0 00-.555.003zm10.549 3.737l-3.028-2.5V.434a.435.435 0 00-.435-.435h-2.029a.435.435 0 00-.435.435v2.63L11.537.396a1.739 1.739 0 00-2.21 0L.158 7.946a.435.435 0 00-.058.612l.924 1.123a.435.435 0 00.612.065l8.521-7.018a.442.442 0 01.554 0l8.521 7.018a.435.435 0 00.612-.058l.924-1.123a.435.435 0 00-.062-.613z'
      fill='#fff'
    />
  </svg>
);

export default Icon;
