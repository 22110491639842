import { get } from '@axios';
import REQUESTS from '@requests';

const SET_FITNESS_ANALYTICS = 'SET_FITNESS_ANALYTICS';
const GET_FITNESS_CSV = 'GET_FITNESS_CSV';
const INITIALIZE_FITNESS_ANALYTICS = 'INITIALIZE_FITNESS_ANALYTICS';

const getFitnessDetails = (data) => {
  return async (dispatch) => {
    let response = await get({
      url: `${REQUESTS.FITNESS_ANALYTICS}?groupId=${data.groupId}&fitnessAttribute=${data.attribute}&deviceId=${data.deviceId}`,
    });
    dispatch({ type: SET_FITNESS_ANALYTICS, payload: response?.message });
    if (response?.error) return { error: true, message: response?.message };
    else return { error: false, message: response?.message };
  };
};

const getFitnessCSV = (data) => {
  return async (dispatch) => {
    let response = await get({
      url: `${REQUESTS.GET_FITNESS_CSV}?groupId=${data.groupId}&fitnessAttribute=${data.attribute}&deviceId=${data.deviceId}`,
    });
    if (response?.error) return { error: true, message: response };
    else return { error: false, message: response };
  };
};

export {
  getFitnessDetails,
  getFitnessCSV,
  SET_FITNESS_ANALYTICS,
  GET_FITNESS_CSV,
  INITIALIZE_FITNESS_ANALYTICS,
};
