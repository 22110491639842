import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WHITE, PURPLE } from '@colors';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99999,
    backgroundColor: 'rgba(255,255,255,0.4) !important',
    color: WHITE,
  },
  loader: { color: PURPLE },
}));

const Loader = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress className={classes.loader} />
    </Backdrop>
  );
};

Loader.propTypes = { open: PropTypes.bool };

export default Loader;
