const headCells = [
  {
    id: 'bussinesslogo',
    label: 'Business Logo',
    align: 'left',
    numeric: false,
  },
  {
    id: 'bussinessname',
    label: 'Business Name',
    align: 'left',
    numeric: false,
  },
  {
    id: 'challengearea',
    label: 'Area',
    align: 'left',
    numeric: false,
  },
  {
    id: 'incentivemsg',
    label: 'Incentive Message',
    align: 'left',
    numeric: false,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    numeric: false,
  },
  {
    id: 'fitnessmetric',
    label: 'Fitness Metric',
    align: 'left',
    numeric: false,
  },
  {
    id: 'fitnessmetricbenchmark',
    label: 'Fitness Metric Benchmark',
    align: 'left',
    numeric: false,
  },
  {
    id: 'startdate',
    label: 'Start Date',
    align: 'left',
    numeric: false,
  },
  {
    id: 'enddate',
    label: 'End Date',
    align: 'left',
    numeric: false,
  },
  {
    id: 'coupon',
    label: 'Coupon',
    align: 'left',
    numeric: false,
  },
  {
    id: 'completionmsg',
    label: 'Completion Message',
    align: 'left',
    numeric: false,
  },

  {
    id: 'bussinessaddress',
    label: 'Business Address',
    align: 'left',
    numeric: false,
  },
];

export { headCells };
