import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { styledButton } from 'components/Button/types';

const useStyles = makeStyles(() => ({
  root: styledButton(),
}));

const CustomButton = ({
  label,
  disabled,
  onClick,
  styles,
  fullWidth,
  startIcon,
  endIcon,
  style,
  type,
}) => {
  let classes = useStyles();

  if (styles) {
    classes = styles;
  }

  return (
    <Button
      className={classes.root}
      type={type}
      fullWidth={fullWidth}
      variant='contained'
      disabled={disabled}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon ? endIcon : null}
      onClick={() => _.isFunction(onClick) && onClick()}>
      {label}
    </Button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomButton.defaultProps = {
  fullWidth: true,
};

export default CustomButton;
