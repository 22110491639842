import React, { useEffect, useRef, useState } from 'react';
import {
  PageContainer,
  HeadingContainer,
  HeadingTitle,
  BodyContainer,
} from 'css/components/Body';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import { purpleGradientButton } from 'components/Button/types';
import Button from 'components/Button';
import styled from 'styled-components';
import Select from 'components/Select';
import { PURPLE } from '@colors';
import SimpleTable from 'components/SimpleTable';
import { useDispatch, useSelector } from 'react-redux';
import { getFitnessMatrix } from '@actions/fitnessMatrix';
import { getDeviceList } from '@actions/deviceList';
import {
  getFitnessDetails as fetchFitness,
  getFitnessCSV,
} from '@actions/fitnessAnalytics';
import { getGroupIds } from '@actions/groupId';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleGradientButton(),
    marginTop: '16px',
    marginBottom: '8px',
    height: '45px',
  },
}));

const btnLabel = 'Download Excel';

const FitnessAnalytics = () => {
  const dispatch = useDispatch();
  const btnStyle = useButtonStyles();
  const { role_id } = useSelector((state) => state.auth.profile);
  const { deviceList } = useSelector((state) => state.deviceList);
  const groupId = useSelector((state) => state?.groupId);
  const selfGroupId = useSelector((state) => state?.auth?.profile?.group_id);
  const [tableData, setTableData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const [selectedAttribute, setSelectedAttribute] = useState(undefined);
  const fitnessData = useSelector((state) => state?.fitnessMatrix?.data);
  const [weekDates, setWeekDates] = useState([]);
  const [params, setParams] = useState({});
  const linkRef = useRef(undefined);
  const fitnessAnalyticsData = useSelector(
    (state) => state?.fitnessAnalytics?.data
  );
  const [headings, setHeadings] = useState([]);
  const [CSVData, setCSVData] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (!deviceList.length) {
        await dispatch(getDeviceList());
      }
      if (!groupId.length) {
        await dispatch(getGroupIds());
      }

      if (!fitnessData.length) {
        await dispatch(getFitnessMatrix());
      }
    };
    fetchData();
  }, [
    dispatch,
    fitnessData,
    deviceList,
    groupId,
    params,
    selectedAttribute,
    selectedDevice,
    selectedGroup,
  ]);

  useEffect(() => {
    const fetchFitnessData = async () => {
      await dispatch(fetchFitness(params));
      if (Object.keys(params).length) {
        let res = await dispatch(getFitnessCSV(params));
        setCSVData(res?.message);
      }
    };
    fetchFitnessData();
  }, [dispatch, params]);

  useEffect(() => {
    formatData();
  }, [fitnessAnalyticsData]);

  useEffect(() => {
    setWeekDates(getLastWeekDates());
  }, []);

  const formatData = () => {
    let head = [];
    head.push('User');
    let allData = [];

    fitnessAnalyticsData?.forEach((item, index) => {
      let row = [];
      if (!item?.fitnessAnalytics?.length) row.push('No user');
      else row.push(item?.username);
      weekDates.forEach((itr) => {
        if (head?.length <= 7) head.push(itr);
        let found = item?.fitnessAnalytics.find((i) => i.date === itr);
        if (found) {
          row.push(found?.value);
        } else {
          row.push('No data');
        }
      });
      allData.push(row);
    });

    setHeadings(head);
    setTableData(allData);
  };

  const getLastWeekDates = () => {
    let result = [];
    for (let i = 0; i < 7; i++) {
      let d = new Date();
      d.setDate(d.getDate() - i);
      result.push(formatDate(d));
    }
    return result;
  };

  const formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    date = mm + '/' + dd + '/' + yyyy;
    return date;
  };

  const fetchNewData = () => {
    setParams({
      ...params,
      groupId: role_id === 1 ? selectedGroup : selfGroupId,
      attribute: selectedAttribute,
      deviceId: selectedDevice,
    });
  };

  console.log(selfGroupId);

  return (
    <PageContainer>
      <HeadingContainer style={{ height: 'auto', padding: '1px 17px' }}>
        <HeadingTitle>Fitness Analytics</HeadingTitle>
        <Container>
          <div>
            <p>Fitness Attribute</p>
            <Select
              fullWidth={false}
              options={fitnessData}
              labelKey='fitnessMetric'
              valueKey='Attribute'
              theme='underLine'
              value={selectedAttribute}
              onChange={(value) => setSelectedAttribute(value)}
            />
          </div>
          <div>
            <p>Group ID</p>
            <Select
              fullWidth={false}
              readOnly={role_id !== 1 ? true : false}
              options={groupId?.length ? groupId : []}
              onChange={(value) => setSelectedGroup(value)}
              value={role_id !== 1 ? selfGroupId : selectedGroup}
              theme='underLine'
            />
          </div>
          <div>
            <p>Device Type</p>
            <Select
              fullWidth={false}
              options={deviceList}
              labelKey='devicename'
              valueKey='device_id'
              theme='underLine'
              onChange={(value) => setSelectedDevice(value)}
              value={selectedDevice}
            />
          </div>
          <Button
            disabled={
              !(
                selectedAttribute &&
                selectedDevice &&
                (selectedGroup || role_id !== 1)
              )
            }
            styles={btnStyle}
            fullWidth={false}
            label={'Apply'.toUpperCase()}
            onClick={fetchNewData}
          />
        </Container>
      </HeadingContainer>
      {CSVData && fitnessAnalyticsData.length ? (
        <BodyContainer style={{ padding: '10px 17px' }}>
          <HeadingTitle>Device Name</HeadingTitle>
          {headings?.length >= 7 && tableData?.length && (
            <SimpleTable head={headings} rowData={tableData} />
          )}
          <ButtonWrapper>
            <div style={{ marginBottom: '20px' }}>
              <Button
                onClick={async () => {
                  linkRef?.current?.link?.click();
                }}
                styles={btnStyle}
                fullWidth={false}
                label={btnLabel.toUpperCase()}
              />

              <CSVLink
                style={{ display: 'none' }}
                ref={linkRef}
                data={CSVData ? CSVData : 'No data found'}>
                {btnLabel}
              </CSVLink>
            </div>
          </ButtonWrapper>
        </BodyContainer>
      ) : (
        <BodyContainer
          style={{
            padding: '10px 17px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text>No data found</Text>
        </BodyContainer>
      )}
    </PageContainer>
  );
};

export default FitnessAnalytics;

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div {
    width: 100%;
    max-width: 298px;
    padding-right: 40px;
    > div {
      margin: 0;
    }
    :last-child {
      padding: 0;
    }
    > p {
      margin: 0;
      font-size: 18px;
      color: PURPLE;
      font-weight: normal;
    }
  }
`;

const Text = styled.div`
  text-align: center;
  font-size: 22px;
  padding: 50px;
  color: ${PURPLE};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  > button {
    height: 42px;
    min-width: 209px;
    font-size: 14px;
  }
`;

// const CSVButton = styled(CSVLink)`
//   padding: 10px 20px;
//   color: #ffffff;
//   height: 45px;
//   font-size: 18px;
//   background: linear-gradient(to right, #96498e, #793a72);
//   margin-top: 16px;
//   font-family: 'Poppins', sans-serif;
//   font-weight: 600;
//   margin-bottom: 8px;
//   border-radius: 5px;
//   text-decoration: none;
//   text-transform: capitalize;
// `;
