import { INITIAL_FIELD_STATE } from '@reducers/common';

const initial = {
  title: {
    ...INITIAL_FIELD_STATE,
  },
  detail: {
    ...INITIAL_FIELD_STATE,
  },
  startDate: {
    ...INITIAL_FIELD_STATE,
  },
  endDate: {
    ...INITIAL_FIELD_STATE,
  },
  redemption: {
    ...INITIAL_FIELD_STATE,
    options: [],
  },
  url: {
    ...INITIAL_FIELD_STATE,
  },
};

export { initial };
