const headCells = [
  {
    id: 'firstname',
    label: 'First Name',
    align: 'left',
    numeric: false,
  },
  {
    id: 'lastname',
    label: 'Last Name',
    align: 'left',
    numeric: false,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    numeric: false,
  },
  {
    id: 'phone',
    label: 'Phone Number',
    align: 'left',
    numeric: false,
  },
  {
    id: 'dob',
    label: 'Date of Birth',
    align: 'left',
    numeric: false,
  },
  {
    id: 'zipCode',
    label: 'Zip Code',
    align: 'left',
    numeric: true,
  },
];

export { headCells };
