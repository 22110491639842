const FIELDS_LABELS = {
  EMAIL: 'Username',
  PASSWORD: 'Password',
};

const BUTTON_LABELS = {
  LOGIN: 'Login',
};

export { FIELDS_LABELS, BUTTON_LABELS };
