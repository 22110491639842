import moment from 'moment';
import {
  UPDATE_CHALLENGE_FIELD,
  SET_CHALLENGE_START_DATE,
  SET_CHALLENGE_END_DATE,
} from '@actions/challenge';
import { CREATE_CHALLENGES_KEYS } from '@reduxConstants/create-challenge';

const {
  INCENTIVE_MESSAGE,
  FITNESS_METRIC_ID,
  FITNESS_METRIC_BENCHMARK,
  START_DATE,
  END_DATE,
  COUPON_ID,
  CHALLENGE_AREA_ID,
  CHALLENGE_TYPE_ID,
  RADIUS,
  COMPLETION_MESSAGE,
  BUSINESS_NAME,
  BUSINESS_LOGO,
  BUSINESS_ADDRESS,
  TITLE,
  CHALLENGE_WIN_LIMIT,
} = CREATE_CHALLENGES_KEYS;

const initiateData = (challenge, updateFieldValue, dispatch) => {
  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: INCENTIVE_MESSAGE,
    value: challenge?.[INCENTIVE_MESSAGE],
  });
  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: CHALLENGE_WIN_LIMIT,
    value: challenge?.[CHALLENGE_WIN_LIMIT] || 5,
  });
  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: TITLE,
    value: challenge?.[TITLE],
  });
  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: FITNESS_METRIC_ID,
    value: challenge?.[FITNESS_METRIC_ID],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: FITNESS_METRIC_BENCHMARK,
    value: challenge?.[FITNESS_METRIC_BENCHMARK],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: COUPON_ID,
    value: challenge?.[COUPON_ID],
  });

  updateFieldValue(dispatch, SET_CHALLENGE_START_DATE, {
    value: moment(challenge?.[START_DATE]).toDate(),
  });

  updateFieldValue(dispatch, SET_CHALLENGE_END_DATE, {
    value: moment(challenge?.[END_DATE]).toDate(),
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: CHALLENGE_AREA_ID,
    value: challenge?.[CHALLENGE_AREA_ID],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: CHALLENGE_TYPE_ID,
    value: challenge?.[CHALLENGE_TYPE_ID],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: RADIUS,
    value: challenge?.[RADIUS],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: COMPLETION_MESSAGE,
    value: challenge?.[COMPLETION_MESSAGE],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: BUSINESS_NAME,
    value: challenge?.[BUSINESS_NAME],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: BUSINESS_ADDRESS,
    value: challenge?.[BUSINESS_ADDRESS],
  });

  updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
    key: BUSINESS_LOGO,
    value: challenge?.[BUSINESS_LOGO],
  });
};

export { initiateData };
