import styled from 'styled-components';
import { PURPLE, WHITE, DARK_GRAY } from '@colors';

const Container = styled.div``;

const CreateChallenge = styled.div`
  text-align: right;
`;

const PageForm = styled.div`
  padding: 15px;
  background-color: #eae7ec;
  height: 100%;
  border-radius: 6px;
  display: flex;
`;

const Img = styled.img`
  border-radius: 100%;
  border: 1.5px solid ${PURPLE};
  background-color: ${WHITE};
  padding: 3px;
  height: 23px;
  width: 23px;
  box-shadow: 0px 5px 10px -5px rgb(128 128 128);
`;

const ImageContainer = styled.div`
  text-align: center;
`;

const ChallengeDetailTitle = styled.h3`
  text-align: center;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #830072;
  color: #830072;
  background-color: #eae7ec;
`;

const ChallengeDetailLogo = styled.img`
  width: auto;
  max-width: 300px;
`;
const ChallengeDetailBody = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const ChallengeDetailRow = styled.div`
  display: flex;
  margin: 20px;
`;

const ChallengeDetailImage = styled.img`
  width: 30px;
`;

const ChallengeDetailCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChallengeDetailValue = styled.h5`
  color: ${DARK_GRAY};
  text-align: center;
`;

const ChallengeDetailButton = styled.button`
  padding: 10px 0;
  width: 100px;
  background: #830072;
  border: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-weight: bold;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
`;

export {
  Container,
  CreateChallenge,
  PageForm,
  Img,
  ImageContainer,
  ChallengeDetailTitle,
  ChallengeDetailLogo,
  ChallengeDetailBody,
  ChallengeDetailRow,
  ChallengeDetailImage,
  ChallengeDetailCol,
  ChallengeDetailValue,
  ChallengeDetailButton,
};
