import React from 'react';
import BrandLogo from 'assets/images/logo.png';
import { Logo, Container, LogoWrapper } from 'css/components/Header';

const MenuAppBar = () => {
  return (
    <Container>
      <LogoWrapper>
        <Logo src={BrandLogo} alt='logo' />
      </LogoWrapper>
    </Container>
  );
};

export default MenuAppBar;
