import {
  CREATE_CHALLENGES_KEYS,
  LONGITUDE,
  LATITUDE,
  FROM_USER_CHALLENGE_ID,
} from '@reduxConstants/create-challenge';

const {
  INCENTIVE_MESSAGE,
  FITNESS_METRIC_ID,
  FITNESS_METRIC_BENCHMARK,
  START_DATE,
  END_DATE,
  COUPON_ID,
  CHALLENGE_AREA_ID,
  RADIUS,
  COMPLETION_MESSAGE,
  BUSINESS_NAME,
  BUSINESS_LOGO,
  CHALLENGE_TYPE_ID,
  CHALLENGE_XML,
  CHALLENGE_BUNDLE,
  CHALLENGE_BUNDLE_IOS,
  BUSINESS_ADDRESS,
  TITLE,
  CHALLENGE_WIN_LIMIT,
} = CREATE_CHALLENGES_KEYS;

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const disabled = (challenge) => {
  const start = new Date(challenge?.[START_DATE]?.value);
  let disabled = !(
    challenge?.[INCENTIVE_MESSAGE].valid &&
    challenge?.[FITNESS_METRIC_ID].valid &&
    challenge?.[FITNESS_METRIC_BENCHMARK].valid &&
    challenge?.[START_DATE].valid &&
    start > yesterday &&
    challenge?.[END_DATE].valid &&
    challenge?.[COUPON_ID].valid &&
    challenge?.[CHALLENGE_AREA_ID].valid &&
    challenge?.[COMPLETION_MESSAGE].valid &&
    challenge?.[BUSINESS_NAME].valid &&
    challenge?.[BUSINESS_LOGO].valid &&
    challenge?.[TITLE].valid &&
    challenge?.[CHALLENGE_WIN_LIMIT].valid &&
    challenge?.[BUSINESS_ADDRESS].valid
  );

  if (challenge?.[CHALLENGE_AREA_ID]?.value === 1 && !disabled) {
    disabled = !challenge?.[RADIUS].valid;
  }
  return disabled;
};

const makePayload = (data) => {
  const payload = {
    from_user_id: data?.from_user_id,
    [FITNESS_METRIC_ID]: data?.[FITNESS_METRIC_ID]?.value,
    [COUPON_ID]: data?.[COUPON_ID].value,
    [CHALLENGE_AREA_ID]: data?.[CHALLENGE_AREA_ID].value,
    [RADIUS]: data?.[CHALLENGE_AREA_ID]?.value === 1 ? data?.[RADIUS].value : 0,
    [INCENTIVE_MESSAGE]: data?.[INCENTIVE_MESSAGE].value,
    [FITNESS_METRIC_BENCHMARK]: data?.[FITNESS_METRIC_BENCHMARK].value,
    [COMPLETION_MESSAGE]: data?.[COMPLETION_MESSAGE].value,
    [BUSINESS_NAME]: data?.[BUSINESS_NAME].value,
    [BUSINESS_LOGO]: data?.[BUSINESS_LOGO].value,
    [CHALLENGE_TYPE_ID]: data?.[CHALLENGE_TYPE_ID].value,
    [CHALLENGE_XML]: data?.[CHALLENGE_XML]?.value,
    [CHALLENGE_BUNDLE]: data?.[CHALLENGE_BUNDLE]?.value,
    [CHALLENGE_BUNDLE_IOS]: data?.[CHALLENGE_BUNDLE_IOS]?.value,
    [BUSINESS_ADDRESS]: data?.[BUSINESS_ADDRESS].value,
    [LONGITUDE]: data?.lng,
    [LATITUDE]: data?.lat,
    [START_DATE]: data?.[START_DATE].value,
    [END_DATE]: data?.[END_DATE].value,
    [CHALLENGE_WIN_LIMIT]: data?.[CHALLENGE_WIN_LIMIT].value,
    [TITLE]: data?.[TITLE].value,
    transactiontype: data[FROM_USER_CHALLENGE_ID] ? 'U' : 'I',
    last_updated_by: data?.from_user_id,
    role_id: data?.role_id,
  };

  if (data[FROM_USER_CHALLENGE_ID]) {
    payload[FROM_USER_CHALLENGE_ID] = data[FROM_USER_CHALLENGE_ID];
  }
  return payload;
};

export { disabled, makePayload };
