import styled from 'styled-components';
import { PURPLE } from '@colors';

const PageContainer = styled.div`
  /* padding: 20px 15px 10px; */
  ${(props) =>
    props.variant === 'transparent'
      ? 'background-color: none'
      : 'background-color: rgb(140, 125, 155, 0.82);'}
  background-color: rgb(140, 125, 155, 0.82);
  flex: 1;
  ${(props) => (props.fullHeight ? 'height: 100%;' : '')}
  border-radius: 6px;
  padding: 15px;
  margin-left: 270px;
  @media (max-width: 1250px) {
    margin-left: 0;
  }
`;
const Pageform = styled.div`
  padding: 16px 15px 0px;
  background-color: #eae7ec;
  height: 100%;
  border-radius: 6px;
`;
const HeadingChallenge = styled.div`
  background-color: #eae7ec;
  padding: 17px 30px 0 25px;
  height: 70px;
  margin: 0 0px 13px 0;
  border-radius: 6px;
`;

const Textback = styled.div`
  margin-top: 15px;
`;

const Textbacktwo = styled.div`
  margin-top: 15px;
`;
const Textbackthree = styled.div``;
const Textbackfour = styled.div`
  margin-top: 32px;
`;
const Textbackfive = styled.div`
  margin-top: 15px;
`;
const Textbacksix = styled.div`
  margin-top: 15px;
`;
const Textbackseven = styled.div`
  margin-top: 15px;
`;
const Textbackeight = styled.div`
  margin-top: 15px;
`;

const Textbacknine = styled.div`
  width: 100%;
`;
const HeadingContainer = styled.div`
  background-color: rgb(255, 255, 255, 0.82);
  padding: 1px 30px 5px 30px;
  height: 99px;
  border-radius: 6px;
`;

const BodyContainer = styled.div`
  background-color: rgb(255, 255, 255, 0.82);
  min-height: calc(100vh - 429px);
  margin: 15px 0 4px 0;
  border-radius: 6px;
  overflow: hidden;
`;
const HeadInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const HeadingTitle = styled.h2`
  color: ${PURPLE};
  font-size: 21px;
  font-weight: 100;
  font-family: inherit;
`;

const HeadingSubTitle = styled.h2`
  color: ${PURPLE};
  font-size: 16px;
  font-weight: 100;
  font-family: inherit;
`;

const Maintextfield = styled.div`
  background-color: #fff;
`;
const Backpaper = {
  backgroundColor: '#9C8EA8',
};

export {
  PageContainer,
  HeadingContainer,
  BodyContainer,
  HeadingTitle,
  HeadInnerContainer,
  HeadingChallenge,
  Backpaper,
  Pageform,
  Textback,
  Textbacktwo,
  Textbackthree,
  Textbackfour,
  Textbackfive,
  Textbacksix,
  Textbackseven,
  Textbackeight,
  Textbacknine,
  Maintextfield,
  HeadingSubTitle,
};
