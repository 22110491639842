import React from 'react';
import { PURPLE } from '@colors';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

const Popup = (props) => {
  const history = useHistory();
  const style = {
    heading: {
      color: PURPLE,
      margin: 0,
      fontSize: 16,
    },
    description: {
      color: PURPLE,
      fontSize: 10,
      fontWeight: 100,
    },
  };
  const heading =
    props.mapType === 'user'
      ? `${props.item.firstname} ${props.item.lastname}`
      : `${props.item.title}`;

  const description =
    props.mapType === 'user'
      ? ``
      : `${props.item.noofusersacceptedchallenge} user have joined this challenge`;

  return (
    <div>
      <div>
        <p style={style.heading}>{heading}</p>
        <span style={style.description}>{description}</span>
      </div>
      <button
        onClick={() =>
          props.mapType === 'user'
            ? history.push(ROUTES.USERS)
            : history.push(ROUTES.CHALLENGES)
        }>
        View Details
      </button>
    </div>
  );
};

export default Popup;
