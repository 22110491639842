const headCells = [
  {
    id: 'title',
    label: 'Title',
    align: 'left',
    numeric: false,
  },
  {
    id: 'detail',
    label: 'Detail',
    align: 'left',
    numeric: false,
  },
  {
    id: 'startdate',
    label: 'Start Date',
    align: 'left',
    numeric: false,
  },
  {
    id: 'enddate',
    label: 'End date',
    align: 'left',
    numeric: true,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    numeric: false,
  },
];

export { headCells };
