import styled from 'styled-components';
import BackgroundImage from 'assets/images/error-bg.png';

const Container = styled.div`
  background-image: url(${BackgroundImage});
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background-position: 50%;
  background-size: cover;
`;

const Wrapper = styled.div`
  height: 100%;
  /* padding: 90px 0; */
  text-align: center;
  color: white;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: clamp(77px, 16.5vw, 239px);
  font-weight: 800;
`;

const SubHeading = styled.div`
  font-size: clamp(17px, 3.5vw, 35px);
  font-size: 35px;
  font-family: inherit;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  padding: 0 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  > button {
    margin: 10px 10px;
    max-width: 380px;
    width: 100%;
    border-radius: 8px;
  }
`;

export { Container, Wrapper, Heading, SubHeading, ButtonWrapper };
