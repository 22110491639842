import UsersIcon from 'assets/svg/UsersIcon';
import HomeIcon from 'assets/svg/HomeIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import MapIcon from 'assets/svg/MapIcon';
import PaymentIcon from 'assets/svg/PaymentIcon';
import FitnessIcon from 'assets/svg/FitnessAnalyticsIcon';
import BodyIcon from 'assets/svg/BodyAnalyticsIcon';
import { ROUTES } from 'constants/routes';

const ADMIN_MENUS = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    link: ROUTES.HOME,
  },
  {
    title: 'Map',
    icon: <MapIcon />,
    link: ROUTES.MAP,
  },
  {
    title: 'User Management',
    icon: <UsersIcon />,
    link: ROUTES.USERS,
  },
  {
    title: 'Create Coupons',
    icon: <PlusIcon />,
    link: ROUTES.COUPONS,
  },
  {
    title: 'Create Challenges',
    icon: <PlusIcon />,
    link: ROUTES.CHALLENGES,
  },
  {
    title: 'Payment',
    icon: <PaymentIcon />,
    link: ROUTES.PAYMENT,
  },
  {
    title: 'Register Admin',
    icon: <PlusIcon />,
    link: ROUTES.REGISTER_ADMIN,
  },
  {
    title: 'Fitness Analytics',
    icon: <FitnessIcon />,
    link: ROUTES.FITNESS_ANALYTICS,
  },
  {
    title: 'Body Analytics',
    icon: <BodyIcon />,
    link: ROUTES.BODY_ANALYTICS,
  },
];

export { ADMIN_MENUS };
