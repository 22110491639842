import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ADMIN_MENUS } from 'components/SideMenu/menus';
import { logout as logoutUser } from '@actions/auth';
import { permissions } from 'constants/routes';
import LogoutIcon from 'assets/svg/LogoutIcon';
import {
  List,
  ListItem,
  ListItemText,
  StyledLink,
} from 'css/components/SideMenu';

const SideMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { role_id, user_id } = useSelector((state) => state?.auth?.profile);
  // const menus = role_id === 1 ? ADMIN_MENUS : [];
  const menus = ADMIN_MENUS;

  return (
    <List isAdmin={true}>
      {menus.map((menu, index) => {
        return permissions[role_id].includes(menu.link) ? (
          <ListItem
            key={`${menu.title}${index}`}
            onClick={() => history.push(menu.link)}>
            <StyledLink
              className={
                location.pathname === menu?.link ||
                (location.pathname.includes(menu?.link) && menu?.link !== '/')
                  ? 'active'
                  : null
              }>
              {menu.icon}
              <ListItemText>{menu.title}</ListItemText>
            </StyledLink>
          </ListItem>
        ) : null;
      })}
      <ListItem>
        <StyledLink
          onClick={async () => {
            await dispatch(logoutUser(user_id));
          }}>
          <LogoutIcon />
          <ListItemText>Logout</ListItemText>
        </StyledLink>
      </ListItem>
    </List>
  );
};

export default SideMenu;
