import * as React from 'react';

const Icon = (props) => (
  <svg width={26.04} height={26.04} viewBox='0 0 26.04 26.04' {...props}>
    <path
      d='M0 13.02A13.02 13.02 0 1013.02 0 13.018 13.018 0 000 13.02zm18.1-2.717a1.213 1.213 0 011.709 0 1.193 1.193 0 01.351.851 1.214 1.214 0 01-.357.858l-5.9 5.884a1.207 1.207 0 01-1.665-.038l-5.99-5.972a1.208 1.208 0 011.709-1.709l5.068 5.128z'
      fill='#7f3d78'
    />
  </svg>
);

export default Icon;
