import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/TextField';
import { TextField as MaterialField } from '@material-ui/core';
import { purpleButton } from 'components/Button/types';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from 'components/TextField/useStyles';
import { getTheme } from 'components/TextField/Theme';
import MasterCard from 'assets/images/master-card-icon.png';
import Visa from 'assets/images/visa-card-icon.png';
import DefaultCard from 'assets/images/credit-card.png';
import AmericanExpress from 'assets/images/american-express-icon.png';
import { FormField } from 'css/components/TextField';
import Button from 'components/Button';
import { performTransaction, fetchToken } from '@actions/payment';
import { SET_DIALOG } from '@actions/dialog';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

const PaymentForm = ({ spacing = 5, btn }) => {
  const dispatch = useDispatch();
  const { role_id, user_id } = useSelector((state) => state?.auth?.profile);
  const [paymentDetails, setPaymentDetails] = useState({});
  const buttonStyles = useButtonStyles();
  const [cardIcon, setCardIcon] = useState(DefaultCard);
  const classes = useStyles(getTheme('transparentBorder'));

  const handleClick = async () => {
    let data = {
      'card[number]': paymentDetails?.number,
      'card[exp_month]': paymentDetails?.expiry.split('/')[0],
      'card[exp_year]': paymentDetails?.expiry.split('/')[1],
      'card[cvc]': paymentDetails?.cvc,
      'card[name]': paymentDetails?.name,
      'card[address_zip]': paymentDetails?.zipcode,
    };

    let res = await dispatch(fetchToken(data));
    if (res?.error) {
      dispatch({
        type: SET_DIALOG,
        payload: {
          show: true,
          message: res?.message?.data?.error?.message || 'An error occured',
          error: true,
        },
      });
      return;
    }

    let payload = {
      name: paymentDetails?.name,
      email: paymentDetails?.email,
      phone: paymentDetails?.phone,
      zipcode: paymentDetails?.zipcode,
      amount: parseFloat(paymentDetails?.amount) * 100,
      stripe_token: res?.data?.id,
      role_id: role_id,
      last_updated_by: user_id,
      last_updated_ts: moment.utc().local().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    };

    let transaction = await dispatch(performTransaction(payload));
    if (!transaction.error) {
      dispatch({
        type: SET_DIALOG,
        payload: {
          show: true,
          message: `Transaction with ID ${transaction?.data?.transaction_id} successful`,
          error: false,
        },
      });
    } else {
      alert(`Transaction failed. Please retry.`);
    }
    setPaymentDetails({});
  };

  const validDate = (dValue) => {
    let result = false;
    if (dValue) {
      dValue = dValue?.split('/');
      let pattern = /^\d{2}$/;
      let d = new Date();
      let n = d.getYear();

      if (dValue[0] < 1 || dValue[0] > 12) result = true;

      if (dValue[1] < n % 100 || dValue[1] > (n % 100) + 10) result = true;

      if (!pattern.test(dValue[0]) || !pattern.test(dValue[1])) result = true;

      if (dValue[2]) result = true;

      if (result) return result;
    } else return result;
  };

  const testEmail = (val) => {
    return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    );
  };

  const disabled = !(
    paymentDetails?.name &&
    paymentDetails?.email &&
    !testEmail(paymentDetails?.email) &&
    paymentDetails?.phone &&
    paymentDetails?.phone?.length >= 10 &&
    paymentDetails?.zipcode &&
    paymentDetails?.zipcode?.length >= 6 &&
    paymentDetails?.amount &&
    paymentDetails?.number &&
    paymentDetails?.number?.length >= 16 &&
    !validDate(paymentDetails?.expiry) &&
    paymentDetails?.expiry &&
    paymentDetails?.cvc &&
    paymentDetails?.cvc?.length >= 3
  );

  const changeCard = (item) => {
    if (paymentDetails?.number) {
      switch (paymentDetails?.number[0]) {
        case '5':
          setCardIcon(MasterCard);
          break;
        case '4':
          setCardIcon(Visa);
          break;
        case '3':
          setCardIcon(AmericanExpress);
          break;
        default:
          setCardIcon(DefaultCard);
      }
    }
  };

  return (
    <Grid spacing={spacing} container>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Name'
          value={paymentDetails?.name}
          onChange={(value) => {
            if (/^[A-Za-z]+$/.test(value))
              setPaymentDetails({ ...paymentDetails, name: value });
          }}
          theme='transparentBorder'
          style={FormField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Email'
          value={paymentDetails?.email}
          error={testEmail(paymentDetails?.email) && paymentDetails?.email}
          helperText={
            testEmail(paymentDetails?.email) && paymentDetails?.email
              ? 'Please insert a valid email'
              : ''
          }
          type='email'
          onChange={(value) =>
            setPaymentDetails({ ...paymentDetails, email: value })
          }
          theme='transparentBorder'
          style={FormField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberFormat
          customInput={MaterialField}
          style={FormField}
          label='Phone'
          allowNegative={false}
          format='##########'
          displayType={'number'}
          className={classes.root}
          value={paymentDetails?.phone || ''}
          fullWidth={true}
          variant='outlined'
          onValueChange={(e) => {
            setPaymentDetails({ ...paymentDetails, phone: e?.value });
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberFormat
          customInput={MaterialField}
          style={{ ...FormField, borderColor: 'red' }}
          label='Card Number'
          className={classes.root}
          placeholder='**** **** **** ****'
          format='#### #### #### ####'
          fullWidth={true}
          value={paymentDetails?.number || ''}
          variant='outlined'
          onValueChange={(e) => {
            setPaymentDetails({ ...paymentDetails, number: e?.value });
            changeCard();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img
                  src={cardIcon}
                  height='20'
                  width='50'
                  alt='card-icon'></img>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <NumberFormat
              customInput={MaterialField}
              style={FormField}
              label='Card Expiry'
              className={classes.root}
              placeholder='MM/YY'
              value={paymentDetails?.expiry || ''}
              format='##/##'
              fullWidth={true}
              error={
                validDate(paymentDetails?.expiry) && paymentDetails?.expiry
              }
              helperText={
                validDate(paymentDetails?.expiry) && paymentDetails?.expiry
                  ? 'Enter a valid expiry date'
                  : ''
              }
              variant='outlined'
              onValueChange={(e) => {
                setPaymentDetails({
                  ...paymentDetails,
                  expiry: e?.formattedValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <NumberFormat
              customInput={MaterialField}
              style={FormField}
              label='Card CVC'
              format='###'
              displayType={'number'}
              className={classes.root}
              fullWidth={true}
              variant='outlined'
              value={paymentDetails?.cvc || ''}
              onValueChange={(e) => {
                setPaymentDetails({ ...paymentDetails, cvc: e?.value });
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <NumberFormat
              customInput={MaterialField}
              style={FormField}
              label='Zipcode'
              format='######'
              displayType={'number'}
              className={classes.root}
              value={paymentDetails?.zipcode || ''}
              fullWidth={true}
              variant='outlined'
              onValueChange={(e) => {
                setPaymentDetails({ ...paymentDetails, zipcode: e?.value });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberFormat
          customInput={MaterialField}
          style={FormField}
          label='Amount'
          className={classes.root}
          thousandSeparator={true}
          prefix={'$'}
          fullWidth={true}
          allowNegative={false}
          value={paymentDetails?.amount || ''}
          variant='outlined'
          onValueChange={(e) => {
            setPaymentDetails({ ...paymentDetails, amount: e?.value });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          styles={buttonStyles}
          disabled={disabled}
          label='Pay'
          onClick={handleClick}>
          Testing
        </Button>
      </Grid>
    </Grid>
  );
};

PaymentForm.propTypes = {
  spacing: PropTypes.number,
};

export default PaymentForm;
