import { get, post } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const GET_NOTIFICATIONS_DATA = 'GET_NOTIFICATIONS_DATA';

const getNotifications = (payload) => {
  const { userID, isInbox, offSet, numberOfRecords } = payload;
  return async (dispatch) => {
    try {
      let notificationsData = await get({
        url: `${REQUESTS.GET_INBOX}${userID}&isinbox=${isInbox}&offset=${offSet}&numofrecords=${numberOfRecords}`,
      });
      console.log(
        'getNotifications',
        `${REQUESTS.GET_INBOX}${userID}&isinbox=${isInbox}&offset=${offSet}&numofrecords=${numberOfRecords}`
      );
      notificationsData = notificationsData?.message || [];
      dispatch(
        updateField(GET_NOTIFICATIONS_DATA, {
          notificationsData,
        })
      );

      return notificationsData;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const updateNotifications = (payload) => {
  const { notification_ids } = payload;
  return async (dispatch) => {
    try {
      let response = await post({
        url: REQUESTS.UPDATE_NOTIFICATION,
        payload,
      });
      response = response?.message || [];

      return response;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

export { GET_NOTIFICATIONS_DATA, getNotifications, updateNotifications };
