import moment from 'moment';
import * as a from '@actions/challenge';
import { initial } from '@reducers/challenge/initialState';
import { CREATE_CHALLENGES_KEYS } from '@reduxConstants/create-challenge';

const { START_DATE, END_DATE } = CREATE_CHALLENGES_KEYS;

const challenge = (state = initial, action = null) => {
  switch (action.type) {
    case a.INITIATE_CHALLENGE:
      return initial;
    case a.UPDATE_CHALLENGE_DURATION: {
      return {
        ...state,
        value: action.value,
        valid: !!action.value,
      };
    }

    case a.SET_CHALLENGE_START_DATE:
      return {
        ...state,
        [START_DATE]: {
          value: action.value,
          valid: state?.[END_DATE]?.value
            ? moment(action.value).isSameOrBefore(
                moment(state?.[END_DATE]?.value)
              )
            : !!action.value,
        },
        [END_DATE]: {
          ...state?.[END_DATE],
          valid: state?.[END_DATE]?.value
            ? moment(action.value).isSameOrBefore(
                moment(state?.[END_DATE]?.value)
              )
            : !!state?.[END_DATE]?.value,
        },
      };

    case a.SET_CHALLENGE_END_DATE:
      return {
        ...state,
        [END_DATE]: {
          value: action.value,
          valid: state?.[START_DATE]?.value
            ? moment(action.value).isSameOrAfter(
                moment(state?.[START_DATE]?.value)
              )
            : !!action.value,
        },
        [START_DATE]: {
          ...state?.[START_DATE],
          valid: state?.[START_DATE]?.value
            ? moment(action.value).isSameOrAfter(
                moment(state?.[START_DATE]?.value)
              )
            : !!state?.[START_DATE]?.value,
        },
      };

    case a.UPDATE_CHALLENGE_FIELD:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
          valid: !!action.value && !!action?.value?.toString().trim().length,
        },
      };

    default:
      return state;
  }
};

export default challenge;
