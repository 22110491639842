import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';
import { HeadTextContainer } from 'css/components/Table';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    width: '300px',
  },
}));

const EnhancedTableToolbar = ({
  numSelected,
  title,
  onDelete,
  selected,
  onEdit,
  sendMail,
  btn,
  showRowData,
  titleComponent,
}) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  let styles = useStyles();

  return (
    <Toolbar
      style={{ background: 'none', height: '100%' }}
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <HeadTextContainer>
          {titleComponent || (
          <Typography
            className={classes.title}
            variant='h6'
            id='tableTitle'
            component='div'>
            {title}
          </Typography>
          )}
          <Typography
            className={classes.title}
            color='inherit'
            variant='subtitle1'
            component='div'>
            {numSelected} selected
          </Typography>
        </HeadTextContainer>
      ) : (
        titleComponent || (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'>
          {title}
        </Typography>
        )
      )}

      {numSelected > 0 && _.isFunction(sendMail) && (
        <Tooltip title='Send Mail' onClick={() => sendMail(selected)}>
          <IconButton>
            <MailIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 && _.isFunction(onDelete) && (
        <Tooltip title='Delete' onClick={() => onDelete(selected)}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected === 1 && _.isFunction(onEdit) && (
        <Tooltip title='Edit' onClick={() => onEdit(selected)}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected === 1 && _.isFunction(showRowData) && (
        <Tooltip title='More Information' onClick={() => showRowData(selected)}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      {btn && (
        <Button
          label={btn.label}
          fullWidth={btn?.fullWidth}
          styles={styles}
          onClick={btn.onclick !== undefined ? btn.onclick : () => history.push(btn.route)}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
};

export default EnhancedTableToolbar;
