import * as a from '@actions/challengesType';

const initialState = { data: [], error: null };

const challengesType = (state = initialState, action = null) => {
  switch (action.type) {
    case a.SET_CHALLENGES_TYPE:
      return {
        error: null,
        data: action.data,
      };
    case a.SET_CHALLENGES_TYPE_ERROR:
      return {
        error: action.error,
        data: [],
      };
    default:
      return state;
  }
};

export default challengesType;
