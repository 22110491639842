import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={22.468}
    height={22.468}
    viewBox='0 0 22.468 22.468'>
    <path
      d='M22.263 18.05l-6.816-6.816 6.816-6.816a.7.7 0 000-.993l-3.22-3.22a.7.7 0 00-.993 0l-6.821 6.816L4.418.205a.7.7 0 00-.993 0l-3.22 3.22a.7.7 0 000 .993l6.816 6.816L.205 18.05a.7.7 0 000 .993l3.224 3.22a.7.7 0 00.993 0l6.807-6.816 6.816 6.816a.7.7 0 00.993 0l3.22-3.22a.7.7 0 000-.993z'
      fill='#84407d'
    />
  </svg>
);

export default Icon;
