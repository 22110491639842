import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowDropdownIcon from 'assets/svg/ArrowDropdownIcon';

const FormSelect = {
  backgroundColor: 'red',
};

const InputLabelStyle = styled(InputLabel)`
  color: rgb(131, 0, 114) !important;
  font-weight: bold !important;
`;
const DropdownIcon = styled(ArrowDropdownIcon)`
  position: absolute;
  right: 13px;
  z-index: 0;
`;
const MenuList = styled.div`
  > li {
    padding: 14.5px 24px;
    border-bottom: 1px solid #7f3d78;
    color: #7f3d78;
    font-weight: 600;
    :last-child {
      border: none;
    }
  }
`;
export { FormSelect, InputLabelStyle, DropdownIcon, MenuList };
