import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21.241}
    height={21.241}
    {...props}>
    <defs>
      <style>
        {
          '.prefix__a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}'
        }
      </style>
    </defs>
    <path
      className='prefix__a'
      d='M19.741 10.62A9.12 9.12 0 1110.62 1.5a9.12 9.12 0 019.12 9.12zM10.62 6.972v7.3M6.972 10.62h7.3'
    />
  </svg>
);

export default Icon;
