import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/TextField';
import Datepicker from 'components/Datepicker';
import Select from 'components/Select';
import { updateField } from '@actions/common';
import { CREATE_CHALLENGES_KEYS } from '@reduxConstants/create-challenge';
import {
  UPDATE_CHALLENGE_FIELD,
  SET_CHALLENGE_START_DATE,
  SET_CHALLENGE_END_DATE,
  INITIATE_CHALLENGE,
} from '@actions/challenge';
import { getCoupons } from '@actions/coupon';
import Button from 'components/Button';
import NumberFormat from 'react-number-format';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import useStyles from 'components/TextField/useStyles';
import { getTheme } from 'components/TextField/Theme';
import { TextField as MaterialField } from '@material-ui/core';
import { getFitnessMatrix } from '@actions/fitnessMatrix';
import { getChallengesArea } from '@actions/challengesArea';
import { getChallengesType } from '@actions/challengesType';
import { getChallengeById } from '@selectors/challenge';
import { initiateData } from 'components/ChallengeForm/initiateData';
import { BusinessLogoWrapper } from 'css/components/ChallengeForm';
import { FormField } from 'css/components/TextField';
import Footer from 'pages/create-challenge/Footer';
import BusinessLogo from 'assets/images/map-marker.png';

const {
  INCENTIVE_MESSAGE,
  FITNESS_METRIC_ID,
  FITNESS_METRIC_BENCHMARK,
  START_DATE,
  END_DATE,
  COUPON_ID,
  CHALLENGE_AREA_ID,
  RADIUS,
  COMPLETION_MESSAGE,
  BUSINESS_NAME,
  BUSINESS_LOGO,
  BUSINESS_ADDRESS,
  CHALLENGE_WIN_LIMIT,
  TITLE,
  CHALLENGE_TYPE_ID,
  CHALLENGE_XML,
  CHALLENGE_BUNDLE,
  CHALLENGE_BUNDLE_IOS,
} = CREATE_CHALLENGES_KEYS;

const updateFieldValue = (dispatch, action, value) =>
  dispatch(updateField(action, value));

const Form = ({ spacing = 5, challenges = [], selectedChallengeId, btn }) => {
  const dispatch = useDispatch();
  const challenge = useSelector((state) => state?.challenge);
  const { user_id, role_id } = useSelector((state) => state?.auth?.profile);
  const fitnessMatrix = useSelector((state) => state?.fitnessMatrix?.data);
  const challengesArea = useSelector((state) => state?.challengesArea?.data);
  const challengesType = useSelector((state) => state?.challengesType?.data);
  const [coupons, setCoupons] = useState(undefined);
  const [imgPath, setImagePath] = useState(null);
  const classes = useStyles(getTheme('transparentBorder'));
  const startDate = challenge?.[START_DATE];
  const endDate = challenge?.[END_DATE];

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const showCoupon =
    !(
      challenge?.[START_DATE].value < yesterday &&
      challenge?.[START_DATE].value !== null
    ) &&
    challenge?.[START_DATE].valid &&
    challenge?.[END_DATE].valid &&
    ((challenge?.[END_DATE].value < yesterday &&
      challenge?.[END_DATE].value !== null) ||
      challenge?.[START_DATE].value <= challenge?.[END_DATE].value);

  const logoDropzone = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDrop: (files) => {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePath(event.target.result);
        updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
          key: BUSINESS_LOGO,
          value: file,
        });
      };
      reader.readAsDataURL(file);
    },
  });

  const xmlDropZone = useDropzone({
    //accept: 'application/xml, application/xml',
    multiple: false,
    onDrop: (xmlFiles) => {
      const xmlFile = xmlFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        // setXmlPath(event.target.result);
        updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
          key: CHALLENGE_XML,
          value: xmlFile,
        });
      };
      reader.readAsDataURL(xmlFile);
    },
  });

  const bundleDropzone = useDropzone({
    //accept: 'application/zip, application/zip',
    multiple: false,
    onDrop: (files) => {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        //setBundlePath(event.target.result);
        updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
          key: CHALLENGE_BUNDLE,
          value: file,
        });
      };
      reader.readAsDataURL(file);
    },
  });

  const iOSBundleDropzone = useDropzone({
    //accept: 'application/zip, application/zip',
    multiple: false,
    onDrop: (files) => {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        //setBundlePath(event.target.result);
        updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
          key: CHALLENGE_BUNDLE_IOS,
          value: file,
        });
      };
      reader.readAsDataURL(file);
    },
  });

  const isActiveCoupon = (data, key, date = undefined) => {
    let endDate = moment(data?.[key]).format('YYYY-MM-DD');
    let today = date
      ? moment(date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    return moment(endDate).isSameOrAfter(today);
  };

  const onlyContainsInt = (value) => {
    return /^\d+|\+|\.+|" "+$/.test(value);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      if (!coupons && showCoupon) {
        let userId = role_id === 1 ? undefined : user_id;
        const data = await dispatch(getCoupons(userId));
        if (data.error) {
          alert(data.message);
          return;
        }
        let coupon_list = data.filter((item) =>
          isActiveCoupon(item, 'enddate', challenge?.[END_DATE].value)
        );
        if (!coupon_list?.length) {
          updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
            key: COUPON_ID,
            value: null,
          });
          coupon_list.push({
            coupon_id: 0,
            title: 'No coupon for selected date range',
          });
        }
        setCoupons(coupon_list);
      }
    };
    fetchCoupons();
  }, [dispatch, coupons, role_id, user_id, startDate, endDate]);

  useEffect(() => {
    setCoupons(undefined);
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!fitnessMatrix.length) {
        return await dispatch(getFitnessMatrix());
      }
      if (!challengesArea.length) {
        return await dispatch(getChallengesArea());
      }
      if (!challengesType.length) {
        return await dispatch(getChallengesType());
      }
    };
    fetchData();
  }, [dispatch, fitnessMatrix, challengesArea, challengesType]);

  useEffect(() => {
    if (selectedChallengeId) {
      const data = getChallengeById(challenges, selectedChallengeId);
      initiateData(data, updateFieldValue, dispatch);
      setImagePath(challenge?.[BUSINESS_LOGO]?.value);
    }
  }, [dispatch, challenges, selectedChallengeId]);

  useEffect(() => {
    if (!selectedChallengeId) {
      dispatch({ type: INITIATE_CHALLENGE });
    }
  }, [dispatch, selectedChallengeId]);

  const test = challenge?.[FITNESS_METRIC_BENCHMARK]?.value;

  return (
    <>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Title'
            theme='transparentBorder'
            style={FormField}
            value={challenge?.[TITLE]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: TITLE,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            customInput={MaterialField}
            style={{ ...FormField, marginTop: '16px' }}
            label='Challenge Win Limit (Default value is 5)'
            className={classes.root}
            fullWidth={true}
            variant='outlined'
            format='########'
            value={challenge?.[CHALLENGE_WIN_LIMIT]?.value}
            error={challenge?.[CHALLENGE_WIN_LIMIT]?.value < 0}
            helperText={
              challenge?.[CHALLENGE_WIN_LIMIT]?.value < 0 ? 'Invalid value' : ''
            }
            onBlur={(e) => {
              if (!challenge?.[CHALLENGE_WIN_LIMIT]?.value) {
                updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                  key: CHALLENGE_WIN_LIMIT,
                  value: 5,
                });
              }
            }}
            onValueChange={(e) => {
              let floatVal = e?.floatValue;
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: CHALLENGE_WIN_LIMIT,
                value: floatVal,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Incentive Message'
            theme='transparentBorder'
            style={FormField}
            value={challenge?.[INCENTIVE_MESSAGE]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: INCENTIVE_MESSAGE,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            options={fitnessMatrix.filter(
              (item) => item.fitnessMetric === 'Steps' || item.fitnessMetric === 'Distance'
            )}
            label='Fitness Matrix'
            labelKey='fitnessMetric'
            theme='transparentBorder'
            style={FormField}
            valueKey={FITNESS_METRIC_ID}
            value={challenge?.[FITNESS_METRIC_ID]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: FITNESS_METRIC_ID,
                value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Datepicker
            label='Start Date'
            value={challenge?.[START_DATE].value}
            minDate={new Date()}
            error={
              challenge?.[START_DATE].value < yesterday &&
              challenge?.[START_DATE].value !== null
            }
            helperText={
              challenge?.[START_DATE].value < yesterday &&
              challenge?.[START_DATE].value !== null
                ? 'Start date must be greater than current date'
                : ''
            }
            onChange={(value) =>
              updateFieldValue(dispatch, SET_CHALLENGE_START_DATE, {
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Datepicker
            label='End Date'
            value={challenge?.[END_DATE].value}
            minDate={challenge?.[START_DATE].value || new Date()}
            error={
              (challenge?.[END_DATE].value < yesterday ||
                challenge?.[START_DATE].value > challenge?.[END_DATE].value) &&
              challenge?.[END_DATE].value !== null
            }
            helperText={
              (challenge?.[END_DATE].value < yesterday ||
                challenge?.[START_DATE].value > challenge?.[END_DATE].value) &&
              challenge?.[END_DATE].value !== null
                ? 'End date must be greater than current & start date'
                : ''
            }
            onChange={(value) =>
              updateFieldValue(dispatch, SET_CHALLENGE_END_DATE, {
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFormat
            customInput={MaterialField}
            style={{ ...FormField, marginTop: '16px' }}
            label='Fitness Metric Benchmark'
            className={classes.root}
            fullWidth={true}
            variant='outlined'
            format='########'
            error={challenge?.[FITNESS_METRIC_BENCHMARK]?.value < 0}
            helperText={
              challenge?.[FITNESS_METRIC_BENCHMARK]?.value < 0
                ? 'Invalid value'
                : ''
            }
            value={test}
            onValueChange={(e) => {
              let floatVal = e?.floatValue;
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: FITNESS_METRIC_BENCHMARK,
                value: floatVal,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            options={
              showCoupon
                ? coupons
                : [
                    {
                      coupon_id: 0,
                      title: 'Select a date range for picking coupons',
                    },
                  ]
            }
            label='Coupons'
            labelKey='title'
            style={FormField}
            valueKey={COUPON_ID}
            theme='transparentBorder'
            value={challenge?.[COUPON_ID]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: COUPON_ID,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            options={challengesArea}
            label='Challenge Area'
            theme='transparentBorder'
            labelKey='challengearea'
            style={FormField}
            valueKey={CHALLENGE_AREA_ID}
            value={challenge?.[CHALLENGE_AREA_ID]?.value}
            onChange={(value) => {
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: CHALLENGE_AREA_ID,
                value,
              });
              if (value !== 1) {
                updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                  key: RADIUS,
                  value: null,
                });
              }
            }}
          />
        </Grid>
        {challenge && challenge?.[CHALLENGE_AREA_ID]?.value === 1 && (
          <Grid item xs={12} sm={6}>
            <Select
              options={challenge?.[RADIUS]?.options}
              label='Radius'
              style={FormField}
              theme='transparentBorder'
              value={challenge?.[RADIUS]?.value}
              onChange={(value) =>
                updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                  key: RADIUS,
                  value,
                })
              }
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            label='Completion Message'
            theme='transparentBorder'
            style={FormField}
            value={challenge?.[COMPLETION_MESSAGE]?.value}
            error={onlyContainsInt(challenge?.[COMPLETION_MESSAGE]?.value)}
            helperText={
              onlyContainsInt(challenge?.[COMPLETION_MESSAGE]?.value)
                ? 'Message cannot only contain numbers'
                : null
            }
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: COMPLETION_MESSAGE,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Business Name'
            theme='transparentBorder'
            style={FormField}
            value={challenge?.[BUSINESS_NAME]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: BUSINESS_NAME,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Business Address'
            theme='transparentBorder'
            style={FormField}
            value={challenge?.[BUSINESS_ADDRESS]?.value}
            onChange={(value) =>
              updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: BUSINESS_ADDRESS,
                value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            options={challengesType}
            label='Challenge Type'
            labelKey='challengetype'
            theme='transparentBorder'
            style={FormField}
            valueKey={CHALLENGE_TYPE_ID}
            value={challenge?.[CHALLENGE_TYPE_ID]?.value}
            onChange={(value) =>
              {
                updateFieldValue(dispatch, UPDATE_CHALLENGE_FIELD, {
                key: CHALLENGE_TYPE_ID,
                value: value,
              })}
            }
          />
        </Grid>
        {challenge && challenge?.[CHALLENGE_TYPE_ID]?.value === 2 && (
          <Grid item xs={12} sm={6}>
            <div
            style={{ marginTop: '16px', marginBottom: '8px' }}
            {...xmlDropZone.getRootProps()}>
            <input {...xmlDropZone.getInputProps()} />
            <Button
              fullWidth={true}
              label='Upload AR XML'
              endIcon={<CloudUploadIcon />}
            />
          </div>
          </Grid>
        )}
        {challenge && challenge?.[CHALLENGE_TYPE_ID]?.value === 2 && (
          <Grid item xs={12} sm={6}>
            <div
            style={{ marginTop: '16px', marginBottom: '8px' }}
            {...bundleDropzone.getRootProps({ className: 'dropzone' })}>
            <input {...bundleDropzone.getInputProps()} />
            <Button
              fullWidth={true}
              label='Upload Android Bundle'
              endIcon={<CloudUploadIcon />}
            />
          </div>
          </Grid>
        )}
        {challenge?.[CHALLENGE_TYPE_ID]?.value === 2 && challenge && (
          <Grid item xs={12} sm={6}>
            <div
            style={{ marginTop: '16px', marginBottom: '8px' }}
            {...iOSBundleDropzone.getRootProps({ className: 'dropzone' })}>
            <input {...iOSBundleDropzone.getInputProps()} />
            <Button
              fullWidth={true}
              label='Upload iOS Bundle'
              endIcon={<CloudUploadIcon />}
            />
          </div>
          </Grid>
        )}
        <Grid item xs={6}>
        <div
            style={{ marginTop: '16px', marginBottom: '8px' }}
            {...logoDropzone.getRootProps({ className: 'dropzone' })}>
            <input {...logoDropzone.getInputProps()} />
            <Button
              fullWidth={true}
              label='Upload Business Logo'
              endIcon={<CloudUploadIcon />}
            />
          </div>
        </Grid>
        {btn && (
          <Grid item xs={6}>
            <Footer />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {challenge?.[BUSINESS_LOGO]?.value && (
          <BusinessLogoWrapper>
            <img
              alt='Business Logo'
              height='100'
              width='100'
              src={
                typeof challenge?.[BUSINESS_LOGO]?.value == 'string' &&
                challenge?.[BUSINESS_LOGO]?.value?.includes('http')
                  ? challenge?.[BUSINESS_LOGO]?.value
                  : imgPath
              }
              onError={(e) => {
                e.target.error = null;
                e.target.src = BusinessLogo;
                return BusinessLogo;
              }}
            />
          </BusinessLogoWrapper>
        )}
      </Grid>
    </>
  );
};

export default Form;
