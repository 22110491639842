import 'date-fns';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { getTheme } from 'components/Datepicker/Theme';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from 'components/TextField/useStyles';
import DatePickerIcon from 'assets/svg/DatepickerIcon';
const Datepicker = ({
  value,
  onChange,
  label,
  format,
  fullWidth,
  error,
  helperText,
  minDate,
  maxDate,
  theme,
}) => {
  const classes = useStyles(getTheme(theme));
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate={minDate}
        maxDate={maxDate}
        className={classes.root}
        helperText={helperText}
        error={error}
        margin='normal'
        label={label}
        format={format}
        value={value}
        fullWidth={fullWidth}
        onChange={(date) => _.isFunction(onChange) && onChange(date)}
        keyboardIcon={<DatePickerIcon />}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

Datepicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  theme: PropTypes.oneOf(['white', 'purple', 'grey', 'form']),
};

Datepicker.defaultProps = {
  value: new Date(),
  label: 'Date picker dialog',
  format: 'MM/dd/yyyy',
  fullWidth: true,
  theme: 'grey',
};

export default Datepicker;
