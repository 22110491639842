import { AUTH_KEYS, DISABLE_NOTIFICAION } from '@reduxConstants/auth';

const initial = {
  [AUTH_KEYS.AUTHENTICATED]: false,
  [AUTH_KEYS.ERROR]: null,
  [AUTH_KEYS.PROFILE]: null,
  [AUTH_KEYS.LOGOUT]: false,
  [DISABLE_NOTIFICAION]: false,
  [AUTH_KEYS.LATITUDE]:0.0,
  [AUTH_KEYS.LONGITUDE]:0.0
};

export { initial };
