import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_BADGE_LIST = 'SET_BADGE_LIST';
const getBadgesList = (userId) => {
  return async (dispatch) => {
    let badgesList = await get({
      url: `${REQUESTS.GET_BADGES}${userId}`,
    });
    badgesList = badgesList?.message || [];
    dispatch(
      updateField(SET_BADGE_LIST, {
        badgesList,
      })
    );
    return badgesList;
  };
};

export { getBadgesList, SET_BADGE_LIST };
