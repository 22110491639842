import * as a from '@actions/inbox';
import { INBOX_KEYS } from '@reduxConstants/inbox';

const inbox = (state = { messages: [], error: null }, action = null) => {
  switch (action.type) {
    case a.TOGGLE_MSGS:
      return {
        ...state,
        messages: state.messages.map((item, i) =>
          i === item.id
            ? {
                ...item,
                open: !item.open,
              }
            : item
        ),
      };

    case a.SET_INBOX_MSGS:
      return {
        ...state,
        messages: action.messages,
        [INBOX_KEYS.ERROR]: null,
      };
    case a.INBOX_ERROR:
      return {
        ...state,
        [INBOX_KEYS.ERROR]: action[INBOX_KEYS.ERROR],
        messages: [],
      };
    default:
      return state;
  }
};

export default inbox;
