import React from 'react';
import TextField from 'components/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from 'assets/svg/SearchIcon';
import { Title, Header } from 'css/pages/home';

const InfoHeader = ({ title, onChange, label, value }) => {
  return (
    <Header>
      <Title>{title}</Title>
      <TextField
        label={label}
        value={value}
        style={{ marginTop: '0px' }}
        variant='standard'
        theme='purple'
        endAdornment={
          <IconButton style={{ padding: 0 }}>
            <SearchIcon />
          </IconButton>
        }
        onChange={onChange}
      />
    </Header>
  );
};

export default InfoHeader;
