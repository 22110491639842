import * as a from '@actions/loading';

const loading = (state = false, action = null) => {
  switch (action.type) {
    case a.TOGGLE_LOADING: {
      return !state;
    }
    case a.HIDE_LOADING: {
      return false;
    }
    default:
      return state;
  }
};

export default loading;
