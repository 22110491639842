import React, { useEffect, useState } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import moment from 'moment';
import { Button, btnGroup } from 'css/pages/map';
import { getAllUsers } from '@actions/user';
import { getChallenges } from '@actions/challenge';
import MapBox from 'components/Map';
import {
  PageContainer,
  HeadingContainer,
  HeadingTitle,
  BodyContainer,
  HeadInnerContainer,
} from 'css/components/Body';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
const Map = () => {
  const dispatch = useDispatch();
  const [activeTab, toggleActive] = useState(true);
  const [allChallenge, setChallenge] = useState([]);
  const [allUsers, setUsers] = useState([]);
  const [mapData, setData] = useState([]);
  const { user_id, role_id } = useSelector((state) => state?.auth?.profile);

  const isActiveChallenge = (data, key) => {
    let endDate = moment(data?.[key]).format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    return moment(endDate).isSameOrAfter(today);
  };

  useEffect(() => {
    const fetchChallenges = async () => {
      if (allChallenge.length < 1) {
        let userId = role_id !== 1 ? user_id : undefined;
        const data = await dispatch(getChallenges(userId));
        if (data.error) {
          alert(data.message);
          return;
        }

        if (role_id !== 1) {
          let tempData = data.filter((i) => isActiveChallenge(i, 'enddate'));
          setChallenge(data.filter((item) => item?.from_user_id === user_id));
          setData(tempData.filter((item) => item?.from_user_id === user_id));
        } else {
          let tempData = data.filter((i) => isActiveChallenge(i, 'enddate'));
          setChallenge(data);
          setData(tempData);
        }
      }
    };
    fetchChallenges();
  }, [dispatch, allChallenge, role_id, user_id]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (allUsers.length < 1) {
        const data = await dispatch(getAllUsers());
        if (data.error) {
          alert(data.message);
          return;
        }
        setUsers(data.users);
        setData(data.users);
      }
    };
    fetchUsers();
  }, [dispatch, allUsers]);

  useEffect(() => {
    if (activeTab) {
      setData(allUsers);
    } else {
      setData(
        allChallenge.filter((item) => isActiveChallenge(item, 'enddate'))
      );
    }
  }, [activeTab, allUsers, allChallenge]);

  return (
    <PageContainer>
      <HeadingContainer>
        <HeadInnerContainer>
          <HeadingTitle>Map View</HeadingTitle>
          <ButtonGroup
            style={btnGroup}
            aria-label='outlined primary button group'>
            <Button onClick={() => toggleActive(false)} active={!activeTab}>
              Active challenge
            </Button>
            <Button onClick={() => toggleActive(true)} active={activeTab}>
              Active User
            </Button>
          </ButtonGroup>
        </HeadInnerContainer>
      </HeadingContainer>
      <BodyContainer>
        <MapWrapper>
          <MapBox data={mapData} mapType={activeTab ? 'user' : 'challenge'} />
        </MapWrapper>
      </BodyContainer>
    </PageContainer>
  );
};

export default Map;

const MapWrapper = styled.div`
  height: calc(100vh - 368px);
`;
