import * as a from '@actions/fitnessMatrix';

const initialState = { data: [], error: null };

const fitnessMatrix = (state = initialState, action = null) => {
  switch (action.type) {
    case a.SET_FITNESS_MATRIX:
      return {
        error: null,
        data: action.data,
      };
    case a.SET_FITNESS_ERROR:
      return {
        error: action.error,
        data: [],
      };
    default:
      return state;
  }
};

export default fitnessMatrix;
