import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={18.375}
    viewBox='0 0 21 18.375'
    {...props}>
    <path
      d='M7.875 0v2.625h10.5V15.75h-10.5v2.625H21V0zM5.25 5.25L0 9.188l5.25 3.938V10.5h10.5V7.875H5.25z'
      fill='#fff'
    />
  </svg>
);

export default Icon;
