import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={25.092}
    height={14.624}
    viewBox='0 0 25.092 14.624'
    {...props}>
    <defs>
      <style>{'.prefix__a_dumbell{fill:#fff}'}</style>
    </defs>
    <path
      className='prefix__a_dumbell'
      d='M18.78 8.87H6.312a.521.521 0 01-.519-.519V6.273a.521.521 0 01.519-.519H18.78a.521.521 0 01.519.519v2.078a.521.521 0 01-.519.519z'
    />
    <path
      className='prefix__a_dumbell'
      d='M18.468 13.367V1.257A1.355 1.355 0 0119.897 0a1.355 1.355 0 011.429 1.257v12.11a1.355 1.355 0 01-1.429 1.257 1.355 1.355 0 01-1.429-1.257zM23.793 3.156h-.779a.521.521 0 00-.519.519v7.273a.521.521 0 00.519.519h.779a1.3 1.3 0 001.3-1.3V4.456a1.3 1.3 0 00-1.3-1.3zM6.623 13.367V1.257A1.355 1.355 0 005.195 0a1.355 1.355 0 00-1.429 1.257v12.11a1.355 1.355 0 001.429 1.257 1.355 1.355 0 001.428-1.257zM2.078 3.156h-.779a1.3 1.3 0 00-1.3 1.3v5.714a1.3 1.3 0 001.3 1.3h.779a.521.521 0 00.519-.519V3.675a.521.521 0 00-.519-.519z'
    />
  </svg>
);

export default Icon;
