import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { PURPLE } from '@colors';
import EnhancedTableToolbar from 'components/Table/ToolBar';
import EnhancedTableHead from 'components/Table/Head';
import {
  PaperContainer,
  InnerContainer,
  TableC as TableContainer,
  TableB as TableBody,
  HeadingColor,
} from 'css/components/Table';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  title,
  headCells = [],
  rows = [],
  onDelete,
  onEdit,
  sendMail,
  actionKey,
  btn,
  multipleSelect = true,
  showRowData,
  showToolBar = true,
  titleComponent,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rows.length > 10 ? 10 : rows.length
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n, index) => n[actionKey] || index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row, index) => {
    const selectedIndex = selected.indexOf(row[actionKey] || index);

    if (!multipleSelect) {
      setSelected(selected.splice(0, 1));
    }

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row[actionKey] || index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const isSelected = (row, index) => {
    return selected.indexOf(row[actionKey] || index) !== -1;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <PaperContainer className={classes.paper} elevation={0}>
        {showToolBar && (
          <InnerContainer>
            <EnhancedTableToolbar
              numSelected={selected.length}
              title={title}
              titleComponent={titleComponent}
              onDelete={onDelete}
              onEdit={onEdit}
              btn={btn}
              selected={selected}
              sendMail={sendMail}
              showRowData={showRowData}
            />
          </InnerContainer>
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='medium'
            aria-label='enhanced table'>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableCheckbox={rows[0]?.[actionKey] === 0}
              headCells={headCells}
              multipleSelect={multipleSelect}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row, index);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        if (row[actionKey] !== 0) handleClick(row, index);
                      }}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`tableRow${index}`}
                      selected={isItemSelected}>
                      <TableCell padding='checkbox'>
                        {row[actionKey] !== 0 && (
                          <Checkbox
                            style={HeadingColor}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        )}
                      </TableCell>
                      {headCells.map((item, headIndex) => (
                        <TableCell
                          key={`headCells${headIndex}`}
                          style={{ color: PURPLE }}
                          id={labelId}
                          scope='row'
                          align={item.align}>
                          {row[item.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PaperContainer>
    </div>
  );
}
