import styled from 'styled-components';
import { PURPLE, WHITE, DARK_GRAY } from '@colors';

const MeasurementsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  > div {
    margin-right: 25px;
    :last-child {
      margin-right: 0;
    }
  }
`;
const Section = styled.div`
  max-width: 350px;
  flex: 1;
  background-color: ${WHITE};
  padding: 5px 25px;
  border-radius: 5px;
  min-height: 106px;
  display: grid;
  align-items: center;
  p {
    margin: 0;
    color: ${PURPLE};
    font-weight: normal;
    font-size: 18px;

    :last-child {
      border-bottom: 1px solid ${PURPLE};
      padding-bottom: 15px;
    }
  }
`;
const BodyTitle = styled.div`
  padding: 15px;
  font-size: 18px;
  font-weight: normal;
  color: ${PURPLE};
`;
const TableContainer = styled.div`
  background: transparent;
  box-shadow: none;
  width: 100%;
  overflow-x: auto;
  .MuiTableCell-root {
    border-bottom: none;
    font-family: inherit;
  }
  .MuiTableCell-head {
    color: ${PURPLE};
    padding: 12px 15px;
    font-size: 18px;
    font-weight: 400;
  }
  .MuiTableCell-body {
    padding: 10px 15px;
    font-size: 17px;
    color: ${DARK_GRAY};
  }
  .MuiTableRow-head {
    th {
      text-align: center;
      :nth-child(1) {
        text-align: left;
      }
    }
  }
  .MuiTableRow-root {
    td {
      text-align: center;
    }
  }
`;
export { MeasurementsSection, Section, BodyTitle, TableContainer };
