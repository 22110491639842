import * as React from 'react';

const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={23}
    height={27.498}
    viewBox='0 0 23 27.498'
    {...props}>
    <defs>
      <style>{'.prefix__a_body{fill:#fff}'}</style>
    </defs>
    <path
      className='prefix__a_body'
      d='M14.875 3.375A3.375 3.375 0 1111.5 0a3.375 3.375 0 013.375 3.375zM21.563 7.875H1.438a1.4 1.4 0 100 2.8h5.9a1.263 1.263 0 011.03.829 6.763 6.763 0 01-.03 3.02l-.228 1.25a.021.021 0 01-.006.018L6.283 25.85a1.406 1.406 0 001.168 1.624A1.42 1.42 0 009.1 26.337l1.258-7v.013s.371-1.9 1.108-1.9h.066c.749 0 1.108 1.9 1.108 1.9v-.01l1.258 7a1.43 1.43 0 001.659 1.133 1.4 1.4 0 001.166-1.623L14.9 15.795a.021.021 0 00-.006-.018l-.228-1.25a6.763 6.763 0 01-.03-3.02 1.263 1.263 0 011.03-.829h5.894a1.4 1.4 0 100-2.8z'
    />
  </svg>
);

export default Icon;
