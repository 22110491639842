import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/TextField';
import Datepicker from 'components/Datepicker';
import Select from 'components/Select';
import {
  SET_COUPON_TITLE,
  SET_COUPON_DETAIL,
  SET_COUPON_START_DATE,
  SET_COUPON_END_DATE,
  SET_COUPON_REDEMPTION,
  SET_COUPON_URL,
  CREATE_COUPON_INITIATE,
  getCouponsList,
} from '@actions/coupon';
import { FormField } from 'css/components/TextField';
import { getCouponById } from '@selectors/coupon';
import Footer from 'pages/create-coupon/Footer';

const CouponForm = ({ selectedCouponId, coupons = [], spacing = 4, btn }) => {
  const dispatch = useDispatch();

  const { title, detail, startDate, endDate, redemption, url } = useSelector(
    (state) => state?.coupon
  );
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  useEffect(() => {
    if (selectedCouponId) {
      const data = getCouponById(coupons, selectedCouponId);
      dispatch({ type: SET_COUPON_TITLE, value: data.title });
      dispatch({ type: SET_COUPON_DETAIL, value: data.detail });
      dispatch({
        type: SET_COUPON_START_DATE,
        value: moment(data.startdate).toDate(),
      });
      dispatch({
        type: SET_COUPON_END_DATE,
        value: moment(data.enddate).toDate(),
      });
      dispatch({
        type: SET_COUPON_REDEMPTION,
        value: data.coupontype,
      });
      dispatch({ type: SET_COUPON_URL, value: data.url });
    }
  }, [dispatch, coupons, selectedCouponId]);

  useEffect(() => {
    if (!selectedCouponId) {
      dispatch({ type: CREATE_COUPON_INITIATE });
    }
  }, [dispatch, selectedCouponId]);

  useEffect(() => {
    const fetchCouponType = async () => {
      await dispatch(getCouponsList());
    };
    if (!redemption?.options?.length) fetchCouponType();
  }, [dispatch, redemption]);

  return (
    <Grid spacing={spacing} container>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Title'
          theme='transparentBorder'
          style={FormField}
          value={title.value || ''}
          onChange={(value) => dispatch({ type: SET_COUPON_TITLE, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Details'
          theme='transparentBorder'
          style={FormField}
          value={detail.value || ''}
          onChange={(value) => dispatch({ type: SET_COUPON_DETAIL, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Datepicker
          minDate={new Date()}
          theme='purple'
          error={startDate.value < yesterday && startDate.value !== null}
          helperText={
            startDate.value < yesterday && startDate.value !== null
              ? 'Start date must be greater than current date'
              : ''
          }
          value={startDate.value}
          label='Start Date'
          onChange={(value) => dispatch({ type: SET_COUPON_START_DATE, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Datepicker
          minDate={startDate.value || new Date()}
          theme='purple'
          label='End Date'
          value={endDate.value}
          error={
            (endDate.value < yesterday || endDate.value < startDate.value) &&
            endDate.value !== null
          }
          helperText={
            (endDate.value < yesterday || endDate.value < startDate.value) &&
            endDate.value !== null
              ? 'End date must be greater than current & start date'
              : ''
          }
          onChange={(value) => dispatch({ type: SET_COUPON_END_DATE, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          options={redemption.options.map((item) => item.label)}
          label='Redemption Criteria'
          value={redemption?.value}
          style={FormField}
          theme='transparentBorder'
          onChange={(value) => dispatch({ type: SET_COUPON_REDEMPTION, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Coupon Link'
          theme='transparentBorder'
          style={FormField}
          value={url.value || ''}
          onChange={(value) => dispatch({ type: SET_COUPON_URL, value })}
        />
      </Grid>
      {btn && (
        <Grid item xs={12} sm={6}>
          <Footer />
        </Grid>
      )}
    </Grid>
  );
};

CouponForm.propTypes = {
  spacing: PropTypes.number,
  selectedCouponId: PropTypes.number,
  coupons: PropTypes.array,
};

export default CouponForm;
