import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';

const TableH = styled(TableHead)`
  background-color: #fff;
`;

const PaperContainer = styled(Paper)`
  background: none !important;
  box-shadow: none;
`;

const InnerContainer = styled.div`
  color: #5b3268;
  background-color: rgb(234, 231, 237);
  height: 99px;
  margin: 2px 0 4px 0;
  border-radius: 6px;
`;

const TableB = styled(TableBody)`
  background-color: #eae7ed;
  color: #96498e;
`;

const TableC = styled(TableContainer)`
  margin-top: 18px;
  border-radius: 6px;
`;

const HeadTextContainer = styled.div`
  width: 100%;
`;

const HeadingColor = {
  color: '#96498e',
};

const TableSortingLabel = styled.div`
  color: #96498e;
  font-family: inherit;
}`;

export {
  TableH,
  PaperContainer,
  InnerContainer,
  TableC,
  HeadingColor,
  TableB,
  HeadTextContainer,
  TableSortingLabel,
};
