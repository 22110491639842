import { combineReducers } from 'redux';
import user from '@reducers/user';
import auth from '@reducers/auth';
import activityTrackers from '@reducers/activityTracker';
import challenge from '@reducers/challenge';
import loading from '@reducers/loading';
import inbox from '@reducers/inbox';
import coupon from '@reducers/coupon';
import fitnessMatrix from '@reducers/fitnessMatrix';
import challengesArea from '@reducers/challengesArea';
import challengesType from '@reducers/challengesType';
import dashboard from '@reducers/dashboard';
import mail from '@reducers/mail';
import allChallenges from '@reducers/allChallenges';
import avtar from '@reducers/avtar';
import notificationInfo from '@reducers/notifications';
import badgesList from '@reducers/badgesList';
import fitnessGraph from '@reducers/fitnessGraph';
import stepBank from '@reducers/stepBank';
import deviceList from '@reducers/deviceList';
import roles from '@reducers/roles';
import registerUser from '@reducers/registerUser';
import dialog from '@reducers/dialog';
import fitnessAnalytics from '@reducers/fitnessAnalytics';
import bodyAnalytics from '@reducers/bodyAnalytics';
import groupId from '@reducers/groupId';

const appReducer = combineReducers({
  user,
  auth,
  activityTrackers,
  challenge,
  stepBank,
  inbox,
  loading,
  dashboard,
  coupon,
  fitnessMatrix,
  challengesArea,
  challengesType,
  allChallenges,
  mail,
  avtar,
  notificationInfo,
  badgesList,
  fitnessGraph,
  deviceList,
  roles,
  registerUser,
  dialog,
  fitnessAnalytics,
  bodyAnalytics,
  groupId,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
