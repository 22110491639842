import { get, post } from '@axios';
import moment from 'moment';
import REQUESTS from '@requests';
import {
  FROM_USER_CHALLENGE_ID,
  CREATE_CHALLENGES_KEYS,
} from '@reduxConstants/create-challenge';
import { updateField } from '@actions/common';

const { START_DATE, END_DATE } = CREATE_CHALLENGES_KEYS;

const INITIATE_CHALLENGE = 'INITIATE_CHALLENGE';
const UPDATE_CHALLENGE_DURATION = 'UPDATE_CHALLENGE_DURATION';
const UPDATE_CHALLENGE_FIELD = 'UPDATE_CHALLENGE_FIELD';
const SET_CHALLENGE_START_DATE = 'SET_CHALLENGE_START_DATE';
const SET_CHALLENGE_END_DATE = 'SET_CHALLENGE_END_DATE';
const SET_CHALLENGES_DATA = 'SET_CHALLENGES_DATA';
const SET_USER_ACCEPTED_CHALLENGES_DATA = 'SET_USER_ACCEPTED_CHALLENGES_DATA';

const getChallenges = (userId, challenge_id, only = false) => {
  return async (dispatch) => {
    try {
      let allChallenges = await get({
        url: userId
          ? only
            ? `${REQUESTS.FROM_USER_CHALLENGE}?userid=${userId}&pastchallenges=1`
            : `${
                REQUESTS.FROM_USER_CHALLENGE
              }?userid=${userId}${'&isnonacceptedchallenge=1'}${'&from_user_challenge_id='}${
                challenge_id || 0
              }&pastchallenges=1`
          : `${REQUESTS.FROM_USER_CHALLENGE}?pastchallenges=1`,
      });

      allChallenges = allChallenges?.message || [];

      dispatch(
        updateField(SET_CHALLENGES_DATA, {
          allChallenges,
        })
      );
      return allChallenges;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};
const getChallengesById = (userId, challenge_id) => {
  return async () => {
    try {
      let allChallenges = await get({
        url: `${
          REQUESTS.FROM_USER_CHALLENGE
        }?user_id=${userId}${'&isnonacceptedchallenge=0'}${'&from_user_challenge_id='}${
          challenge_id || null
        }`,
      });

      allChallenges = allChallenges?.message || [];
      return allChallenges;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};
const getUserAcceptedChallenge = (userId) => {
  return async (dispatch) => {
    try {
      let userAcceptedChallenges = await get({
        url: `${REQUESTS.GET_USER_ACCEPTED_CHALLENGES}${userId}`,
      });
      userAcceptedChallenges = userAcceptedChallenges?.message || [];
      dispatch(
        updateField(SET_USER_ACCEPTED_CHALLENGES_DATA, {
          userAcceptedChallenges,
        })
      );
      return userAcceptedChallenges;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};
const joinChallenge = (data) => {
  return async () => {
    try {
      const payload = {
        ...data,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };
      const request = await post({
        url: REQUESTS.JOIN_CHALLENGE,
        payload,
      });
      return request;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};
const redeemChallenge = (data) => {
  return async () => {
    try {
      const payload = {
        ...data,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };
      const request = await post({
        url: REQUESTS.REDEEM_CHALLENGE,
        payload,
      });
      return request;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const deleteFromChallenge = (data) => {
  return async () => {
    try {
      const payload = {
        [FROM_USER_CHALLENGE_ID]: data?.[FROM_USER_CHALLENGE_ID].toString(),
        last_updated_by: data.lastUpdatedBy,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        role_id: data.role_id,
      };

      const request = await post({
        url: REQUESTS.DELETE_FROM_USER_CHALLENGE,
        payload,
      });
      return request;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const createAndUpdateChallenge = (data) => {
  return async (dispatch) => {
    try {
      const payload = {
        ...data,
        [START_DATE]: moment.utc(moment(data?.startdate)).format(),
        [END_DATE]: moment.utc(moment(data?.enddate)).format(),
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      const request = await post({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: REQUESTS.FROM_CHALLENGE,
        payload: formData,
      });
      dispatch({ type: INITIATE_CHALLENGE });
      return request?.message;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

/*
  @description : To get the winner list of the challenge
*/

const getChallengeWinnerList = (from_user_challenge_id) => {
  return async (dispatch) => {
    try {
      let winnerList = await get({
        url: `${REQUESTS.CHALLENGE_WINNER_LIST}${from_user_challenge_id}`,
      });

      return winnerList?.message;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

export {
  UPDATE_CHALLENGE_DURATION,
  UPDATE_CHALLENGE_FIELD,
  INITIATE_CHALLENGE,
  SET_CHALLENGE_START_DATE,
  SET_CHALLENGE_END_DATE,
  SET_CHALLENGES_DATA,
  SET_USER_ACCEPTED_CHALLENGES_DATA,
  getChallengesById,
  getChallenges,
  getUserAcceptedChallenge,
  redeemChallenge,
  joinChallenge,
  deleteFromChallenge,
  createAndUpdateChallenge,
  getChallengeWinnerList,
};
