import styled from 'styled-components';
import ContainerBg from 'assets/images/login-bg.png';

const Container = styled.div`
  /* background-image: url(${ContainerBg}); */
  background-repeat: no-repeat;
  min-height: calc(100vh);
  padding: 50px 40px;
  background-size: cover;
  background-position: 50%;
  position: relative;
`;
const Wrapper = styled.div`
  position: relative;
  @media (max-width: 1250px) {
    flex-direction: column;
    display: flex;
    grid-gap: 15px;
  }
`;
const Bg = styled.div`
  background-image: url(${ContainerBg});
  position: fixed;
  // width: 100%;
  // height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background-position: 50%;
  background-size: cover;
`;
export { Container, Wrapper, Bg };
