import styled from 'styled-components';
import { WHITE } from '@colors';
import LoginBg from 'assets/images/login-bg.png';

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${WHITE};
  padding: 0 20px;
  background-image: url('${LoginBg}');
  background-position: center;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 459px;
  padding: 25px;
  background-color: #8c7d9bb5;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.14),
    0 2px 1px -1px rgba(255, 255, 255, 0.12),
    0 1px 3px 0 rgba(255, 255, 255, 0.2);

  @media (max-width: 589px) {
    max-width: 100%;
  }
`;
const Section = styled.div`
  width: 100%;
`;

const StyledLogo = styled.img`
  width: 100%;
  max-width: 263px;
`;

const InputWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const LoginLogo = styled.div`
  width: 100%;
  height: 161px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffcf;
  border-radius: 7px;
`;

const FieldWrapper = styled.div`
  background-color: ${WHITE};
  padding: 0 28px;
  margin-top: 32px;
  border-radius: 7px;
  padding-bottom: 90px;
`;

export {
  Container,
  Wrapper,
  StyledLogo,
  InputWrapper,
  LoginLogo,
  FieldWrapper,
  Section,
};
