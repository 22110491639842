import React from 'react';
import Table from 'pages/coupons/Table';
import { PageContainer } from 'css/components/Body';

const Coupons = () => (
  <PageContainer>
    <Table />
  </PageContainer>
);

export default Coupons;
