import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';
import { INBOX_KEYS } from '@reduxConstants/inbox';

const TOGGLE_MSGS = 'TOGGLE_MSGS';
const SET_INBOX_MSGS = 'SET_INBOX_MSGS';
const INBOX_ERROR = 'INBOX_ERROR';

const getInbox = (userId) => {
  return async (dispatch) => {
    try {
      let inbox = await get({ url: `${REQUESTS.GET_INBOX}${userId}` });
      inbox = inbox?.message || [];
      let messages = [];
      for (let i = 0; i < inbox?.length; i++) {
        messages.push({
          id: i,
          title: inbox[i].subject,
          open: false,
          body: inbox[i].message,
        });
      }
      dispatch(
        updateField(SET_INBOX_MSGS, {
          messages,
        })
      );
      return messages;
    } catch (error) {
      dispatch(updateField(INBOX_ERROR, { [INBOX_KEYS.ERROR]: error.error }));
      return { error: true, message: error.error };
    }
  };
};

export { TOGGLE_MSGS, INBOX_ERROR, SET_INBOX_MSGS, getInbox };
