import {
  INITIAL_FIELD_STATE,
  GENDER_INITIAL_FIELD_STATE,
  ETHNICITY_FIELD_STATE,
} from '@reducers/common';
import { USER_KEYS } from '@reduxConstants/user';

const initial = {
  [USER_KEYS.FIRST_NAME]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.LAST_NAME]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.EMAIL]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.PASSWORD]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.NAME]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.GENDER]: {
    ...GENDER_INITIAL_FIELD_STATE,
  },
  [USER_KEYS.ZIP_CODE]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.ETHNICITY]: {
    ...ETHNICITY_FIELD_STATE,
  },
  [USER_KEYS.GROUP_ID]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.HEIGHT.HEIGHT]: {
    [USER_KEYS.HEIGHT.FEET]: { ...INITIAL_FIELD_STATE },
    [USER_KEYS.HEIGHT.INCH]: { ...INITIAL_FIELD_STATE },
  },
  [USER_KEYS.WEIGHT]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.TERMS_AND_CONDITIONS]: {
    ...INITIAL_FIELD_STATE,
  },
  [USER_KEYS.DOB]: {
    ...INITIAL_FIELD_STATE,
    [USER_KEYS.DOB_VISIBILITY]: false,
  },
};

export { initial };
