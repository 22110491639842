import React from 'react';
import {
  PageContainer,
  HeadingContainer,
  HeadingTitle,
} from 'css/components/Body';
import StripeLogo from 'assets/images/stripe-logo.png';
import PaymentForm from 'components/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PageForm } from 'css/pages/challenges';

const stripePromise = loadStripe(process.env.REACT_APP_REACT_KEY);

const PaymentPage = () => {
  return (
    <PageContainer>
      <HeadingContainer>
        <HeadingTitle>
          <div>Payment</div>
          <span>
            Powered By:
            <img
              src={StripeLogo}
              style={{ verticalAlign: 'middle' }}
              height='46'
              alt='card_logo'
              width='100'></img>
          </span>
        </HeadingTitle>
      </HeadingContainer>
      <PageForm style={{ marginTop: '10px' }}>
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      </PageForm>
    </PageContainer>
  );
};

export default PaymentPage;
