import { INITIAL_FIELD_STATE } from '@reducers/common';
import { CREATE_CHALLENGES_KEYS } from '@reduxConstants/create-challenge';

const {
  INCENTIVE_MESSAGE,
  FITNESS_METRIC_ID,
  FITNESS_METRIC_BENCHMARK,
  START_DATE,
  END_DATE,
  COUPON_ID,
  CHALLENGE_AREA_ID,
  RADIUS,
  COMPLETION_MESSAGE,
  BUSINESS_NAME,
  BUSINESS_LOGO,
  BUSINESS_ADDRESS,
  TITLE,
  CHALLENGE_WIN_LIMIT,
} = CREATE_CHALLENGES_KEYS;

const initial = {
  [INCENTIVE_MESSAGE]: {
    ...INITIAL_FIELD_STATE,
  },
  [FITNESS_METRIC_ID]: {
    ...INITIAL_FIELD_STATE,
  },
  [FITNESS_METRIC_BENCHMARK]: {
    ...INITIAL_FIELD_STATE,
  },
  [START_DATE]: {
    ...INITIAL_FIELD_STATE,
  },
  [END_DATE]: {
    ...INITIAL_FIELD_STATE,
  },
  [COUPON_ID]: {
    ...INITIAL_FIELD_STATE,
  },
  [CHALLENGE_AREA_ID]: {
    ...INITIAL_FIELD_STATE,
  },
  [TITLE]: {
    ...INITIAL_FIELD_STATE,
  },
  [CHALLENGE_WIN_LIMIT]: {
    ...INITIAL_FIELD_STATE,
  },
  [RADIUS]: {
    ...INITIAL_FIELD_STATE,
    options: [5, 15, 25, 50],
  },
  [COMPLETION_MESSAGE]: {
    ...INITIAL_FIELD_STATE,
  },
  [BUSINESS_NAME]: {
    ...INITIAL_FIELD_STATE,
  },
  [BUSINESS_LOGO]: {
    ...INITIAL_FIELD_STATE,
  },
  [BUSINESS_ADDRESS]: {
    ...INITIAL_FIELD_STATE,
  },
};

export { initial };
