import React from 'react';
import CouponForm from 'components/CouponForm';

const Form = () => (
  <React.Fragment>
    <CouponForm btn={true} />
  </React.Fragment>
);

export default Form;
