import {
  APPLE_HEALTH,
  FITBIT,
  GOOGLE_FIT,
  ACTIVITY_TRACKER_KEYS,
} from '@reduxConstants/activityTracker';

const initial = [
  {
    [ACTIVITY_TRACKER_KEYS.DEVICE]: APPLE_HEALTH,
    [ACTIVITY_TRACKER_KEYS.CONNECTED]: false,
    platform: 'ios',
    device_id: 1,
  },
  {
    [ACTIVITY_TRACKER_KEYS.DEVICE]: FITBIT,
    [ACTIVITY_TRACKER_KEYS.CONNECTED]: false,
    platform: 'android',
    device_id: 2,
  },
  {
    [ACTIVITY_TRACKER_KEYS.DEVICE]: GOOGLE_FIT,
    [ACTIVITY_TRACKER_KEYS.CONNECTED]: false,
    platform: 'android',
    device_id: 3,
  },
];

export { initial };
