import { updateField } from '@actions/common';
import { TOGGLE_LOADING, HIDE_LOADING } from '@actions/loading';
import REQUESTS from '@requests';
import { get, post } from '@axios';

import moment from 'moment';
const UPDATE_AVATAR_RESPONSE_DATA = 'UPDATE_AVATAR_RESPONSE_DATA';
const UPDATE_AVATAR_STATUS = 'UPDATE_AVATAR_STATUS';
const DEV = 'https://dev-api.knosishealth.com:6100';
const PROD = 'https://prod-api.knosishealth.com:6103';


export const setAvtarResponse = async (responseData) => {
  return updateField(UPDATE_AVATAR_RESPONSE_DATA, {
    data: responseData,
  });
};
export const updateAvatarDownloadComplete = (isComplete) => {
  return updateField(UPDATE_AVATAR_STATUS, {
    avatarDownloadComplete: isComplete,
  });
};
//gender, height, frontImage, sideImage
export const requestThreeDModel = (
  profile,
  userId,
  gender,
  height,
  weight,
  frontImageBase64,
  sideImageBase64
) => {
  return async (dispatch) => {
    // alert(frontImageBase64);
    // return;
    try {
      // dispatch(TOGGLE_LOADING);
      fetch('https://saia.3dlook.me/api/v2/persons/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'APIKey be4137588377e1f2955d9b4e9a874f3fc484e3e3',
        },
        body: JSON.stringify({
          gender: gender,
          height: height,
          weight: weight,
          front_image: frontImageBase64,
          side_image: sideImageBase64,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          dispatch(fetchThreeDModel(profile,responseJson?.task_set_url,userId));
        })
        .catch((error) => {
          //display error message
          console.warn(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};
let timeout;
const fetchThreeDModel = (profile,taskURL,userId) => {
  return async (dispatch) => {
    // dispatch(TOGGLE_LOADING);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      model_url: '' + taskURL,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    fetch(`${PROD}${'/avatar/getModelDetails'}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.is_ready === false) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            dispatch(fetchThreeDModel(profile,taskURL, userId));
          }, 5000);
        } else {
          clearTimeout(timeout);
          const res = result?.sub_tasks;

          if(res){
            alert("The side/full body is not full: Try uploading the images again.")
          }
          
          else{
            dispatch(postAvatar(profile,userId,result));
          }
          dispatch({ type: HIDE_LOADING });
        }
      })
      .catch((error) => {
        clearTimeout(timeout);
        // dispatch(HIDE_LOADING);
        console.log('error', error);
      });
  };
};
const getAvatar = (userId) => {
  return async (dispatch) => {
    try {
      let avatarData = await get({
        url: `${REQUESTS.AVATAR_DATA}${userId}`,
      });

      if (avatarData?.message.length > 0) {
        dispatch(
          updateField(UPDATE_AVATAR_RESPONSE_DATA, {
            data: avatarData?.message[0]?.json,
          })
        );
        dispatch({ type: HIDE_LOADING });
      }
      return avatarData;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};
const postAvatar = (profile,userId, responseData) => {
  return async (dispatch) => {
    try {
      const payload = {
        user_id: userId,
        chest_top: 0,
        crotch_length: 0,
        frontal_collar_crotch_length: 0,
        high_hips: 0,
        inseam: 0,
        jacket_length: 0,
        neck: 0,
        outseam: 0,
        scye_depth: 0,
        shoulder_length: 0,
        shoulders: 0,
        sleeve_length: 0,
        gender_id: profile?.gender_id,
        gender: profile?.gender,
        height: 0,
        id: '1',
        url: 'url',
        body_model: '0.0',
        chest_model: 0,
        high_hips_model: 0,
        low_hips_model: 0,
        waist_model: 0,
        last_updated_by: 5,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        json: responseData,
        textures_json: { textures: ['SQL', 'C#', 'MVC'] },
        transactiontype: 'I',
        body_height: 0,
      };
      let avatarData = await post({
        url: `${REQUESTS.AVATAR_DATA}${userId}`,
        payload,
      });
      if (avatarData) {
        const postAvatarData = await dispatch(
          updateField(UPDATE_AVATAR_RESPONSE_DATA, {
            data: responseData,
          })
        );

        dispatch(getAvatar(userId));
        dispatch(updateAvatarDownloadComplete(true));

        return postAvatarData;
      }
      return avatarData;
    } catch (error) {
      return { error: true, message: error?.error };
    }
  };
};

export {
  UPDATE_AVATAR_RESPONSE_DATA,
  UPDATE_AVATAR_STATUS,
  getAvatar,
  postAvatar,
};
