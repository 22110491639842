const REQUESTS = {
  REGISTER: '/users/register',
  LOGIN: '/users/login',
  LOGOUT: '/users/logout',
  SEND_EMAIL: '/users/sendbulkemail',
  CHANGE_PASSWORD: '/users/password/update',
  FORGOT_PASSWORD: '/users/password/forgot',
  GET_GENDER_LIST: '/users/genderlist',
  GET_ETHNICITY_LIST: '/users/ethnicitylist',
  UPDATE_PROFILE: '/users/updateuser',
  UPDATE_PROFILE_IMAGE: '/users/updateimage',
  GET_INBOX: '/notifications/?userid=',
  GET_ALL_USERS: '/users/getallusers',
  DELETE_USERS: '/users/deleteuser',
  GET_USERS_CSV: 'users/downloadCsvFile',
  COUPON: '/coupon',
  COUPON_LIST: '/coupon/list',
  COUPON_TYPE_LIST: '/coupon/typelist',
  DELETE_COUPON: '/coupon/delete',
  FROM_USER_CHALLENGE: '/challenges/fromuser',
  FROM_CHALLENGE: '/challenges/from',
  DELETE_FROM_USER_CHALLENGE: '/challenges/deletefromchallenge',
  FITNESS_MATRIX: '/challenges/fitnessmatrix',
  CHALLENGES_AREA: '/challenges/area',
  CHALLENGES_TYPE: '/challenges/type',
  GET_HEALTH_DATA: '/health/getDashboardData?userid=',
  POST_HEALTH_DATA: '/health/bulkhealth',
  JOIN_CHALLENGE: '/challenges/join',
  REDEEM_CHALLENGE: '/challenges/redeem',
  GET_STEP_BANK_DATA: '/challenges/stepBankList?userid=',
  GET_USER_ACCEPTED_CHALLENGES:
    '/challenges/getUserAcceptedChallengeList?userid=',
  GET_DEVICE_LIST: '/health/devicelist',
  UPDATE_NOTIFICATION: '/notifications/updatereadnotification',
  GET_GRAPH_DATA: '/users/getgraphsdata?userid=',
  GET_BADGES: '/badges/getUserBadgeHistory?userid=',
  PAYMENT: '/payment',
  GET_ALL_ROLES: '/users/rolelist',
  GET_GROUP_IDS: '/adminportal/getAllGroups',
  REGISTER_ADMIN: '/adminportal/registerUserByAdmin',
  FITNESS_ANALYTICS: '/adminportal/getFitnessAnalyticsData',
  GET_FITNESS_CSV: 'adminportal/downloadFitnessCsvFile',
  GET_USER_BY_GROUP: '/adminportal/getUserByGroup',
  BODY_ANALYTICS: '/adminportal/getBodyAnalyticsData',
  AVATAR_DATA: '/avatar?user_id=',
  CHALLENGE_WINNER_LIST: '/adminportal/getwinnerlist?from_user_challenge_id=',
  COUPON_TOOL_LINK: '/adminportal/coupontool'
};

export default REQUESTS;
