import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { createAndUpdateCoupon } from '@actions/coupon';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import { SET_DIALOG } from '@actions/dialog';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const Footer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const buttonStyles = useButtonStyles();

  const { title, detail, startDate, endDate, redemption, url } = useSelector(
    (state) => state?.coupon
  );
  const { last_updated_by, role_id, user_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  const disabled = !(
    title.valid &&
    detail.valid &&
    startDate.valid &&
    endDate.valid &&
    url.valid
  );

  return (
    <Grid item xs={12} sm={12} className={classes.flex}>
      <Button
        label='Create'
        fullWidth={true}
        styles={buttonStyles}
        disabled={disabled}
        onClick={async () => {
          const payload = {
            lastUpdatedBy: last_updated_by,
            title: title?.value,
            detail: detail?.value,
            startDate: startDate?.value,
            endDate: endDate?.value,
            role_id: role_id,
            user_id: user_id,
            coupontype_id: redemption.options.find(
              (item) => item.label === redemption.value
            ).value,
            url: url?.value,
          };
          const response = await dispatch(createAndUpdateCoupon(payload));
          if (response.error) {
            alert(response.message);
            return;
          }
          dispatch({
            type: SET_DIALOG,
            payload: {
              show: true,
              message: 'Coupon Created Successfully!',
              error: false,
              redirect: '/coupons',
            },
          });
        }}
      />
    </Grid>
  );
};

export default Footer;
