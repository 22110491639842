import * as a from '@actions/avtar';

const initialState = {
  data: '',
  avatarDownloadComplete: false,
};

const avtar = (state = initialState, action) => {
  switch (action.type) {
    case a.UPDATE_AVATAR_RESPONSE_DATA:
      return {
        ...state,
        data:
          typeof action['data'] === 'string'
            ? JSON.parse(action['data'])
            : action['data'],
      };
    case a.UPDATE_AVATAR_STATUS:
      return {
        ...state,
        avatarDownloadComplete: action['avatarDownloadComplete'],
      };

    default: {
      return state;
    }
  }
};
export default avtar;
