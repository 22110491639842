const INITIAL_FIELD_STATE = {
  valid: false,
  value: null,
};

const GENDER_INITIAL_FIELD_STATE = {
  ...INITIAL_FIELD_STATE,
  options: [],
  // options: [
  //   { text: 'Male', value: '1' },
  //   { text: 'Female', value: '2' },
  //   { text: 'Other', value: '3' },
  // ],
};

const ETHNICITY_FIELD_STATE = {
  ...INITIAL_FIELD_STATE,
  options: [],
  // options: [
  //   { text: 'African American', value: 'African American' },
  //   { text: 'Asian', value: 'Asian' },
  //   { text: 'Hispanic', value: 'Hispanic' },
  //   { text: 'Native American', value: 'Native American' },
  //   { text: 'White', value: 'White' },
  //   { text: 'Other', value: 'Other' },
  // ],
};

export {
  INITIAL_FIELD_STATE,
  GENDER_INITIAL_FIELD_STATE,
  ETHNICITY_FIELD_STATE,
};
