import axios from 'axios';
import { updateField } from '@actions/common';
import { TOGGLE_LOADING, HIDE_LOADING } from '@actions/loading';
import { AUTH_LOGOUT } from '@actions/auth';

const loaderBlacklistApiUrl = [
  '/health/bulkhealth',
  '/health/getDashboardData?userid=',
  '/challenges/stepBankList?userid=',
  '/challenges/getUserAcceptedChallengeList?userid=',
  '/challenges/fromuser',
  '/badges/getUserBadgeHistory',
];
const DEV = 'https://dev-api.knosishealth.com:6100';
const PROD = 'https://prod-api.knosishealth.com:6103';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || PROD,
});

const interceptor = (store) => {
  instance.interceptors.request.use(
    (config) => {
      const token = store?.getState().auth?.profile?.token;
      if (token) {
        config.headers['Authorization'] = token;
      }

      const isUrlBlacklisted = !!loaderBlacklistApiUrl.find((url) =>
        config?.url.includes(url)
      );
      if (!isUrlBlacklisted) {
        store.dispatch(updateField(TOGGLE_LOADING));
      }

      return config;
    },
    (error) => {
      store.dispatch(updateField(HIDE_LOADING));
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      const isUrlBlacklisted = !!loaderBlacklistApiUrl.find((url) =>
        response?.config?.url.includes(url)
      );

      if (!isUrlBlacklisted) {
        store.dispatch(updateField(HIDE_LOADING));
      }

      if (response?.data?.status === 401) {
        store.dispatch(updateField(AUTH_LOGOUT));
      }

      return Promise.resolve(response);
    },
    (error) => {
      store.dispatch(updateField(HIDE_LOADING));
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );
};

const get = async ({ url }) => {
  try {
    const request = await instance.get(url);
    return request?.data;
  } catch (error) {
    throw error;
  }
};

const post = async ({ url, payload, headers }) => {
  try {
    const request = await instance.post(url, payload, headers);
    return request?.data;
  } catch (error) {
    throw error;
  }
};

export { get, post, interceptor };
export default instance;
