import { get } from '@axios';
import REQUESTS from '@requests';

const SET_GROUP_ID = 'SET_GROUP_ID';

const getGroupIds = () => {
  return async (dispatch) => {
    let response = await get({
      url: REQUESTS.GET_GROUP_IDS,
    });

    const groupIds = response?.message.filter(
      (r) => r?.group_id && r?.group_id != 'undefined'
    );

    dispatch({ type: SET_GROUP_ID, payload: groupIds || [] });
    if (response?.error) return { error: true, message: response?.message };
    else return { error: false, message: groupIds };
  };
};

export { getGroupIds, SET_GROUP_ID };
