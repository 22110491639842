import * as a from '@actions/dialog';

const initial = {
  show: false,
  error: false,
  message: undefined,
};

const dialog = (state = initial, action = null) => {
  switch (action.type) {
    case a.INITIALIZE_DIALOG: {
      return { ...initial, error: state?.error };
    }
    case a.SET_DIALOG: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default dialog;
