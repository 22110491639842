import { get, post } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';
import { HEALTH_KEYS } from '@reduxConstants/dashboard';
import moment from 'moment';

const SET_HEALTH_DATA = 'SET_HEALTH_DATA';
const HEALTH_ERROR = 'HEALTH_ERROR';

const getHealthData = (userId, device_id) => {
  return async (dispatch) => {
    try {
      let healthData = await get({
        url: `${REQUESTS.GET_HEALTH_DATA}${userId}${'&device_id='}${device_id}`,
      });
      console.log(`${REQUESTS.GET_HEALTH_DATA}${userId}${'&device_id='}${device_id}`)

      healthData = healthData?.message || [];
      dispatch(
        updateField(SET_HEALTH_DATA, {
          healthData,
        })
      );
      return healthData;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const postHealthData = (
  userId,
  healthData,
  from_user_challenge_id,
  device_id
) => {
  const healthRecord = healthData?.healthData;
  const healthdatarecords = [];
  var date = new Date();
  var offsetInHours = date.getTimezoneOffset() / 60;
  for (let i = 0; i < healthRecord?.length; i++) {
    healthdatarecords.push({
      user_id: userId,
      device_id: device_id,
      steps: healthRecord[i]?.steps || 0,
      date: 
      healthRecord[i]?.startDate+moment.utc().local().format('[T]HH:mm:ss[Z]')||
      moment.utc().local().format('YYYY-MM-DD[T]HH:mm:SS[Z]'),
      activetime: healthRecord[i]?.activeTime || 0,
      calories: parseInt(healthRecord[i]?.calories) || 0,
      distance: (healthRecord[i]?.distance).toFixed(2)  || 0.0,
      weight: 0,
      heightinfeet: 0,
      heightininches: 0,
      BMI:  0,
      floors: healthRecord[i]?.floors || 0,
      sleep: healthRecord[i]?.sleep || 0,
      standingtime: 0,
      heartrateaverage: (healthRecord[i]?.heartrateaverage).toFixed(1) || 0.0,
      chest: 0,
      waist: 0,
      shoulder: 0,
      lowhips: 0,
      neck: 0,
      highhips: 0,
      from_user_challenge_id: from_user_challenge_id || 0,
      transactiontype: 'I',
      last_updated_by: userId,
      last_updated_ts: moment.utc().local().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    });
  
}



  const payloadData = {
    healthdatarecords: healthdatarecords,
  };

  return async () => {
    try {
      const payload = {
        ...payloadData,
      };

      const request = await post({ url: REQUESTS.POST_HEALTH_DATA, payload });

      return { error: false, data: request?.data };
    } catch (error) {
      return { error: true, message: error?.error };
    }
  };
};

export { HEALTH_ERROR, SET_HEALTH_DATA, getHealthData, postHealthData };
