import { post, get } from '@axios';
import moment from 'moment';
import { updateField } from '@actions/common';
import { AUTH_KEYS, DISABLE_NOTIFICAION } from '@reduxConstants/auth';
import { USER_KEYS } from '@reduxConstants/user';
import REQUESTS from '@requests';
import { USER_INITIATE } from '@actions/user';

const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_ERROR = 'AUTH_ERROR';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const AUTH_INITIATE = 'AUTH_INITIATE';
const SET_LOGOUT_FALSE = 'SET_LOGOUT_FALSE';
const SET_LOCATION = 'SET_LOCATION';
const DISABLE_NOTIFICAION_CONST = 'DISABLE_NOTIFICAION_CONST';

const signUp = (data) => {
  return async (dispatch) => {
    try {
      const payload = {
        ...data,
        last_updated_ts: moment.utc().local().format('YYYY-MM-DD'),
      };
      const request = await post({ url: REQUESTS.REGISTER, payload });
      dispatch(
        updateField(AUTH_SUCCESS, {
          [AUTH_KEYS.PROFILE]: {
            ...request?.message,
            token: request?.token,
            refreshToken: request?.refreshToken,
          },
        })
      );
      dispatch(updateField(USER_INITIATE));
      return { error: false, data: request?.data };
    } catch (error) {
      dispatch(updateField(AUTH_ERROR, { [AUTH_KEYS.ERROR]: error.error }));
      return { error: true, message: error?.error };
    }
  };
};

const updateUser = (data) => {
  return async (dispatch) => {
    try {
      const payload = {
        ...data,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        last_updated_by: data?.user_id,
      };

      const request = await post({ url: REQUESTS.UPDATE_PROFILE, payload });

      dispatch(updateField(USER_INITIATE));
      dispatch(
        updateField(AUTH_SUCCESS, {
          [AUTH_KEYS.PROFILE]: payload,
        })
      );

      return { error: false, data: request?.data };
    } catch (error) {
      return { error: true, message: error?.error };
    }
  };
};

const updateProfileImage = (data) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('user_id', data?.user_id);
      formData.append('image', data?.image);
      formData.append(
        'last_updated_ts',
        moment.utc().local().format('YYYY-MM-DD')
      );
      formData.append('last_updated_by', data?.user_id);
      //convert back to string from obj
      const oldImage = data?.image.uri;
      data['image'] = oldImage;

      const request = await post({
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: data?.token,
        },
        url: REQUESTS.UPDATE_PROFILE_IMAGE,
        payload: formData,
      });
      dispatch(updateField(USER_INITIATE));
      dispatch(
        updateField(AUTH_SUCCESS, {
          [AUTH_KEYS.PROFILE]: data,
        })
      );
      return { error: false, data: request?.data };
    } catch (error) {
      console.log('error', error);
      //return { error: true, message: error?.error };
      return { error: true, message: error };
    }
  };
};

const login = (data) => {
  return async (dispatch) => {
    try {
      const payload = {};
      payload.emailid = data?.[USER_KEYS.EMAIL]?.value;
      payload.password = data?.[USER_KEYS.PASSWORD]?.value;
      const request = await post({ url: REQUESTS.LOGIN, payload });
      dispatch(
        updateField(AUTH_SUCCESS, {
          [AUTH_KEYS.PROFILE]: {
            ...request?.message,
            token: request?.token,
            refreshToken: request?.refreshToken,
          },
        })
      );
      dispatch(updateField(USER_INITIATE));
      return { error: false, data: request?.data };
    } catch (error) {
      console.log("ERROR: ",error)
      dispatch(updateField(AUTH_ERROR, { [AUTH_KEYS.ERROR]: error.error }));
      return { error: true, message: error.error };
    }
  };
};
const loginOnSignUp = (data) => {
  return async (dispatch) => {
    try {
      const payload = {};
      payload.emailid = data?.emailid;
      payload.password = data?.password;
      const request = await post({ url: REQUESTS.LOGIN, payload });

      dispatch(
        updateField(AUTH_SUCCESS, {
          [AUTH_KEYS.PROFILE]: {
            ...request?.message,
            token: request?.token,
            refreshToken: request?.refreshToken,
          },
        })
      );
      dispatch(updateField(USER_INITIATE));
      return { error: false, data: request?.data };
    } catch (error) {
      dispatch(updateField(AUTH_ERROR, { [AUTH_KEYS.ERROR]: error.error }));
      return { error: true, message: error.error };
    }
  };
};

const logout = (user_id) => {
  return async (dispatch) => {
    try {
      const request = await get({ url: `${REQUESTS.LOGOUT}/${user_id}` });
      dispatch({ type: AUTH_LOGOUT });

      return { error: false, data: request?.data };
    } catch (error) {
      dispatch(updateField(AUTH_ERROR, { [AUTH_KEYS.ERROR]: error.error }));
      return { error: true, message: error.error };
    }
  };
};

const changePassword = (data) => {
  return async (dispatch) => {
    try {
      const payload = {
        emailid: data?.emailid,
        oldPassword: data?.password,
        newPassword: data?.newPassword,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };
      const request = await post({ url: REQUESTS.CHANGE_PASSWORD, payload });
      dispatch(updateField(USER_INITIATE));
      console.log("ERROR: ", request);

      return { error: false, data: request?.data };
    } catch (error) {

      return { error: true, message: error?.error };
    }
  };
};
const forgotPassword = (data) => {
  return async (dispatch) => {
    try {
      const payload = {
        emailid: data?.value,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };

      const request = await post({ url: REQUESTS.FORGOT_PASSWORD, payload });
      dispatch(updateField(USER_INITIATE));
      return { error: false, data: request };
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const disableNotification = (data) => {
  return async (dispatch) => {
    dispatch(
      updateField(DISABLE_NOTIFICAION_CONST, {
        [DISABLE_NOTIFICAION]: data,
      })
    );
  };
};

export {
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_INITIATE,
  DISABLE_NOTIFICAION_CONST,
  SET_LOGOUT_FALSE,
  SET_LOCATION,
  signUp,
  logout,
  login,
  loginOnSignUp,
  updateUser,
  updateProfileImage,
  changePassword,
  forgotPassword,
  disableNotification,
};
