import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Iframe from "react-iframe";

import { getCoupons } from "@actions/coupon";
import InfoHeader from "pages/home/InfoHeader";
import {
  Error,
  CouponTableWrapper,
  ActiveCouponWrapper,
  NonActiveCouponWrapper,
  CouponsWrapper,
  Section,
  TableWrapper,
  TableHead,
  TableBody,
  CouponText,
  CouponBody,
  CouponOverlay,
} from "css/pages/home";

const Coupons = () => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState(undefined);
  const [totalCoupons, setTotalCoupons] = useState(undefined);
  const [searchVal, setSearchVal] = useState(undefined);
  const { role_id, user_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  const onTapCoupon = (coupon) => {
    if (!coupon?.coupon_url) return;
    window.open(
      coupon?.coupon_url,
      "_blank",
      "noreferrer"
    );
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      if (!coupons) {
        let data = undefined;
        if (role_id !== 1) data = await dispatch(getCoupons(user_id));
        else data = await dispatch(getCoupons());
        if (data.error) {
          alert(data.message);
          return;
        }
        // console.log("coupons data", data);

        setCoupons(data);
        setTotalCoupons(data);
      }
    };
    fetchCoupons();
  }, [dispatch, role_id, user_id]); //do not add coupons 

  const searchCoupon = (val) => {
    setSearchVal(val);
    let match = totalCoupons.filter((item) => {
      return item?.title?.toLowerCase().includes(val.toLowerCase());
    });
    setCoupons(match);
  };

  const activeCoupons = () => {
    const data = _.filter(coupons, (c) => {
      const endDate = moment(c.enddate).format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      return moment(endDate).isSameOrAfter(today);
    });

    return data?.length ? (
      <ActiveCouponWrapper>
        {data?.map((coupon, index) => {
          return (
            <CouponBody key={index} onClick={() => onTapCoupon(coupon)}>
              {coupon?.coupon_url !== "undefined" && coupon?.coupon_url && (
                <Iframe //does not work as styled component
                  url={coupon.coupon_url}
                  width="98%"
                  height="98%"
                />
              )}
              <CouponOverlay>{""}</CouponOverlay>
              <CouponText>{coupon?.title}</CouponText>
            </CouponBody>
          );
        })}
      </ActiveCouponWrapper>
    ) : (
      <ActiveCouponWrapper>
        <div>
          <CouponText>No Active Coupon</CouponText>
        </div>
      </ActiveCouponWrapper>
    );
  };

  const inActiveCoupons = () => {
    const data = _.filter(coupons, (c) => {
      const endDate = moment(c.enddate).format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      return !moment(endDate).isSameOrAfter(today);
    });

    return data?.length ? (
      <NonActiveCouponWrapper>
        {data?.map((coupon, index) => (
          <CouponBody key={index} onClick={() => onTapCoupon(coupon)}>
              {coupon?.coupon_url !== "undefined" && coupon?.coupon_url && (
              <Iframe //does not work as styled component
                url={coupon.coupon_url}
                width="98%"
                height="98%"
              />
            )}
            <CouponOverlay>{""}</CouponOverlay>
            <CouponText>{coupon?.title}</CouponText>
          </CouponBody>
        ))}
      </NonActiveCouponWrapper>
    ) : (
      <NonActiveCouponWrapper>
        <div>
          <CouponText>No Active Coupon</CouponText>
        </div>
      </NonActiveCouponWrapper>
    );
  };

  return (
    <CouponsWrapper>
      <Section>
        <InfoHeader
          title="Coupons"
          label="Search coupon"
          value={searchVal}
          onChange={(v) => searchCoupon(v)}
        />
        {coupons?.length ? (
          <>
            <TableWrapper>
              <TableHead>
                <div>Active Coupons</div>
                <div>Non-Active Coupons</div>
              </TableHead>
              <TableBody>
                <CouponTableWrapper>
                  {activeCoupons()}
                  {inActiveCoupons()}
                </CouponTableWrapper>
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <Error>No Data Found</Error>
        )}
      </Section>
    </CouponsWrapper>
  );
};

export default Coupons;
