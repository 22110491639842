import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_CHALLENGES_TYPE = 'SET_CHALLENGES_TYPE';
const SET_CHALLENGES_TYPE_ERROR = 'SET_CHALLENGES_TYPE_ERROR';

const getChallengesType = () => {
  return async (dispatch) => {
    try {
      const data = await get({ url: REQUESTS.CHALLENGES_TYPE });

      dispatch(
        updateField(SET_CHALLENGES_TYPE, {
          data: data?.message,
        })
      );

      return data?.message;
    } catch (error) {
      dispatch(
        updateField(SET_CHALLENGES_TYPE_ERROR, { error: error.message })
      );
      return { error: true, message: error.message };
    }
  };
};

export { SET_CHALLENGES_TYPE, SET_CHALLENGES_TYPE_ERROR, getChallengesType };
