import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: (props) => ({
    borderRadius: '4px',
    fontFamily: 'inherit',
    '& label': {
      fontFamily: `'Poppins', sans-serif`,
      color: props.labelColor,
      fontWeight: props.fontWeight || 600,
    },
    '& label.Mui-focused': {
      color: props.labelFocusedColor,
    },
    '& label.Mui-error': {
      color: 'red',
    },
    '& .MuiInput-underline': {
      fontFamily: 'inherit',
      '& input': {
        color: props.color,
        paddingBottom: 15,
      },
      '&::before': {
        borderColor: props.borderColor,
        borderWidth: props.borderWidth,
        boxShadow: props.boxShadow,
      },
      '&:hover': {
        '&:not(.Mui-disabled)::before': {
          borderColor: props.borderColor,
        },
      },
      '&:not(.Mui-disabled)::after': {
        borderColor: props.borderColor,
      },
    },
    '& .MuiInputLabel-outlined': {
      transform: props.transform,
    },
    '& .MuiInputLabel-shrink': {
      transform: props.transformShrink,
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'inherit',
      '& input': {
        color: props.color,
        padding: props.padding,
      },
      // '& input::placeholder': {
      //   color: props.placeholderColor,
      //   opacity: 1,
      //   fontWeight: 600,
      // },
      '& fieldset': {
        borderColor: props.borderColor,
      },
      '&:hover fieldset': {
        borderColor: props.borderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: props.borderColor,
      },
    },
  }),
});

export default useStyles;
