import styled from 'styled-components';
import { PURPLE, WHITE } from '@colors';

const Button = styled.button`
  padding: 10px 20px;
  color: ${(props) => (props.active ? WHITE : PURPLE)};
  border: 0px;
  height: 45px;
  width: 217px;
  font-size: 0.9vw;
  font-family: inherit;
  cursor: pointer;
  background-color: ${(props) => (props.active ? PURPLE : WHITE)};
  box-shadow: 0px 5px 9px -6px #000;
  border-radius: 3px;

  :click {
    color: red;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 1024px) {
    font-size: 1.5vw;
  }
`;

const btnGroup = {
  alignItems: 'center',
  width: '55%',
};

export { Button, btnGroup };
