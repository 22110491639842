import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_CHALLENGES_AREA = 'SET_CHALLENGES_AREA';
const SET_CHALLENGES_AREA_ERROR = 'SET_CHALLENGES_AREA_ERROR';

const getChallengesArea = () => {
  return async (dispatch) => {
    try {
      const data = await get({ url: REQUESTS.CHALLENGES_AREA });

      dispatch(
        updateField(SET_CHALLENGES_AREA, {
          data: data?.message,
        })
      );

      return data?.message;
    } catch (error) {
      dispatch(
        updateField(SET_CHALLENGES_AREA_ERROR, { error: error.message })
      );
      return { error: true, message: error.message };
    }
  };
};

export { SET_CHALLENGES_AREA, SET_CHALLENGES_AREA_ERROR, getChallengesArea };
