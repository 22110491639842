import Form from 'components/ChallengeForm';
import Header from 'pages/create-challenge/Header';
import { PageContainer } from 'css/components/Body';
import { PageForm } from 'css/pages/challenges';

const CreateChallenge = () => {
  return (
    <PageContainer fullHeight>
      <Header />
      <PageForm>
        <div>
          <Form btn={true} />
        </div>
      </PageForm>
    </PageContainer>
  );
};

export default CreateChallenge;
