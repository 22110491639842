import { WHITE, PURPLE } from '@colors';

const defaultTheme = {
  labelColor: 'rgba(255,255,255,0.5)',
  color: WHITE,
  borderColor: WHITE,
  labelFocusedColor: WHITE,
};

const getTheme = (theme) => {
  switch (theme) {
    case 'white':
      return defaultTheme;
    case 'purple':
      return {
        labelColor: 'PURPLE',
        color: PURPLE,
        borderColor: PURPLE,
        labelFocusedColor: PURPLE,
        IconColor: PURPLE,
      };
    case 'form':
      return {
        labelColor: 'rgb(131 0 114 / 0.5)',
        color: PURPLE,
        borderColor: PURPLE,
        backgroundColor: 'black !important',
        labelFocusedColor: PURPLE,
      };
    case 'grey':
      return {
        labelColor: '#9a9a9a85',
        color: '#000',
        borderColor: '#630557',
        labelFocusedColor: '#630557',
      };
    case 'transparentBorder':
      return {
        labelColor: PURPLE,
        color: PURPLE,
        borderColor: 'transparent',
        labelFocusedColor: PURPLE,
        placeholderColor: PURPLE,
        padding: '21.5px 14px',
        borderFocusColor: 'transparent !important',
        // transform: 'translate(3px, -16px) scale(0.75)',
        transformShrink: 'translate(3px, -16px) scale(0.75)',
      };
    case 'underLine':
      return {
        labelColor: PURPLE,
        borderColor: 'PURPLE',
        borderFocusColor: 'purple',
        borderFocusWidth: '3px',
        labelFocusedColor: PURPLE,
        placeholderColor: PURPLE,
        padding: '21.5px 14px',
        transform: 'translate(3px, -16px) scale(0.75)',
        border: 'none',
        borderBottomStyle: 'solid ',
        color: PURPLE,
        borderRadius: '0',
      };
    default:
      return defaultTheme;
  }
};

export { getTheme };
