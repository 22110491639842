const BLACK = '#000000';
const PURPLE = '#830072';
const WHITE = '#ffffff';
const FOREST_GREEN = '#228B22';
const RED = '#FF0000';
const GREY = '#808080';
const LIGHT_GREY = '#E8E8E8';
const ORANGE = '#be5504';
const PINK = '#C65DA0';
const DARK_GRAY = '#626262';

export {
  BLACK,
  PURPLE,
  WHITE,
  FOREST_GREEN,
  RED,
  GREY,
  LIGHT_GREY,
  ORANGE,
  PINK,
  DARK_GRAY,
};
