import * as a from '@actions/groupId';

const initial = [];

const groupId = (state = initial, action = null) => {
  switch (action.type) {
    case a.SET_GROUP_ID: {
      let payload = [];
      action.payload.forEach((item) => {
        if (item?.group_id) payload.push(item?.group_id);
      });
      return payload;
    }
    default:
      return state;
  }
};

export default groupId;
