import styled from 'styled-components';

const BusinessLogoWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  > img {
    width: 100px;
    height: auto;
    object-fit: contain;
  }
`;

export { BusinessLogoWrapper };
