import * as a from '@actions/auth';
import { initial } from '@reducers/auth/initialState';
import { AUTH_KEYS, DISABLE_NOTIFICAION } from '@reduxConstants/auth';

const auth = (state = initial, action = null) => {
  switch (action.type) {
    case a.AUTH_INITIATE:
      return initial;

    case a.AUTH_SUCCESS:
      return {
        ...state,
        [AUTH_KEYS.AUTHENTICATED]: true,
        [AUTH_KEYS.ERROR]: null,
        [AUTH_KEYS.PROFILE]: action[AUTH_KEYS.PROFILE],
      };
    case a.AUTH_ERROR:
      return {
        ...state,
        [AUTH_KEYS.AUTHENTICATED]: false,
        [AUTH_KEYS.ERROR]: action[AUTH_KEYS.ERROR],
        [AUTH_KEYS.PROFILE]: null,
      };
    case a.DISABLE_NOTIFICAION_CONST:
      return {
        ...state,
        disableNotification: action.disableNotification,
      };
    case a.SET_LOGOUT_FALSE:
      return {
        ...state,
        [AUTH_KEYS.LOGOUT]: false,
      };

      case a.SET_LOCATION:
        return {
          ...state,
          [AUTH_KEYS.LATITUDE]:  action.latitude,
          [AUTH_KEYS.LONGITUDE]: action.longitude,
        };
    default:
      return state;
  }
};

export default auth;
