import { WHITE, PURPLE } from '@colors';

const defaultTheme = {
  labelColor: 'rgba(255,255,255,0.5)',
  color: WHITE,
  borderColor: WHITE,
  labelFocusedColor: WHITE,
};

const getTheme = (theme) => {
  switch (theme) {
    case 'white':
      return defaultTheme;
    case 'purple':
      return {
        labelColor: '#7E7E7E',
        color: PURPLE,
        borderColor: PURPLE,
        labelFocusedColor: PURPLE,
        boxShadow: '0px 5px 3px #eee',
        fontWeight: 400,
      };
    case 'form':
      return {
        labelColor: 'rgb(131 0 114 / 0.5)',
        color: PURPLE,
        borderColor: PURPLE,
        backgroundColor: 'black !important',
        labelFocusedColor: PURPLE,
      };
    case 'grey':
      return {
        labelColor: '#9a9a9a85',
        color: '#000',
        borderColor: '#630557',
        labelFocusedColor: '#630557',
      };
    case 'transparentBorder':
      return {
        labelColor: PURPLE,
        color: PURPLE,
        borderColor: 'transparent',
        backgroundColor: '#fff !important',
        labelFocusedColor: PURPLE,
        placeholderColor: PURPLE,
        padding: '21.5px 14px',
        transform: 'translate(14px, 23px) scale(1)',
        transformShrink: 'translate(1px, -16px) scale(0.75) !important',
      };
    default:
      return defaultTheme;
  }
};

export { getTheme };
