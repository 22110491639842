import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_DEVICE_LIST = 'SET_DEVICE_LIST';
const SET_CONNECTED_DEVICE = 'SET_CONNECTED_DEVICE';
const getDeviceList = () => {
  return async (dispatch) => {
    let deviceList = await get({
      url: REQUESTS.GET_DEVICE_LIST,
    });
    deviceList = deviceList?.message || [];
    dispatch(
      updateField(SET_DEVICE_LIST, {
        deviceList,
      })
    );
    return deviceList;
  };
};

const setConnectedDevice = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONNECTED_DEVICE,
      connectedDevice: data,
    });

    return data;
  };
};
export {
  getDeviceList,
  setConnectedDevice,
  SET_DEVICE_LIST,
  SET_CONNECTED_DEVICE,
};
