import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CouponsTable } from 'css/pages/coupons';
import { getCoupons, deleteCoupon, getCouponToolLink } from '@actions/coupon';
import { getCouponById } from '@selectors/coupon';
import Table from 'components/Table';
import EditCouponDialog from 'pages/coupons/EditCouponDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import { headCells } from 'pages/coupons/data';
import { ROUTES } from 'constants/routes';
import { SET_DIALOG } from '@actions/dialog';

const CouponsTableBody = () => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState(undefined);
  const [selectedCouponIds, setSelectedCouponIds] = useState(undefined);
  const [editDialogVisibility, setEditDialogVisibility] = useState(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const { last_updated_by, role_id, user_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  const isActiveCoupon = (data, key) => {
    let endDate = moment(data?.[key]).format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    return moment(endDate).isSameOrAfter(today);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      if (!coupons) {
        let data = undefined;
        if (role_id !== 1) data = await dispatch(getCoupons(user_id));
        else data = await dispatch(getCoupons());
        if (data.error) {
          alert(data.message);
          return;
        }
        setCoupons(data);
      }
    };
    fetchCoupons();
  }, [dispatch, role_id, user_id]); //do not add coupons 

  if (coupons) {
    let rows = [];

    if (coupons?.length < 1 || coupons?.[0].coupon_id === 0)
      rows.push({
        coupon_id: 0,
        title: '-',
        detail: '-',
        startdate: '-',
        enddate: '-',
      });
    else {
      for (let i = 0; i < coupons?.length; i++) {
        rows.push({
          coupon_id: coupons[i].coupon_id,
          title: coupons[i].title,
          detail: coupons[i].detail,
          startdate: moment(coupons[i].startdate).format('MM/DD/YYYY'),
          enddate: moment(coupons[i].enddate).format('MM/DD/YYYY'),
          status: isActiveCoupon(coupons[i], 'enddate') ? 'Active' : 'Inactive',
        });
      }
      rows.sort((item, itemB) => (item?.status > itemB?.status ? 1 : -1));
    }

    return (
      <CouponsTable>
        <Table
          title='Coupons'
          headCells={headCells}
          actionKey='coupon_id'
          rows={rows}
          btn={{ label: 'Create Coupon', route: ROUTES.CREATE_COUPON }} //remove route and pass onClick to make it work with click handler.
          onDelete={(ids) => {
            setSelectedCouponIds(ids);
            setDeleteDialogVisibility(true);
          }}
          onEdit={(ids) => {
            if (!isActiveCoupon(getCouponById(coupons, ids[0]), 'enddate'))
              dispatch({
                type: SET_DIALOG,
                payload: {
                  show: true,
                  message: 'Cannot edit an expired coupon',
                  error: true,
                },
              });
            else {
              setSelectedCouponIds(ids);
              setEditDialogVisibility(!editDialogVisibility);
            }
          }}
        />
        {editDialogVisibility && (
          <EditCouponDialog
            open={editDialogVisibility}
            coupons={coupons}
            selectedCouponId={selectedCouponIds[0]}
            onClose={(reload) => {
              setEditDialogVisibility(false);
              if (reload) {
                setCoupons(undefined);
              }
            }}
          />
        )}

        {deleteDialogVisibility && (
          <ConfirmDialog
            title='Are you sure?'
            message='Do you really want to delete these records? This process cannot be undone.'
            open={deleteDialogVisibility}
            hide={async (remove) => {
              if (remove) {
                const coupon = await dispatch(
                  deleteCoupon({
                    couponId: selectedCouponIds,
                    lastUpdatedBy: last_updated_by,
                    role_id: role_id,
                  })
                );
                if (coupon.error) {
                  alert(coupon.message);
                  return;
                }
                setCoupons(undefined);
              }
              setSelectedCouponIds(undefined);
              setDeleteDialogVisibility(false);
            }}
          />
        )}
      </CouponsTable>
    );
  }

  return null;
};

export default CouponsTableBody;
