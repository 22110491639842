import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { purpleButton } from 'components/Button/types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/TextField';
import Select from 'components/Select';
import Button from 'components/Button';
import {
  SET_REGUSER_ACCESS,
  SET_REGUSER_GROUPID,
  SET_REGUSER_PASSWORD,
  SET_REGUSER_EMAIL,
  createNewAdmin,
} from '@actions/registerUser';
import { MAXIMUM_PASSWORD_LENGTH } from '@reduxConstants/user';
import { FormField } from 'css/components/TextField';
import { SET_DIALOG } from '@actions/dialog';
import { getAllRoles } from '@actions/roles';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

const RegisterUserForm = ({ spacing = 5 }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state?.roles);
  const { email, password, groupid, access } = useSelector(
    (state) => state?.registerUser
  );
  const buttonStyles = useButtonStyles();

  useEffect(() => {
    const fetchRoles = async () => {
      if (roles.length < 1) {
        await dispatch(getAllRoles());
      }
    };
    fetchRoles();
  }, [dispatch, roles.length]);

  const disabled = !(
    email.valid &&
    password.valid &&
    (groupid.valid || access.value === 'Super User') &&
    access.valid
  );

  const handleClick = async () => {
    let roleId = roles.find((item) => item.rolename === access?.value).role_id;
    let payload = {
      emailid: email?.value,
      password: password?.value,
      group_id: roleId !== 1 ? groupid?.value : undefined,
      role_id: roleId,
    };
    const response = await dispatch(createNewAdmin(payload));
    if (response?.error) {
      alert(response.message);
      return;
    }
    dispatch({
      type: SET_DIALOG,
      payload: {
        show: true,
        message: 'User created successfully',
        error: false,
      },
    });
  };

  return (
    <Grid spacing={spacing} container>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Email'
          theme='transparentBorder'
          style={FormField}
          error={!!(email.value && !email.valid)}
          helperText={email.value && !email.valid ? 'Invalid Email' : null}
          value={email?.value || ''}
          onChange={(value) => dispatch({ type: SET_REGUSER_EMAIL, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Password'
          theme='transparentBorder'
          style={FormField}
          error={!!(password.value && !password.valid)}
          helperText={
            password.value && !password.valid
              ? `Password should be less than ${MAXIMUM_PASSWORD_LENGTH} characters and Single quote (‘), Double quote (“) and escape letters are not allowed`
              : null
          }
          secureText={true}
          value={password?.value || ''}
          onChange={(value) => dispatch({ type: SET_REGUSER_PASSWORD, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Group ID'
          theme='transparentBorder'
          disabled={access?.value === 'Super User'}
          style={FormField}
          value={groupid?.value || ''}
          onChange={(value) => dispatch({ type: SET_REGUSER_GROUPID, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          theme='transparentBorder'
          style={FormField}
          options={roles
            ?.filter((item) => item.role_id !== 4)
            .map((item) => item.rolename)}
          label='Access Level'
          value={access?.value}
          onChange={(value) => dispatch({ type: SET_REGUSER_ACCESS, value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          styles={buttonStyles}
          disabled={disabled}
          label='Create Admin'
          onClick={handleClick}
        />
      </Grid>
    </Grid>
  );
};

export default RegisterUserForm;
