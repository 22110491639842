import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={21}
      height={18.375}
      viewBox='0 0 15.911 21.215'
      {...props}>
      <path
        d='M7.138 20.787C1.118 12.059 0 11.163 0 7.956a7.956 7.956 0 1115.911 0c0 3.208-1.118 4.1-7.138 12.831a1 1 0 01-1.635 0zm.818-9.516a3.315 3.315 0 10-3.315-3.315 3.315 3.315 0 003.315 3.314z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgComponent;
