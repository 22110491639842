import { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/Button';
import ChallengeForm from 'components/ChallengeForm';
import { CREATE_COUPON_INITIATE } from '@actions/coupon';
import {
  CREATE_CHALLENGES_KEYS,
  FROM_USER_CHALLENGE_ID,
} from '@reduxConstants/create-challenge';
import { disabled, makePayload } from 'components/ChallengeForm/data';
import Loader from 'components/Loader';
import { getLocationInfo } from 'utils/GeoCoderManager';
import { getChallengeById } from '@selectors/challenge';
import { createAndUpdateChallenge } from '@actions/challenge';
import { SET_DIALOG } from '@actions/dialog';
import { makeStyles } from '@material-ui/core/styles';
import { purpleButton } from 'components/Button/types';
import CloseIcon from 'assets/svg/CloseIcon';
import styled from 'styled-components';
const { BUSINESS_ADDRESS } = CREATE_CHALLENGES_KEYS;

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
  },
}));

const EditChallengeDialog = ({
  open,
  onClose,
  selectedChallengeId,
  challenges,
}) => {
  const dispatch = useDispatch();
  const buttonStyles = useButtonStyles();
  const [loader, setLoader] = useState(false);
  const challenge = useSelector((state) => state?.challenge);
  const { user_id, role_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={() => _.isFunction(onClose) && onClose()}
      aria-labelledby='form-dialog-title'>
      <Container>
        <IconWrapper
          onClick={() => {
            dispatch({ type: CREATE_COUPON_INITIATE });
            _.isFunction(onClose) && onClose();
          }}>
          <CloseIcon />
        </IconWrapper>

        <Title>Edit Challenge</Title>
        <DialogContent>
          <ChallengeForm
            challenges={challenges}
            selectedChallengeId={selectedChallengeId}
            spacing={2}
          />
        </DialogContent>

        <DialogActions>
          <Button
            label='Cancel'
            fullWidth={false}
            onClick={() => {
              dispatch({ type: CREATE_COUPON_INITIATE });
              _.isFunction(onClose) && onClose();
            }}
            styles={buttonStyles}
          />
          <Button
            label='Update'
            disabled={disabled(challenge)}
            fullWidth={false}
            onClick={async () => {
              setLoader(true);
              const { results } = await getLocationInfo(
                challenge?.[BUSINESS_ADDRESS]?.value
              );
              if (!results?.length) {
                alert('Please enter valid business address');
                return;
              }
              setLoader(false);

              const data = getChallengeById(challenges, selectedChallengeId);
              const payload = makePayload({
                ...challenge,
                lat: results[0]?.geometry?.lat,
                lng: results[0]?.geometry?.lng,
                from_user_id: user_id,
                role_id: role_id,
                [FROM_USER_CHALLENGE_ID]: data[FROM_USER_CHALLENGE_ID],
              });

              const response = await dispatch(
                createAndUpdateChallenge(payload)
              );
              if (response?.error) {
                alert(response?.message);
                return;
              }
              dispatch({
                type: SET_DIALOG,
                payload: {
                  show: true,
                  message: 'Challenge Updated Successfully!',
                  error: false,
                  redirect: '/challenges',
                },
              });
              _.isFunction(onClose) && onClose(true);
            }}
            styles={buttonStyles}
          />
        </DialogActions>
        <Loader open={loader} />
      </Container>
    </Dialog>
  );
};

EditChallengeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditChallengeDialog;

const Container = styled.div`
  background: #dbdbdb;
`;

const IconWrapper = styled.div`
  margin: 23px 20px;
  > svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 21px;
  color: PURPLE;
  font-weight: 400;
  padding: 0 22px;
`;
