import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableWrapper } from 'css/components/SimpleTable';
import { TableContainer as TableC } from 'css/pages/body-analytics';

const SimpleTable = (props) => {
  const { head, rowData, secondary } = props;
  const Wrapper = props.secondary ? TableC : TableWrapper;

  return (
    <Wrapper>
      <TableContainer style={!secondary ? { maxHeight: '268px' } : {}}>
        {head.length && rowData.length && (
          <Table
            stickyHeader={!secondary ? true : false}
            aria-label='simple table'>
            <TableHead>
              <TableRow>
                {head.map((item, index) => (
                  <TableCell
                    align={
                      index === 0
                        ? 'left'
                        : index === item?.length - 1 && secondary
                        ? 'right'
                        : 'center'
                    }>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((item, index) => (
                <TableRow key={index}>
                  {item.map((i, ind) => {
                    return (
                      <TableCell
                        key={index + ind}
                        align={
                          ind === 0
                            ? 'left'
                            : ind === item.length - 1 && secondary
                            ? 'right'
                            : 'center'
                        }
                        component={ind === 0 ? 'th' : 'td'}>
                        {i}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Wrapper>
  );
};

export default SimpleTable;
