import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Success from 'assets/images/success-img.png';
import ErrorImg from 'assets/images/error-img.png';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import { useSelector, useDispatch } from 'react-redux';
import { INITIALIZE_DIALOG } from '@actions/dialog';
import { useHistory } from 'react-router-dom';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    marginTop: '16px',
    marginBottom: '8px',
    width: '298px',
    height: '49px',
  },
}));

const CustomDialog = () => {
  const buttonStyles = useButtonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { show, message, error, redirect } = useSelector(
    (state) => state.dialog
  );

  const closeDialog = () => {
    dispatch({ type: INITIALIZE_DIALOG });
    if (redirect) history.push(redirect);
  };

  const Icon = error ? ErrorImg : Success;

  return (
    <>
      <Dialog
        open={show}
        onClose={closeDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'>
        <Wrapper>
          <div onClick={closeDialog} style={{ cursor: 'pointer' }}>
            <DialogText textAlign='right'>Close</DialogText>
          </div>
          <DialogBody>
            <img src={Icon} height='217' width='218' alt='dialog_icon'></img>
            <DialogText>{message}</DialogText>
            <Button
              fullWidth={false}
              onClick={closeDialog}
              label='OK'
              styles={buttonStyles}
            />
          </DialogBody>
        </Wrapper>
      </Dialog>
    </>
  );
};

export default CustomDialog;

const Wrapper = styled.div`
  padding: 4%;
`;

const DialogText = styled.p`
  margin-top: 0;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  font-weight: 600 !important;
  color: #000 !important;
  font-family: inherit;
`;

const DialogBody = styled(DialogContent)`
  height: 80%;
  text-align: center;
`;
