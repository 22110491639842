import styled from 'styled-components';
import { PURPLE } from '@colors';

const TableWrapper = styled.div`
  .MuiTable-root {
    width: calc(100% - 1px);
  }
  button {
    height: 42px;
    font-size: 14px;
    min-width: 200px;
    float: right;
    margin-top: 50px;
  }
  .MuiTableContainer-root {
    background: transparent;
    box-shadow: none;
  }
  .MuiTableCell-head {
    color: ${PURPLE};
    background-color: #ece9ef;
  }
  .MuiTableCell-root {
    border: none;
    font-family: inherit;
    font-weight: normal;
  }
  .MuiTableHead-root {
    > tr {
      > th {
        :first-child {
          padding: 0;
        }
      }
    }
  }
  .MuiTableBody-root {
    tr {
      :last-child {
        td {
          border-bottom: 1px solid PURPLE;
        }
      }
    }
    > .MuiTableRow-root {
      > th {
        padding: 0;
      }
      > td {
        background: white;
        border: 1px solid ${PURPLE};
        padding: 7.5px 12px;
        border-right: none;
        border-bottom: none;
        padding: 7px;

        :last-child {
          border-right: 1px solid ${PURPLE};
        }
      }
    }
  }
  .MuiTableBody-root {
    tr {
      :last-child {
        td {
          border-bottom: 1px solid ${PURPLE};
        }
      }
    }
  }
`;

export { TableWrapper };
