const CREATE_CHALLENGES_KEYS = {
  INCENTIVE_MESSAGE: 'incentivemsg',
  FITNESS_METRIC_ID: 'fitness_metric_id',
  FITNESS_METRIC_BENCHMARK: 'fitnessmetricbenchmark',
  START_DATE: 'startdate',
  END_DATE: 'enddate',
  COUPON_ID: 'coupon_id',
  CHALLENGE_AREA_ID: 'challenge_area_id',
  RADIUS: 'radius',
  COMPLETION_MESSAGE: 'completionmsg',
  BUSINESS_NAME: 'bussinessname',
  BUSINESS_LOGO: 'bussinesslogo',
  BUSINESS_ADDRESS: 'bussinessaddress',
  ALL_CHALLENGES: 'allchallenges',
  TITLE: 'title',
  CHALLENGE_WIN_LIMIT: 'challengewinlimit',
  CHALLENGE_TYPE_ID: 'challenge_type_id',
  CHALLENGE_XML: 'challengexml',
  CHALLENGE_BUNDLE: 'challengebundle',
  CHALLENGE_BUNDLE_IOS: 'challengebundleios',
};

const FROM_USER_CHALLENGE_ID = 'from_user_challenge_id';
const USER_ACCEPTED_CHALLENGE_COUNT = 'noofusersacceptedchallenge';
const LATITUDE = 'latitude';
const LONGITUDE = 'longitude';

export {
  CREATE_CHALLENGES_KEYS,
  FROM_USER_CHALLENGE_ID,
  LATITUDE,
  LONGITUDE,
  USER_ACCEPTED_CHALLENGE_COUNT,
};
