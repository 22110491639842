import { get } from '@axios';
import REQUESTS from '@requests';

const GET_ALL_ROLES = 'GET_ALL_ROLES';

const getAllRoles = () => {
  return async (dispatch) => {
    try {
      let roles = await get({ url: REQUESTS.GET_ALL_ROLES });
      dispatch({ type: GET_ALL_ROLES, payload: roles?.message });
      return roles?.message;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

export { GET_ALL_ROLES, getAllRoles };
