import * as a from '@actions/mail';
import { MAIL_KEYS } from '@reduxConstants/mail';

const initialState = {
  emailList: [],
  sent: false,
};

const mail = (state = initialState, action = null) => {
  switch (action.type) {
    case a.SEND_EMAIL:
      return {
        ...state,
        [MAIL_KEYS.EMAIL]: action.healthData,
        [MAIL_KEYS.EMAIL_BODY]: action.healthData,
        [MAIL_KEYS.ERROR]: null,
      };
    default:
      return state;
  }
};

export default mail;
