import { WHITE, PURPLE } from '@colors';

const styledButton = ({
  background = WHITE,
  color = PURPLE,
  height = 52,

  disabledBackground = background,
  opacity = 0.5,
  fontSize = 16,
  textTransform = 'capitalize',
} = {}) => ({
  background,
  color,
  height,
  fontSize,
  textTransform,
  fontFamily: `'Poppins', sans-serif`,
  '&.Mui-disabled': {
    background: disabledBackground,
    opacity,
    color: color,
  },
});

const purpleButton = ({
  background = PURPLE,
  color = WHITE,
  height = 52,
  opacity = 0.8,
  fontSize = 18,
} = {}) => ({
  ...styledButton({
    height,
    fontSize,
    background,
    color,
  }),
  fontWeight: '600',
  '&.MuiButton-contained:hover': {
    background: PURPLE,
    opacity,
  },
});

const purpleGradientButton = ({
  background = 'linear-gradient(to right, #96498E, #793A72)',
  color = WHITE,
  height = 52,
  opacity = 0.9,
  fontSize = 18,
} = {}) => ({
  ...styledButton({
    height,
    fontSize,
    background,
    color,
  }),
  fontWeight: '600',
  '&.MuiButton-contained:hover': {
    background: 'linear-gradient(to right, #96498E, #793A72)',
    opacity,
  },
});

export { styledButton, purpleButton, purpleGradientButton };
