import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getAllUsers } from '@actions/user';
import InfoHeader from 'pages/home/InfoHeader';
import {
  Error,
  UserWrapper,
  Section,
  TableWrapper,
  TableHead,
  TableBody,
} from 'css/pages/home';
import { userByGroup } from '@actions/bodyAnalytics';

const Users = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState({});
  const { user_id, role_id, group_id, zipcode } = useSelector(
    (state) => state?.auth?.profile
  );
  const [totalUsers, setTotalUsers] = useState({});
  const [searchValue, setSearchVal] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      if (_.keys(totalUsers).length < 1) {
        let tempData = undefined;
        let data = undefined;
        if (role_id === 1) {
          data = await dispatch(getAllUsers());
          tempData = data?.users;
        } else {
          data = await dispatch(userByGroup(group_id));
          tempData = data?.message;
        }
        if (data?.error) {
          alert(data?.message);
          return;
        }
        let newData = _.countBy(tempData, 'zipcode');
        let newDataFilter = _.countBy(
          tempData.filter((item) => item.user_id !== user_id),
          'zipcode'
        );
        setUsers(newDataFilter);
        setTotalUsers(newData);
      }
    };
    fetchUsers();
  }, [dispatch, users, totalUsers, group_id, role_id, user_id]);

  const searchUser = (val) => {
    setSearchVal(val);
    let match = _.keys(totalUsers).filter((u) => u.toString().includes(val));
    let matchedData = {};
    _.keys(totalUsers).forEach((el) => {
      if (match.includes(el)) {
        matchedData[el] = totalUsers[el];
      }
    });
    if (_.keys(matchedData).includes(zipcode)) {
      matchedData[zipcode] -= matchedData[zipcode];
    }
    if (matchedData[zipcode] <= 1 || isNaN(matchedData[zipcode])) {
      delete matchedData[zipcode];
    }
    setUsers(matchedData);
  };

  return (
    <UserWrapper>
      <Section>
        <InfoHeader
          title='Active Users'
          label='Search by zipcode'
          value={searchValue}
          onChange={(v) => searchUser(v)}
        />
        <TableWrapper>
          {_.keys(users)?.length ? (
            <>
              <TableHead>
                <div>Zipcode</div>
                <div>User Count</div>
              </TableHead>
              <TableBody>
                {_.keys(users).map((key, index) => (
                  <div key={`usersTable${index}`}>
                    <p>{key}</p>

                    <p>{users?.[key]}</p>
                  </div>
                ))}
              </TableBody>
            </>
          ) : (
            <Error>No Data Found</Error>
          )}
        </TableWrapper>
      </Section>
    </UserWrapper>
  );
};

export default Users;
