import React from 'react';
import Users from 'pages/home/Users';
import Challenges from 'pages/home/Challenges';
import Coupons from 'pages/home/Coupons';
import { Container } from 'css/pages/home';

const Home = () => {
  return (
    <Container>
      <Users />
      <Challenges />
      <Coupons />
    </Container>
  );
};

export default Home;
