import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({
  open,
  hide,
  title,
  message,
  destructiveButtonTitle,
}) => (
  <Dialog
    open={open}
    onClick={() => _.isFunction(hide) && hide()}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => _.isFunction(hide) && hide()} color='primary'>
        Cancel
      </Button>
      <Button onClick={() => _.isFunction(hide) && hide(true)} color='primary'>
        {destructiveButtonTitle}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  hide: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  destructiveButtonTitle: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  open: false,
  title: 'Title',
  message: 'Description',
  destructiveButtonTitle: 'Ok',
};

export default ConfirmDialog;
