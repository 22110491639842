import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges } from '@actions/challenge';
import Table from 'components/Table';
import ConfirmDialog from 'components/ConfirmDialog';
import ChallengeDetailDialog from 'pages/challenges/ChallengeDetailDialog';
import {
  deleteFromChallenge,
  getChallengeWinnerList,
} from '@actions/challenge';
import { FROM_USER_CHALLENGE_ID } from '@reduxConstants/create-challenge';
import EditChallengeDialog from 'pages/challenges/EditChallengeDialog';
import { headCells } from 'pages/challenges/data';
import { PageContainer } from 'css/components/Body';
import { Img, ImageContainer } from 'css/pages/challenges';
import { ROUTES } from 'constants/routes';
import BusinessLogo from 'assets/images/map-marker.png';
import { getChallengeById } from '@selectors/challenge';
import { SET_DIALOG } from '@actions/dialog';

const CouponsTableBody = () => {
  const dispatch = useDispatch();
  const [challenges, setChallenges] = useState(undefined);
  const [editDialogVisibility, setEditDialogVisibility] = useState(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const [selectedChallengeIds, setSelectedChallengeIds] = useState(undefined);
  const [challengeDetail, setChallengeDetail] = useState(undefined);
  const profile = useSelector((state) => state?.auth?.profile || {});
  const { user_id, role_id } = useSelector((state) => state?.auth?.profile);

  const Image = ({ src, onError }) => {
    return (
      <ImageContainer>
        <Img src={src} onError={onError}></Img>
      </ImageContainer>
    );
  };

  const isActiveChallenge = (data, key) => {
    let endDate = moment(data?.[key]).format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    return moment(endDate).isSameOrAfter(today);
  };

  useEffect(() => {
    const fetchChallenges = async () => {
      if (!challenges) {
        let userId = role_id !== 1 ? user_id : undefined;
        const data = await dispatch(getChallenges(userId, null));
        if (data.error) {
          alert(data.message);
          return;
        }
        if (role_id !== 1)
          setChallenges(data.filter((item) => item?.from_user_id === user_id));
        else setChallenges(data);
      }
    };
    fetchChallenges();
  }, [dispatch, challenges, role_id, user_id]);

  if (challenges) {
    let rows = [];

    if (challenges?.length < 1)
      rows.push({
        [FROM_USER_CHALLENGE_ID]: 0,
        challengearea: '-',
        incentivemsg: '-',
        fitnessmetric: '-',
        fitnessmetricbenchmark: '-',
        startdate: '-',
        enddate: '-',
        coupon: '-',
        completionmsg: '-',
        bussinessname: '-',
        bussinessaddress: '-',
        bussinesslogo: '-',
      });

    for (let i = 0; i < challenges?.length; i++) {
      const challenge = challenges[i];
      rows.push({
        [FROM_USER_CHALLENGE_ID]: challenge?.[FROM_USER_CHALLENGE_ID],
        challengearea: challenge?.challengearea,
        incentivemsg: challenge.incentivemsg,
        fitnessmetric: challenge.fitnessmetric,
        fitnessmetricbenchmark: challenge.fitnessmetricbenchmark,
        status: isActiveChallenge(challenge, 'enddate') ? 'Active' : 'Inactive',
        startdate: moment(challenge.startdate).format('MM/DD/YYYY'),
        enddate: moment(challenge.enddate).format('MM/DD/YYYY'),
        coupon: challenge.coupon,
        completionmsg: challenge.completionmsg,
        bussinessname: challenge.bussinessname,
        bussinessaddress: challenge.bussinessaddress,
        bussinesslogo: (
          <Image
            src={challenge.bussinesslogo}
            alt='Business Logo'
            onError={(e) => {
              e.target.error = null;
              e.target.src = BusinessLogo;
              return BusinessLogo;
            }}
          />
        ),
      });
      rows.sort((item, itemB) => (item?.status > itemB?.status ? 1 : -1));
    }

    return (
      <PageContainer>
        <Table
          title='Challenges'
          headCells={headCells}
          actionKey={FROM_USER_CHALLENGE_ID}
          rows={rows}
          btn={{ label: 'Create Challenge', route: ROUTES.CREATE_CHALLENGE }}
          onDelete={(ids) => {
            setSelectedChallengeIds(ids);
            setDeleteDialogVisibility(true);
          }}
          onEdit={(ids) => {
            if (
              !isActiveChallenge(
                getChallengeById(challenges, ids[0]),
                'enddate'
              )
            )
              dispatch({
                type: SET_DIALOG,
                payload: {
                  show: true,
                  message: 'Cannot edit an expired challenge',
                  error: true,
                },
              });
            else {
              setSelectedChallengeIds(ids);
              setEditDialogVisibility(!editDialogVisibility);
            }
          }}
          showRowData={async (ids) => {
            const selectedChallenge = challenges.find(
              (r) => r[FROM_USER_CHALLENGE_ID] === ids[0]
            );

            const winnerList = await dispatch(
              getChallengeWinnerList(selectedChallenge?.from_user_challenge_id)
            );
            if (!winnerList.error) {
              selectedChallenge.winnerList = winnerList;
            }

            setChallengeDetail(selectedChallenge);
          }}
        />

        {deleteDialogVisibility && (
          <ConfirmDialog
            title='Are you sure?'
            message='Do you really want to delete these records? This process cannot be undone.'
            open={deleteDialogVisibility}
            hide={async (remove) => {
              if (remove) {
                const request = await dispatch(
                  deleteFromChallenge({
                    [FROM_USER_CHALLENGE_ID]: selectedChallengeIds,
                    lastUpdatedBy: profile?.last_updated_by,
                    role_id: profile?.role_id,
                  })
                );
                if (request?.error) {
                  alert(request?.message);
                  return;
                }
                setChallenges(undefined);
              }
              setSelectedChallengeIds(undefined);
              setDeleteDialogVisibility(false);
            }}
          />
        )}

        {editDialogVisibility && (
          <EditChallengeDialog
            open={editDialogVisibility}
            challenges={challenges}
            selectedChallengeId={selectedChallengeIds[0]}
            onClose={(reload) => {
              setEditDialogVisibility(false);
              if (reload) {
                setChallenges(undefined);
              }
            }}
          />
        )}

        {!!challengeDetail && (
          <ChallengeDetailDialog
            open={!!challengeDetail}
            setOpen={(v) => setChallengeDetail(v)}
            challenge={challengeDetail}
          />
        )}
      </PageContainer>
    );
  }

  return null;
};

export default CouponsTableBody;
