import moment from "moment";
import * as a from "@actions/coupon";
import { initial } from "@reducers/coupon/initialState";

const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (err) {
    return false;
  }
  return true;
}

const coupon = (state = initial, action = null) => {
  switch (action.type) {
    case a.CREATE_COUPON_INITIATE:
      return initial;

    case a.SET_COUPON_TYPE:
      return {
        ...state,
        redemption: {
          ...state.redemption,
          options: action?.payload?.message.map((item) => {
            return { value: item?.coupontype_id, label: item?.coupontype };
          }),
        },
      };

    case a.SET_COUPON_REDEMPTION:
      return {
        ...state,
        redemption: {
          ...state.redemption,
          value: action.value,
          valid: !!action.value,
        },
      };

    case a.SET_COUPON_TITLE:
      return {
        ...state,
        title: {
          value: action.value,
          valid: !!action.value,
        },
      };

    case a.SET_COUPON_DETAIL:
      return {
        ...state,
        detail: {
          value: action.value,
          valid: !!action.value,
        },
      };

    case a.SET_COUPON_START_DATE:
      return {
        ...state,
        startDate: {
          value: action.value,
          valid: state?.endDate?.value
            ? moment(action.value).isSameOrBefore(moment(state?.endDate?.value))
            : !!action.value,
        },
        endDate: {
          ...state?.endDate,
          valid: state?.endDate?.value
            ? moment(action.value).isSameOrBefore(moment(state?.endDate?.value))
            : !!state?.endDate?.value,
        },
      };

    case a.SET_COUPON_END_DATE:
      return {
        ...state,
        endDate: {
          value: action.value,
          valid: state?.startDate?.value
            ? moment(action.value).isSameOrAfter(
                moment(state?.startDate?.value)
              )
            : !!action.value,
        },
        startDate: {
          ...state?.startDate,
          valid: state?.startDate?.value
            ? moment(action.value).isSameOrAfter(
                moment(state?.startDate?.value)
              )
            : !!state?.startDate?.value,
        },
      };

    case a.SET_COUPON_URL:
      return {
        ...state,
        url: {
          value: action.value,
          valid: isValidUrl(action.value), 
        },
      };

    default:
      return state;
  }
};

export default coupon;
