import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import CouponForm from 'components/CouponForm';
import { makeStyles } from '@material-ui/core/styles';
import { createAndUpdateCoupon } from '@actions/coupon';
import { CREATE_COUPON_INITIATE } from '@actions/coupon';
import { SET_DIALOG } from '@actions/dialog';

import CloseIcon from 'assets/svg/CloseIcon';
import styled from 'styled-components';

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
  },
}));
const EditCouponDialog = ({ open, onClose, selectedCouponId, coupons }) => {
  const dispatch = useDispatch();
  const buttonStyles = useButtonStyles();

  const { title, detail, startDate, endDate, redemption, url } = useSelector(
    (state) => state?.coupon
  );

  const { last_updated_by, role_id, user_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const start = new Date(startDate?.value);

  const disabled = !(
    title.valid &&
    detail.valid &&
    startDate.valid &&
    endDate.valid &&
    start > yesterday &&
    url.valid
  );

  return (
    <Dialog
      open={open}
      maxWidth='md'
      onClose={() => _.isFunction(onClose) && onClose()}
      aria-labelledby='form-dialog-title'>
      <Container>
        <IconWrapper
          onClick={() => {
            dispatch({ type: CREATE_COUPON_INITIATE });
            _.isFunction(onClose) && onClose();
          }}>
          <CloseIcon />
        </IconWrapper>

        <Title>Update Coupon</Title>
        <DialogContent>
          <CouponForm
            coupons={coupons}
            selectedCouponId={selectedCouponId}
            spacing={2}
          />
        </DialogContent>

        <DialogActions>
          <Button
            label='Cancel'
            fullWidth={false}
            styles={buttonStyles}
            onClick={() => {
              dispatch({ type: CREATE_COUPON_INITIATE });
              _.isFunction(onClose) && onClose();
            }}
          />
          <Button
            label='Update'
            fullWidth={false}
            styles={buttonStyles}
            disabled={disabled}
            onClick={async () => {
              const payload = {
                lastUpdatedBy: last_updated_by,
                title: title?.value,
                detail: detail?.value,
                startDate: startDate?.value,
                endDate: endDate?.value,
                coupon_id: selectedCouponId,
                role_id: role_id,
                user_id: user_id,
                coupontype_id: redemption.options.find(
                  (item) => item.label === redemption.value
                ).value,
                url: url?.value,
              };

              const response = await dispatch(createAndUpdateCoupon(payload));
              if (response.error) {
                alert(response.message);
                return;
              }
              dispatch({
                type: SET_DIALOG,
                payload: {
                  show: true,
                  message: 'Coupon Updated Successfully!',
                  error: false,
                  redirect: '/coupons',
                },
              });
              _.isFunction(onClose) && onClose(true);
            }}
          />
        </DialogActions>
      </Container>
    </Dialog>
  );
};

EditCouponDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditCouponDialog;

const Container = styled.div`
  background: #dbdbdb;
`;

const IconWrapper = styled.div`
  margin: 23px 20px;
  > svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 21px;
  color: PURPLE;
  font-weight: 400;
  padding: 0 22px;
`;
