import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PURPLE } from '@colors';
import { HeadingChallenge } from 'css/components/Body';

const useStyles = makeStyles(() => ({
  title: {
    color: PURPLE,
    paddingBottom: 24,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <HeadingChallenge>
        <Typography variant='h5' component='h5' className={classes.title}>
          Create Challenge
        </Typography>
      </HeadingChallenge>
    </Grid>
  );
};

export default Header;
