import { get, post } from '@axios';
import REQUESTS from '@requests';
import axios from 'axios';
import { updateField } from '@actions/common';
import { TOGGLE_LOADING } from '@actions/loading';
import qs from 'qs';

const PERFORM_TRANSACTION = 'PERFORM_TRANSACTION';
const FETCH_TOKEN = 'FETCH_TOKEN';

export const fetchToken = (payload) => {
  return async (dispatch) => {
    let headers = {
      Authorization: `Bearer ${process.env.REACT_APP_REACT_KEY}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    dispatch(updateField(TOGGLE_LOADING));
    return await axios
      .post('https://api.stripe.com/v1/tokens', qs.stringify(payload), {
        headers,
      })
      .then((res) => {
        dispatch(updateField(TOGGLE_LOADING));
        return { error: false, data: res?.data };
      })
      .catch((error) => {
        dispatch(updateField(TOGGLE_LOADING));
        return { error: true, message: error?.response };
      });
  };
};

export const performTransaction = (payload) => {
  return async (dispatch) => {
    try {
      const response = await post({ url: REQUESTS.PAYMENT, payload: payload });
      return { error: false, data: response };
    } catch (e) {
      return { error: true, message: e.error };
    }
  };
};
