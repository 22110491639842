import moment from 'moment';
import { post, get } from '@axios';
import REQUESTS from '@requests';

const CREATE_COUPON_INITIATE = 'CREATE_COUPON_INITIATE';
const SET_COUPON_TITLE = 'SET_COUPON_TITLE';
const SET_COUPON_DETAIL = 'SET_COUPON_DETAIL';
const SET_COUPON_START_DATE = 'SET_COUPON_START_DATE';
const SET_COUPON_END_DATE = 'SET_COUPON_END_DATE';
const SET_COUPON_REDEMPTION = 'SET_COUPON_REDEMPTION';
const SET_COUPON_TYPE = 'SET_COUPON_TYPE';
const SET_COUPON_URL = 'SET_COUPON_URL';

const createAndUpdateCoupon = (data, typ = false) => {
  return async (dispatch) => {
    try {
      const payload = {
        title: data?.title,
        detail: data?.detail,
        startdate: moment.utc(moment(data?.startDate)).format(),
        enddate: moment.utc(moment(data?.endDate)).format(),
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        last_updated_by: data?.lastUpdatedBy,
        transactiontype: 'I',
        coupontype_id: data?.coupontype_id,
        role_id: data?.role_id,
        user_id: data?.user_id,
        coupon_url: data?.url,
      };

      if (data.coupon_id) {
        payload.transactiontype = 'U';
        payload.coupon_id = data?.coupon_id;
      }

      const request = await post({ url: REQUESTS.COUPON, payload });
      console.log('redux action createAndUpdateCoupon', request);

      dispatch({ type: CREATE_COUPON_INITIATE });
      return { error: false, data: request?.data };
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const getCoupons = (userId = undefined) => {
  return async () => {
    try {
      let coupons = await get({
        url: userId
          ? `${REQUESTS.COUPON_LIST}?userid=${userId}`
          : REQUESTS.COUPON_LIST,
      });
      // console.log('redux action getCoupons', coupons);
      return coupons?.message?.length
        ? coupons?.message
        : [{ coupon_id: 0, title: 'No coupon found' }];
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const getCouponsList = () => {
  return async (dispatch) => {
    try {
      let coupons = await get({
        url: REQUESTS.COUPON_TYPE_LIST,
      });
      dispatch({ type: SET_COUPON_TYPE, payload: coupons });
      return coupons?.message?.length
        ? coupons?.message
        : [{ coupon_id: 0, title: 'No coupon type found' }];
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const deleteCoupon = (data) => {
  return async () => {
    try {
      const payload = {
        coupon_id: data.couponId.toString(),
        last_updated_by: data.lastUpdatedBy,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        role_id: data.role_id,
      };

      const request = await post({ url: REQUESTS.DELETE_COUPON, payload });
      return request;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const getCouponToolLink = async () => {
    try {
      await get({
        url: REQUESTS.COUPON_TOOL_LINK
      })
      .then(function (response) {
        console.log(response);
        let link = response?.message?.coupontool?.length
                    ? response?.message?.coupontool
                    : '';
        if (link !== '') {
          window.open(link);
        } else {
          alert('Failed to login to Coupon Tool');
        }
      });
    } catch (error) {
      return { error: true, message: error.error };
    }
};

export {
  SET_COUPON_TITLE,
  SET_COUPON_DETAIL,
  SET_COUPON_START_DATE,
  SET_COUPON_END_DATE,
  CREATE_COUPON_INITIATE,
  SET_COUPON_REDEMPTION,
  SET_COUPON_TYPE,
  SET_COUPON_URL,
  getCouponsList,
  createAndUpdateCoupon,
  getCoupons,
  deleteCoupon,
  getCouponToolLink
};
