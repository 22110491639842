import * as a from '@actions/challenge';

const allChallenges = (
  state = { userAcceptedChallenges: [], allChallenges: [], error: null },
  action = null
) => {
  switch (action.type) {
    case a.SET_CHALLENGES_DATA:
      return {
        ...state,
        allChallenges: action.allChallenges,
      };
    case a.SET_USER_ACCEPTED_CHALLENGES_DATA:
      return {
        ...state,
        userAcceptedChallenges: action.userAcceptedChallenges,
      };
    default:
      return state;
  }
};

export default allChallenges;
