import styled from 'styled-components';

const Container = styled.div``;

const CreateCoupon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CouponsTable = styled.div`
  background-color: rgb(140, 125, 155, 0.82);
  /* padding: 16px 15px 13px; */
  border-radius: 7px;
`;

export { Container, CreateCoupon, CouponsTable };
