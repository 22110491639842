import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const List = styled.div`
  min-width: 240px;
  list-style: none;
  padding: 0;
  padding: 7px;
  position: absolute;
  min-height: calc(100% - 228px);
  ${(props) =>
    props.isAdmin &&
    css`
      background-color: #8c7d9bb5;
      margin: 0;
      border-radius: 7px;
    `}
  @media (max-width: 1250px) {
    position: unset;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 10px 20px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  &.active,
  &:hover {
    background-color: #793a72;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 3px;
  }
`;

const ListItem = styled.li`
  padding: 15px 0;
  color: #fff;
  display: flex;
`;

const ListItemIcon = styled.span``;

const ListItemText = styled.span`
  padding-left: 15px;
`;

export { List, ListItem, ListItemIcon, ListItemText, StyledLink };
