import * as a from '@actions/registerUser';
import { initial } from '@reducers/registerUser/initialState';
import { MAXIMUM_PASSWORD_LENGTH } from '@reduxConstants/user';

const coupon = (state = initial, action = null) => {
  switch (action.type) {
    case a.CREATE_REGUSER_INITIATE:
      return initial;
    case a.SET_REGUSER_EMAIL:
      return {
        ...state,
        email: {
          value: action.value,
          valid: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            action.value
          ),
        },
      };
    case a.SET_REGUSER_PASSWORD:
      return {
        ...state,
        password: {
          value: action.value,
          valid:
            !!action.value &&
            action.value.length <= MAXIMUM_PASSWORD_LENGTH &&
            !/\"|\`|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\''|\s/g.test(
              action.value
            ),
        },
      };
    case a.SET_REGUSER_GROUPID:
      return {
        ...state,
        groupid: {
          value: action.value,
          valid: !!action.value,
        },
      };
    case a.SET_REGUSER_ACCESS:
      return {
        ...state,
        access: {
          value: action.value,
          valid: !!action.value,
        },
      };

    default:
      return state;
  }
};

export default coupon;
