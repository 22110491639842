import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SendMailDialog from 'pages/users/SendMailDialog';
import Table from 'components/Table';
import { PageContainer } from 'css/components/Body';
import Typography from '@material-ui/core/Typography';

const headCells = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    numeric: false,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    numeric: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ChallengeWinnersTable = ({ challenge }) => {
  const classes = useStyles();
  const [sendMailModalVisibility, setSendMailModalVisibility] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(undefined);
  const [users, setUsers] = useState(challenge?.winnerList);
  const WINNERS = 'winners';
  const PARTICIPANTS = 'participants';
  const [filterSelected, setFilterSelected] = useState(WINNERS);

  useEffect(() => {
    if (filterSelected === WINNERS) {
      setUsers(challenge?.winnerList.filter((winner) => {
        return winner.is_winner === 1;
      }));
    } else {
      setUsers(challenge?.winnerList);
    }
  }, [filterSelected, challenge?.winnerList]);

  if (challenge?.winnerList?.length) {
    let rows = [];

    for (let i = 0; i < users.length; i++) {
      rows.push({
        name: users[i]?.firstname,
        email: users[i]?.emailid,
        userId: users[i]?.user_id,
      });
    }

    return (
      <React.Fragment>
        <PageContainer style={{ margin: '0 20px' }}>
          <Table
            title='Winner List'
            headCells={headCells}
            rows={rows}
            style={{ background: 'none' }}
            actionKey='userId'
            titleComponent={
              <>
                <button style={{ background: 'transparent', border: 'none', cursor: 'pointer', borderBottom: filterSelected === WINNERS ? '1px solid black' : 'none' }} onClick={() => setFilterSelected(WINNERS)}>
                  <Typography
                    className={classes.title}
                    variant='h6'
                    id='tableTitle'
                    component='div'>
                    Winner List
                  </Typography>
                </button>
                <button style={{ background: 'transparent', border: 'none', cursor: 'pointer', borderBottom: filterSelected === PARTICIPANTS ? '1px solid black' : 'none' }} onClick={() => setFilterSelected(PARTICIPANTS)}>
                  <Typography
                    className={classes.title}
                    variant='h6'
                    id='tableTitle'
                    component='div'>
                    Participant List
                  </Typography>
                </button>
              </>
            }
            sendMail={(users) => {
              setSendMailModalVisibility(true);
              setSelectedUserIds(users);
            }}
          />

          {sendMailModalVisibility && (
            <SendMailDialog
              open={sendMailModalVisibility}
              setOpen={(v) => setSendMailModalVisibility(v)}
              data={challenge?.winnerList?.filter((item) =>
                selectedUserIds.includes(item?.user_id)
              )}
            />
          )}
        </PageContainer>
      </React.Fragment>
    );
  }
  return null;
};

export default ChallengeWinnersTable;
