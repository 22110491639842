import styled from "styled-components";
import { PURPLE, WHITE } from "@colors";
import CouponImage from "assets/images/coupon-img.jpeg";

const tableTitle = {
  color: PURPLE,
  fontSize: 20,
  paddingLeft: 16,
  paddingTop: 16,
};

const Container = styled.div`
  margin-left: 270px;
  display: grid;
  flex: 1;
  grid-gap: 22px;
  grid-template-rows: 1fr 1fr;
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "users challenge coupons"
    "users challenge coupons";
  /* @media (max-width: 1130px) {
    flex-direction: column;
  } */
  @media (max-width: 1484px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "users challenge"
      "coupons coupons ";
  }
  @media (max-width: 1250px) {
    margin-left: 0;
  }
`;

const UserWrapper = styled.div`
  padding: 15px;
  border-radius: 7px;
  background-color: #8c7d9bb5;
  grid-area: users;
`;
const ChallengeWrapper = styled.div`
  padding: 15px;
  border-radius: 7px;
  background-color: #8c7d9bb5;
  grid-area: challenge;
`;
const CouponsWrapper = styled.div`
  padding: 15px;
  border-radius: 7px;
  background-color: #8c7d9bb5;
  grid-area: coupons;
`;
const Section = styled.div`
  height: 100%;
`;
const Title = styled.p`
  margin: 0;
  color: ${PURPLE};
  font-size: 20px;
  font-weight: 400;
`;
const Header = styled.div`
  padding: 12px 17px;
  background-color: rgba(255, 255, 255, 0.96);
  border-radius: 7px;
  margin-bottom: 25px;
`;

const Error = styled.div`
  padding: 20px 16px;
  min-height: 17px;
`;

const CouponTableWrapper = styled.div`
  display: flex;
`;
const ActiveCouponWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  > div {
    // max-width: 250; //159
    height: 300px; //159
    background-image: url(${CouponImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 7px solid #8ce19d;
    border-radius: 7px;
    margin-right: 4%;
    position: relative;
  }

  @media (max-width: 1770px) {
    > div {
      // max-width: 250px; //130
      height: 250px; //130
    }
  }
  @media (max-width: 1567px) {
    > div {
      // max-width: 150px; //114
      height: 200px; //114
    }
  }
`;

const NonActiveCouponWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  > div {
    // max-width: 250; //159
    height: 300px; //159
    background-image: url(${CouponImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 7px solid #cb6d78;
    border-radius: 7px;
    margin-left: 4%;
    position: relative;
  }

  @media (max-width: 1770px) {
    > div {
      // max-width: 250px; //130
      height: 250px; //130
    }
  }
  @media (max-width: 1567px) {
    > div {
      // max-width: 150px; //114
      height: 200px; //114
    }
  }
`;

const CouponBody = styled.div`
  key: ${({key}) => key}
`;

const CouponText = styled.div`
  position: absolute;
  bottom: 0.5px;
  left: 2px;
  right: 0px;
  z-index: 3;
  padding: 4px 8px;
  text-align: left;
  background-color: rgb(255 255 255 / 0.81);
  color: #363333;
  font-weight: 400;
`;

const CouponOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 2px;
  right: 2px;
  z-index: 2;
`;

const TableWrapper = styled.div`
  background-color: rgb(255 255 255 / 0.81);
  border-radius: 7px;
`;

const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 12px 17px;
  color: #96498e;
  font-weight: 400;
  background-color: ${WHITE};
  border-radius: 7px 7px 0 0;
`;
const TableBody = styled.div`
  height: 605px;
  overflow: auto;
  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    padding: 12px 17px;
    color: #363333;
    border-bottom: 1px solid #96498e;
    p {
      margin: 0;
    }
    overflow: auto;
  }
`;

export {
  Container,
  Section,
  tableTitle,
  Error,
  CouponBody,
  CouponText,
  CouponTableWrapper,
  ActiveCouponWrapper,
  NonActiveCouponWrapper,
  Title,
  Header,
  TableWrapper,
  TableHead,
  TableBody,
  UserWrapper,
  ChallengeWrapper,
  CouponsWrapper,
  CouponOverlay,
};
