import { useSelector, useDispatch } from 'react-redux';
import { updateField } from '@actions/common';
import { login as loginSubmit, SET_LOGOUT_FALSE } from '@actions/auth';
import { SET_USER_EMAIL, SET_USER_PASSWORD } from '@actions/user';
import { MAXIMUM_PASSWORD_LENGTH } from '@reduxConstants/user';
import TextField from 'components/TextField';
import Dialog from 'components/Dialog';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import { FIELDS_LABELS, BUTTON_LABELS } from 'constants/labels';
import { TYPES } from 'constants/field-types';
import Logo from 'assets/images/logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { getAllRoles } from '@actions/roles';
import { SET_DIALOG } from '@actions/dialog';
import {
  Container,
  Wrapper,
  StyledLogo,
  InputWrapper,
  LoginLogo,
  FieldWrapper,
  Section,
} from 'css/pages/login';
import { useEffect } from 'react';

const buttonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
  },
}));

const Login = () => {
  const styles = buttonStyles();

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const logout = useSelector((state) => state?.auth?.logout);
  const { roles } = useSelector((state) => state?.roles);
  const { email, password } = user;

  useEffect(() => {
    const fetchRoles = async () => {
      if (roles?.length < 1) {
        await dispatch(getAllRoles());
      }
    };
    const checkLogout = async () => {
      if (logout) {
        await dispatch({ type: SET_LOGOUT_FALSE });
      }
    };
    fetchRoles();
    checkLogout();
  }, [dispatch, roles?.length]);

  useEffect(() => {
    const checkLogout = async () => {
      if (logout) {
        dispatch({
          type: SET_DIALOG,
          payload: {
            show: true,
            message: 'Logged out successfully',
            error: false,
          },
        });
        await dispatch({ type: SET_LOGOUT_FALSE });
      }
    };
    checkLogout();
  }, []);

  return (
    <Container>
      <Dialog />
      <Wrapper>
        <Section>
          <LoginLogo>
            <StyledLogo src={Logo} alt='Logo' />
          </LoginLogo>
          <FieldWrapper>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const response = await dispatch(loginSubmit(user));
                if (response?.error) {
                  alert(response?.message);
                }
              }}>
              <InputWrapper>
                <TextField
                  variant='standard'
                  label={FIELDS_LABELS?.EMAIL}
                  value={email?.value || ''}
                  error={!!(email?.value && !email?.valid)}
                  helperText={
                    email.value && !email.valid ? 'Invalid Email' : null
                  }
                  onChange={(value) =>
                    dispatch(updateField(SET_USER_EMAIL, { email: value }))
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  variant='standard'
                  label={FIELDS_LABELS?.PASSWORD}
                  type={TYPES?.PASSWORD}
                  value={password?.value || ''}
                  error={!!(password?.value && !password?.valid)}
                  helperText={
                    password?.value && !password?.valid
                      ? `Password should be less than ${MAXIMUM_PASSWORD_LENGTH} characters and Single quote (‘), Double quote (“) and escape letters are not allowed`
                      : null
                  }
                  onChange={(value) =>
                    dispatch(
                      updateField(SET_USER_PASSWORD, { password: value })
                    )
                  }
                  secureText={true}
                />
              </InputWrapper>

              <Button
                type='submit'
                disabled={!(email?.valid && password?.valid)}
                label={BUTTON_LABELS?.LOGIN}
                styles={styles}
              />
            </form>
          </FieldWrapper>
        </Section>
      </Wrapper>
    </Container>
  );
};

export default Login;
