import { get } from '@axios';
import REQUESTS from '@requests';

const SET_BODY_ANALYTICS = 'SET_BODY_ANALYTICS';
const INITIALIZE_BODY_ANALYTICS = 'INITIALIZE_BODY_ANALYTICS';
const GET_USER_LIST = 'GET_USER_LIST';

const getBodyDetails = (data) => {
  return async (dispatch) => {
    // let response = await get({
    //   url: `${REQUESTS.BODY_ANALYTICS}?groupId=${data.groupId}&isbodyanalytics=1&user_id=${data.userId}`,
    // });
    let response2 = await get({
      url: `/avatar?user_id=${data.userId}`,
    });
    if (response2?.error) return { error: true, message: response2?.message };
    else {
      dispatch({ type: SET_BODY_ANALYTICS, payload: response2?.message });
      return { error: false, message: response2?.message };
    }
  };
};

const userByGroup = (groupId) => {
  return async (dispatch) => {
    let response = await get({
      url: `${REQUESTS.GET_USER_BY_GROUP}?groupId=${groupId}`,
    });
    if (response?.error) return { error: true, message: response?.message };
    else {
      dispatch({ type: GET_USER_LIST, payload: response?.message });
      return { error: false, message: response?.message };
    }
  };
};
export {
  getBodyDetails,
  userByGroup,
  SET_BODY_ANALYTICS,
  INITIALIZE_BODY_ANALYTICS,
  GET_USER_LIST,
};
