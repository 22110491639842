import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { createAndUpdateChallenge } from '@actions/challenge';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import { CREATE_CHALLENGES_KEYS } from '@reduxConstants/create-challenge';
import Loader from 'components/Loader';
import { getLocationInfo } from 'utils/GeoCoderManager';
import { disabled, makePayload } from 'components/ChallengeForm/data';
import { SET_DIALOG } from '@actions/dialog';

const { BUSINESS_ADDRESS } = CREATE_CHALLENGES_KEYS;

const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
    marginBottom: '8px',
    marginTop: '16px',
  },
}));

const Footer = () => {
  const dispatch = useDispatch();
  const buttonStyles = useButtonStyles();
  const [loader, setLoader] = useState(false);
  const challenge = useSelector((state) => state?.challenge);
  const { user_id, role_id } = useSelector(
    (state) => state?.auth?.profile || {}
  );

  return (
    <>
      <Button
        label='Create'
        fullWidth={true}
        styles={buttonStyles}
        disabled={disabled(challenge)}
        onClick={async () => {
          setLoader(true);
          const { results } = await getLocationInfo(
            challenge?.[BUSINESS_ADDRESS]?.value
          );
          setLoader(false);
          if (!results?.length) {
            alert('Please enter valid business address');
            return;
          }

          const payload = makePayload({
            ...challenge,
            lat: results[0]?.geometry?.lat,
            lng: results[0]?.geometry?.lng,
            from_user_id: user_id,
            role_id: role_id,
            user_id: user_id,
          });

          const response = await dispatch(createAndUpdateChallenge(payload));

          if (response?.status === 401) {
            return;
          }

          if (response?.error) {
            alert(response?.message);
            return;
          }

          dispatch({
            type: SET_DIALOG,
            payload: {
              show: true,
              message: 'Challenge Created Successfully!',
              error: false,
              redirect: '/challenges',
            },
          });
        }}
      />
      <Loader open={loader} />
    </>
  );
};

export default Footer;
