import styled from 'styled-components';
import { WHITE, PURPLE } from '@colors';

const ChipWrapper = styled.div`
  display: flex;
  grid-gap: 5px;
  > div {
    color: ${WHITE};
    background: ${PURPLE};
    :hover {
      opacity: 0.8;
      background: ${PURPLE};
    }
    .MuiChip-avatar {
      background: ${WHITE};
    }
    .MuiChip-deleteIcon {
      color: ${WHITE};
    }
  }
`;

export { ChipWrapper };
