import * as a from '@actions/deviceList';

const deviceList = (
  state = { deviceList: [], connectedDevice: null, error: null },
  action = null
) => {
  switch (action.type) {
    case a.SET_DEVICE_LIST:
      return {
        ...state,
        deviceList: action.deviceList,
      };
    case a.SET_CONNECTED_DEVICE: {
      return {
        ...state,
        connectedDevice: action?.connectedDevice,
      };
    }
    default:
      return state;
  }
};

export default deviceList;
