import * as a from '@actions/badgesList';

const badgesList = (state = { badgesList: [], error: null }, action = null) => {
  switch (action.type) {
    case a.SET_BADGE_LIST:
      return {
        ...state,
        badgesList: action.badgesList,
      };
    default:
      return state;
  }
};

export default badgesList;
