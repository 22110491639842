import { get } from '@axios';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';

const SET_FITNESS_MATRIX = 'SET_FITNESS_MATRIX';
const SET_FITNESS_ERROR = 'SET_FITNESS_ERROR';

const getFitnessMatrix = () => {
  return async (dispatch) => {
    try {
      const data = await get({ url: REQUESTS.FITNESS_MATRIX });

      dispatch(
        updateField(SET_FITNESS_MATRIX, {
          data: data?.message,
        })
      );

      return data?.message;
    } catch (error) {
      dispatch(updateField(SET_FITNESS_ERROR, { error: error.message }));
      return { error: true, message: error.message };
    }
  };
};

export { SET_FITNESS_MATRIX, SET_FITNESS_ERROR, getFitnessMatrix };
