import * as a from '@actions/stepBank';

const stepBank = (state = { stepBankData: [], error: null }, action = null) => {
  switch (action.type) {
    case a.SET_STEP_BANK_DATA:
      return {
        ...state,
        stepBankData: action.stepBankData,
      };
    default:
      return state;
  }
};

export default stepBank;
