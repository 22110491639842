import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={21}
    height={14.7}
    viewBox='0 0 24.24 19.392'
    {...props}>
    <path
      d='M21.816 0H2.424A2.4 2.4 0 00.012 2.424L0 16.968a2.416 2.416 0 002.424 2.424h19.392a2.416 2.416 0 002.424-2.424V2.424A2.416 2.416 0 0021.816 0zm0 16.968H2.424V9.7h19.392zm0-12.12H2.424V2.424h19.392z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;
