import React, { useEffect, useState } from 'react';
import {
  PageContainer,
  HeadingContainer,
  BodyContainer,
  HeadingTitle,
} from 'css/components/Body';
import styled from 'styled-components';
import Select from 'components/Select';
import {
  MeasurementsSection,
  Section,
  BodyTitle,
} from 'css/pages/body-analytics';
import { PURPLE } from '@colors';
import { useDispatch, useSelector } from 'react-redux';
import SimpleTable from 'components/SimpleTable';
import { getGroupIds } from '@actions/groupId';
import {
  userByGroup,
  getBodyDetails,
  INITIALIZE_BODY_ANALYTICS,
} from '@actions/bodyAnalytics';
import BODY_ANALYTICS_KEYS from '@reduxConstants/bodyAnalytics';

const FitnessAnalytics = () => {
  const dispatch = useDispatch();
  const { role_id, group_id } = useSelector((state) => state?.auth?.profile);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [cardData, setCardData] = useState(undefined);
  const selfGroupId = useSelector((state) => state?.auth?.profile?.group_id);
  const keys = BODY_ANALYTICS_KEYS;
  const groupId = useSelector((state) => state?.groupId);
  const { users } = useSelector((state) => state?.bodyAnalytics);
  const bodyData = useSelector((state) => state?.bodyAnalytics?.data);
  const params = [
    keys.CHEST,
    keys.NECK,
    keys.SHOULDER,
    keys.WAIST,
    keys.LOW_HIPS,
    keys.HIGH_HIPS,
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchGroupIds = async () => {
      if (!groupId.length) {
        await dispatch(getGroupIds());
      }
    };
    fetchGroupIds();
    if (role_id !== 1) setSelectedGroup(group_id);
  }, [dispatch, groupId, group_id, role_id]);

  useEffect(() => {
    const fetchUser = async () => {
      await dispatch(userByGroup(selectedGroup));
    };
    if (selectedGroup) fetchUser();
  }, [dispatch, selectedGroup]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      let payload = {
        groupId: selectedGroup,
        userId: selectedUser,
      };
      await dispatch(getBodyDetails(payload));
    };
    if (selectedUser) fetchAnalytics();
  }, [dispatch, selectedUser, selectedGroup]);

  const calculateBMI = (cm, lbs) => {
    let inches = cm / 2.54;
    let bmi = (lbs / (inches * inches)) * 703;
    return Math.round(bmi * 100) / 100;
  };

  useEffect(() => {
    let tempData = [];
    if (bodyData.length) {
      console.log(bodyData)
      let jsonData = [];
      if (bodyData?.length === 1) bodyData.push(bodyData[0]);
      jsonData.push(JSON.parse(bodyData[0]?.json));
      if(bodyData[1]?.json){
        jsonData.push(JSON.parse(bodyData[1]?.json));
      }
      
      setCardData({
        [keys.HEIGHT_IN_FEET]: jsonData[0][keys.HEIGHT_IN_FEET] || 0,
        [keys.HEIGHT_IN_INCHES]: jsonData[0][keys.HEIGHT_IN_INCHES] || 0,
        [keys.WEIGHT]: jsonData[0][keys.WEIGHT] || 0,
        [keys.BMI]: calculateBMI(
          jsonData?.[0]?.height || 0,
          jsonData[0][keys.WEIGHT] || 0
        ),
      });
      for (let i = 0; i < 6; i++) {
        let name = Object.keys(keys)[
          Object.values(keys).indexOf(params[i])
        ].replace('_', ' ');
        if (params[i] == 'neck' || params[i] == 'shoulders')
          tempData.push([
            name[0].toUpperCase() + name.slice(1).substring(-1).toLowerCase(),
            Math.round((jsonData[0]?.front_params?.[params[i]] || 0 / 2.54) * 100) /
              100,
            Math.round((jsonData[1]?.front_params?.[params[i]] || 0 / 2.54) * 100) /
              100,
          ]);
        else
          tempData.push([
            name[0].toUpperCase() + name.slice(1).substring(-1).toLowerCase(),
            Math.round((jsonData[0]?.volume_params?.[params[i]] || 0 / 2.54) * 100) /
              100,
            Math.round((jsonData[1]?.volume_params?.[params[i]] || 0 / 2.54) * 100) /
              100,
          ]);
      }
    }

    setTableData(tempData);
  }, [bodyData]);

  useEffect(
    () => () => {
      dispatch({ type: INITIALIZE_BODY_ANALYTICS });
    },
    [dispatch]
  );

  return (
    <PageContainer>
      <HeadingContainer style={{ height: 'auto', padding: '1px 17px' }}>
        <HeadingTitle>Body Analytics</HeadingTitle>
        <Container>
          <div>
            <p>Group ID</p>
            <Select
              fullWidth={false}
              readOnly={role_id !== 1 ? true : false}
              options={groupId?.length ? groupId : []}
              onChange={(value) => setSelectedGroup(value)}
              value={role_id !== 1 ? selfGroupId : selectedGroup}
              theme='underLine'
            />
          </div>
          <div>
            <p>Username</p>
            <Select
              fullWidth={false}
              options={
                users?.length ? users : [{ username: 'No user', user_id: 0 }]
              }
              labelKey='username'
              valueKey='user_id'
              onChange={(value) => setSelectedUser(value)}
              theme='underLine'
              value={selectedUser || 0}
            />
          </div>
        </Container>
      </HeadingContainer>
      <BodyContainer>
        <MeasurementsSection>
          <Section>
            <p>Height</p>
            <p>
              {!bodyData.length
                ? '0 ft 0 in'
                : `${cardData?.[keys.HEIGHT_IN_FEET] || 0} ft ${
                    cardData?.[keys.HEIGHT_IN_INCHES] || 0
                  } in`}
            </p>
          </Section>
          <Section>
            <p>Weight</p>
            <p>
              {!bodyData.length
                ? '0 lbs'
                : `${cardData?.[keys.WEIGHT] || 0} lbs`}
            </p>
          </Section>
          <Section>
            <p>BMI</p>
            <p>{!bodyData.length ? '0' : `${cardData?.[keys.BMI] || 0}`}</p>
          </Section>
        </MeasurementsSection>
        <BodyTitle>Measurements</BodyTitle>
        {selectedUser && selectedGroup && bodyData?.length > 0 ? (
          <SimpleTable
            secondary={true}
            head={['Attribute', 'Baseline(in)', 'Current(in)']}
            rowData={tableData}
          />
        ) : (
          <Text>
            {selectedUser && selectedGroup
              ? 'No data found'
              : role_id === 1
              ? 'Select a Group ID and a Username to view Data'
              : 'Select a username to view data'}
          </Text>
        )}
      </BodyContainer>
    </PageContainer>
  );
};

export default FitnessAnalytics;

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  > div {
    width: 100%;
    max-width: 298px;
    padding-right: 40px;
    > div {
      margin: 0;
    }
    :last-child {
      padding: 0;
    }
    > p {
      margin: 0;
      font-size: 18px;
      color: ${PURPLE};
      font-weight: normal;
    }
  }
`;

const Text = styled.div`
  text-align: center;
  font-size: 22px;
  padding: 50px;
  color: ${PURPLE};
`;
