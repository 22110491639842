import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/Button';
import { purpleButton } from 'components/Button/types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
// import { convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';
import { sendMail as mail } from '@actions/mail';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { PURPLE } from '@colors';
import { ChipWrapper } from 'css/pages/user';
import styled from 'styled-components';
import { SET_DIALOG } from '@actions/dialog';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useButtonStyles = makeStyles(() => ({
  root: {
    ...purpleButton(),
  },
}));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomDialog = ({ open, setOpen, data }) => {
  const dispatch = useDispatch();
  const [mailList, setMailList] = useState(undefined);
  const [mailBody, setMailBody] = useState(undefined);
  const [userData, setUserData] = useState(undefined);
  const [enabled, setEnabled] = useState(true);
  const handleClose = () => setOpen(false);
  let mails = undefined;

  useEffect(() => {
    setUserData(data);
    let mailString = '';
    data.forEach((el) => {
      mailString += el.emailid + ',';
    });
    setMailList(mailString);
  }, [dispatch, mailList, data]);

  const incrementMails = () => {
    if (!mails) mails = 1;
    else mails += 1;
  };

  const sendMail = async () => {
    handleClose();
    let payload = {
      mail_list: mailList,
      // mail_body: draftToHtml(convertToRaw(mailBody.getCurrentContent())),
      mail_body: mailBody.getCurrentContent().getPlainText(),
    };
    let response = await dispatch(mail(payload));
    if (response?.error) alert(response?.message);
    else
      dispatch(
        dispatch({
          type: SET_DIALOG,
          payload: {
            show: true,
            message: 'Email sent successfully!',
            error: false,
          },
        })
      );
  };
  const buttonStyles = useButtonStyles();

  const handleDelete = (email) => {
    setUserData(userData.filter((item) => item.emailid !== email));
  };

  useEffect(() => {
    if (userData && !userData?.length) handleClose();
  }, [userData]);

  return (
    <Dialog
      maxWidth='md'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}>
      <Container>
        <DialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
          style={{ color: '#7F3D78', fontWeight: 'bold' }}>
          Email
        </DialogTitle>
        <DialogContent
          dividers
          style={{ height: '50px', padding: '12px 18px 0px' }}>
          <ChipWrapper>
            {userData &&
              userData.map((item, index) => {
                return index < 3 ? (
                  <Chip
                    avatar={<Avatar>{item.firstname[0].toUpperCase()}</Avatar>}
                    label={item.emailid}
                    clickable
                    color={PURPLE}
                    onDelete={() => handleDelete(item.emailid)}
                  />
                ) : (
                  incrementMails()
                );
              })}
            {mails && <Chip label={`+${mails} others`} color='primary' />}
          </ChipWrapper>
        </DialogContent>
        <DialogContent dividers style={{ height: 'calc(100vh - 530px)' }}>
          <Editor
            editorState={mailBody || ''}
            wrapperClassName='wrapperClassName'
            onEditorStateChange={(v) => {
              v.getCurrentContent().getPlainText() === ''
                ? setEnabled(true)
                : setEnabled(false);
              return setMailBody(v);
            }}
            placeholder='Type here..'
          />
        </DialogContent>
        <DialogActions>
          <Button
            label='Send'
            fullWidth={false}
            styles={buttonStyles}
            onClick={sendMail}
            disabled={enabled || userData?.length < 1}
          />
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default CustomDialog;

const Container = styled.div`
  height: calc(100vh - 300px);
  width: 960px;
  @media (max-width: 1033px) {
    width: 88vw;
  }
`;
