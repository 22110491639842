import { post } from '@axios';
import REQUESTS from '@requests';
import moment from 'moment';

const SET_REGUSER_EMAIL = 'SET_REGUSER_EMAIL';
const SET_REGUSER_PASSWORD = 'SET_REGUSER_PASSWORD';
const SET_REGUSER_GROUPID = 'SET_REGUSER_GROUPID';
const SET_REGUSER_ACCESS = 'SET_REGUSER_ACCESS';
const CREATE_REGUSER_INITIATE = 'CREATE_REGUSER_INITIATE';

const createNewAdmin = (data) => {
  return async (dispatch) => {
    try {
      let payload = {
        ...data,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      };
      const response = await post({
        url: REQUESTS.REGISTER_ADMIN,
        payload: payload,
      });
      dispatch({ type: CREATE_REGUSER_INITIATE });
      return { error: false, message: response };
    } catch (error) {
      return { error: true, message: error?.error };
    }
  };
};

export {
  SET_REGUSER_ACCESS,
  SET_REGUSER_GROUPID,
  SET_REGUSER_PASSWORD,
  SET_REGUSER_EMAIL,
  CREATE_REGUSER_INITIATE,
  createNewAdmin,
};
