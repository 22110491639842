import React from 'react';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import BusinessLogo from 'assets/images/map-marker.png';
import PeopleImage from 'assets/images/peoples.png';
import ClockImage from 'assets/images/clock.png';
import TrophyImage from 'assets/images/trophy.png';
import ChallengeWinnersTable from 'pages/challenges/ChallengeWinnersTable';
import {
  ChallengeDetailTitle,
  ChallengeDetailLogo,
  ChallengeDetailBody,
  ChallengeDetailRow,
  ChallengeDetailImage,
  ChallengeDetailCol,
  ChallengeDetailValue,
  ChallengeDetailButton,
} from 'css/pages/challenges';

const ChallengeDetailDialog = ({ open, setOpen, challenge }) => {
  const handleClose = () => setOpen(false);

  const startDate = moment(challenge?.startdate);
  const endDate = moment.utc(challenge?.enddate);
  const diff = endDate.diff(startDate, 'days');

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
      <ChallengeDetailTitle>Challenge Details</ChallengeDetailTitle>
      <ChallengeDetailBody>
        <ChallengeDetailLogo
          src={challenge.bussinesslogo}
          alt='Business Logo'
          onError={(e) => {
            e.target.error = null;
            e.target.src = BusinessLogo;
            return BusinessLogo;
          }}
        />
      </ChallengeDetailBody>
      <ChallengeDetailRow>
        <ChallengeDetailCol>
          <ChallengeDetailImage src={PeopleImage} alt='people' />
          <ChallengeDetailValue>
            {challenge?.noofusersacceptedchallenge || 0} people
          </ChallengeDetailValue>
        </ChallengeDetailCol>
        <ChallengeDetailCol>
          <ChallengeDetailImage src={ClockImage} alt='clock' />
          <ChallengeDetailValue>
            {diff}&nbsp;{diff > 1 ? 'days' : 'day'}
          </ChallengeDetailValue>
        </ChallengeDetailCol>
        <ChallengeDetailCol>
          <ChallengeDetailImage src={TrophyImage} alt='trophy' />
          <ChallengeDetailValue>{challenge?.incentivemsg}</ChallengeDetailValue>
        </ChallengeDetailCol>
      </ChallengeDetailRow>
      <ChallengeWinnersTable challenge={challenge} />
      <DialogActions>
        <ChallengeDetailButton onClick={handleClose}>Ok</ChallengeDetailButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChallengeDetailDialog;
