import React, { useState, useEffect } from 'react';
import ReactMapGL, { Popup } from 'react-map-gl';
import Marker from 'components/Map/marker';
import PopBox from 'components/Map/popup';
import { Wrapper } from 'css/components/Map';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN = process.env.REACT_APP_MAPBOX_KEY;

const Map = (props) => {
  const token = TOKEN;
  const [viewport, setViewport] = React.useState({
    latitude: 37.2582904,
    longitude: -104.6537497,
    zoom: 4,
    minZoom: 1,
    scrollZoom: true,
  });
  const [popupInfo, setPopupInfo] = useState(null);
  const { mapType } = props;

  useEffect(() => {
    setPopupInfo(null);
  }, [mapType]);

  return (
    <Wrapper>
      <ReactMapGL
        {...viewport}
        width='100%'
        height='100%'
        mapStyle='mapbox://styles/mapbox/streets-v11'
        onViewportChange={(viewport) => setViewport(viewport)}
        mapboxApiAccessToken={token}>
        <Marker data={props.data} onClick={setPopupInfo} />
        {popupInfo && (
          <Popup
            tipSize={3}
            anchor='bottom'
            offsetLeft={12}
            offsetBottom={10}
            longitude={parseFloat(popupInfo.longitude)}
            latitude={parseFloat(popupInfo.latitude)}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}>
            <PopBox item={popupInfo} mapType={mapType} />
          </Popup>
        )}
      </ReactMapGL>
    </Wrapper>
  );
};

export default Map;
