import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getTheme } from 'components/Select/Theme';
import {
  InputLabelStyle as InputLabel,
  DropdownIcon,
  // MenuList,
} from 'css/components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from 'components/Select/useStyles';

const CustomSelect = ({
  value,
  onChange,
  label,
  options,
  valueKey,
  labelKey,
  disabled,
  style,
  theme,
  readOnly,
}) => {
  const classes = useStyles(getTheme(theme));

  if (!options?.length) {
    return null;
  }
  return (
    <FormControl
      fullWidth
      variant='outlined'
      className={classes.root}
      style={style}
      margin='normal'>
      <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
      <Select
        IconComponent={() => <DropdownIcon />}
        disabled={disabled}
        className={classes.root}
        value={value || ''}
        readOnly={readOnly}
        onChange={(e) => _.isFunction(onChange) && onChange(e.target.value)}
        label={label}
        style={
          readOnly ? { backgroundColor: 'rgb(155, 155, 155, 0.3)' } : null
        }>
        {/* <MenuList> */}
        {options?.map((option, index) =>
          option.isNone ? (
            <MenuItem value='' key={`select${index}`}>
              <em>{option[valueKey]}</em>
            </MenuItem>
          ) : (
            <MenuItem
              value={option[valueKey] || option}
              key={`select${index}`}
              disabled={option?.[valueKey] === 0}>
              {option[labelKey] || option[valueKey] || option}
            </MenuItem>
          )
        )}
        {/* </MenuList> */}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf([
    'white',
    'purple',
    'grey',
    'form',
    'transparentBorder',
    'underLine',
  ]),
};

CustomSelect.defaultProps = {
  value: '',
  disabled: false,
  theme: 'grey',
};

export default CustomSelect;
