import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES, permissions } from 'constants/routes';
import Header from 'components/Header';
import SideMenu from 'components/SideMenu';
import Dialog from 'components/Dialog';
import { Container, Wrapper, Bg } from 'css/pages/privateRoutes';
import ErrorPage from 'pages/error';

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state?.auth);
  const nodeRef = useRef(null);
  const { role_id } = useSelector((state) => state?.auth?.profile) || 0;

  return (
    <>
      <Route
        {...rest}
        nodeRef={nodeRef}
        render={() =>
          auth.authenticated ? (
            permissions[role_id].includes(rest.path) ? (
              <React.Fragment>
                <Container>
                  <Dialog />
                  <Bg />
                  <Header />
                  <Wrapper>
                    <SideMenu />
                    {children}
                  </Wrapper>
                </Container>
              </React.Fragment>
            ) : (
              <ErrorPage />
            )
          ) : (
            <Redirect to={ROUTES.LOGIN} />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
