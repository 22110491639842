import * as a from "@actions/notification";

const initialState = {
  notificationData: [],
};

const notifications = (state = initialState, action = null) => {
  switch (action.type) {
    case a.GET_NOTIFICATIONS_DATA:
      return { ...state, notificationData: action.notificationsData };
    default:
      return state;
  }
};

export default notifications;
