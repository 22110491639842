import React from 'react';
import { useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from '@store';
import Routes from 'routes';
import Loader from 'components/Loader';

const Wrapper = () => {
  const loading = useSelector((state) => state?.loading);

  return (
    <React.Fragment>
      <Routes />
      {loading && <Loader open={loading} />}
    </React.Fragment>
  );
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Wrapper />
    </PersistGate>
  </Provider>
);

export default App;
