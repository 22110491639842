import { get, post } from '@axios';
import moment from 'moment';
import REQUESTS from '@requests';
import { updateField } from '@actions/common';
import { USER_KEYS } from '@reduxConstants/user';

const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const SET_USER_PASSWORD = 'SET_USER_PASSWORD';
const SET_USER_NEW_PASSWORD = 'SET_USER_NEW_PASSWORD';
const SET_USER_CONFIRM_PASSWORD = 'SET_USER_CONFIRM_PASSWORD';
const SET_USER_HEIGHT = 'SET_USER_HEIGHT';
const TOGGLE_USER_DOB_PICKER_VISIBILITY = 'TOGGLE_USER_DOB_PICKER_VISIBILITY';
const USER_INITIATE = 'USER_INITIATE';
const SET_USER_OPTIONS = 'SET_USER_OPTIONS';
const UPDATE_MULTIPLE_USER_FIELDS = 'UPDATE_MULTIPLE_USER_FIELDS';
const GET_USERS_CSV = 'GET_USERS_CSV';

const getGenderList = () => {
  return async (dispatch) => {
    try {
      let genders = await get({ url: REQUESTS.GET_GENDER_LIST });
      genders = genders?.message || [];

      let options = [];

      for (let i = 0; i < genders.length; i++) {
        options.push({
          text: genders[i].gender,
          value: String(genders[i].gender_id),
        });
      }

      dispatch(
        updateField(SET_USER_OPTIONS, {
          options: options,
          key: USER_KEYS.GENDER,
        })
      );
      return options;
    } catch (error) {
      console.error(error);
    }
  };
};

const getEthnicityList = () => {
  return async (dispatch) => {
    try {
      let ethnicity = await get({ url: REQUESTS.GET_ETHNICITY_LIST });
      ethnicity = ethnicity?.message || [];

      let options = [];

      for (let i = 0; i < ethnicity.length; i++) {
        options.push({
          text: ethnicity[i].ethnicity,
          value: String(ethnicity[i].ethnicity_id),
        });
      }

      dispatch(
        updateField(SET_USER_OPTIONS, {
          options: options,
          key: USER_KEYS.ETHNICITY,
        })
      );

      return options;
    } catch (error) {
      console.error(error);
    }
  };
};

const getAllUsers = () => {
  return async () => {
    try {
      let users = await get({ url: REQUESTS.GET_ALL_USERS });
      return { error: false, users: users?.message };
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const deleteUsers = ({ userIds, lastUpdatedBy, roleId }) => {
  return async () => {
    try {
      const payload = {
        role_id: roleId,
        last_updated_ts: moment
          .utc()
          .local()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        last_updated_by: lastUpdatedBy,
        user_id: userIds?.toString(),
      };

      const request = await post({ url: REQUESTS.DELETE_USERS, payload });
      return { error: false, data: request?.data };
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const getUsersCSV = () => {
  return async (dispatch) => {
    let response = await get({
      url: `${REQUESTS.GET_USERS_CSV}`,
    });
    if (response?.error) return { error: true, message: response };
    else return { error: false, message: response };
  };
};

export {
  SET_USER_EMAIL,
  SET_USER_PASSWORD,
  SET_USER_NEW_PASSWORD,
  SET_USER_CONFIRM_PASSWORD,
  SET_USER_HEIGHT,
  UPDATE_USER_FIELD,
  TOGGLE_USER_DOB_PICKER_VISIBILITY,
  USER_INITIATE,
  SET_USER_OPTIONS,
  UPDATE_MULTIPLE_USER_FIELDS,
  GET_USERS_CSV,
  getUsersCSV,
  getGenderList,
  getEthnicityList,
  getAllUsers,
  deleteUsers,
};
